import React, { Component } from 'react';

import { Router, Route, Switch } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

import API from './services/api';

import history from './services/history';

import 'react-toastify/dist/ReactToastify.css';

// Views

import Login from './views/Login';

import Home from './views/Home/index';

import MaintenanceContainer from './views/Maintenance/MaintenanceContainer';

import RefuelContainer from './views/Refuel/RefuelContainer';

import ScheduleContainer from './views/Schedule/ScheduleContainer';

import TicketContainer from './views/Ticket/TicketContainer';

import PieceInventoryContainer from './views/PieceInventory/PieceInventoryContainer';

import Usuarios from './views/Usuarios';

import NovoUsuario from './views/Usuarios/new';

import EmployeeContainer from './views/EmployeePage';

import RoleContainer from './views/Role';

import AsuContainer from './views/Asu';

// import FinancialContainer from './views/Financial';

// Components

import Menu from './views/components/Menu/index';

import Header from './views/components/Header/index';

// Images

// Css

import './css/main.scss';

// para testes no localhost:

// const socket = io('http://localhost:4000/');

class App extends Component {
  state = {
    isAuth: false,

    username: '',

    useruuid: '',

    photo: '',

    teamName: '',
  };

  componentDidMount() {
    const token = localStorage.getItem('USER_TOKEN');

    if (!token) {
      localStorage.removeItem('USER_USERNAME');
      localStorage.removeItem('USER_UUID');
      localStorage.removeItem('USER_PHOTO');
      localStorage.removeItem('USER_TOKEN');
      localStorage.removeItem('USER_TEAM');
      localStorage.removeItem('USER');
    }

    const username = localStorage.getItem('USER_USERNAME');

    const useruuid = localStorage.getItem('USER_UUID');

    const photo = localStorage.getItem('USER_PHOTO');

    const teamName = localStorage.getItem('USER_TEAM');

    if (username && useruuid) {
      this.setState({
        isAuth: true,

        username,

        useruuid,

        photo,

        teamName,
      });
    }
  }

  /**

   * @argument message = mensagem texto puro do toast

   * @argument linkOnClick caso preenchido com uma rota, ao clicar no toast,

   * redireciona a página para a rota informada

   */

  notifyErrorTextOnClick = (message, linkOnClick) => {
    const routeLink = !linkOnClick || linkOnClick === '' ? null : linkOnClick;

    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,

      onClick: () => {
        if (routeLink) history.push(routeLink);
      },
    });
  };

  notifyErrorText = message => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  handleLogin = async (email, passwd, lembrar = false) => {
    try {
      const response = await API.post(
        `auth/login`,

        { username: email, password: passwd },

        { headers: { 'Content-Type': 'application/json' } }
      );

      const { name, uuid, photo, userteams } = response.data.user;
      const { token } = response.data;
      const { name: teamName } = userteams[0] && userteams[0].team;

      if (lembrar) {
        this.saveLocalStorage(name, uuid, photo, token, teamName);
      }

      this.setState({
        isAuth: true,
        username: name,
        useruuid: uuid,
        photo,
        teamName,
      });

      history.push('/home');

      // console.log('logado.data', logado.data);
    } catch (err) {
      // console.log(err);

      return err.response.status;
    }

    return true;
  };

  handleLogout = () => {
    history.push('/');

    this.setState({
      isAuth: false,
    });

    localStorage.removeItem('USER_USERNAME');
    localStorage.removeItem('USER_UUID');
    localStorage.removeItem('USER_PHOTO');
    localStorage.removeItem('USER_TOKEN');
    localStorage.removeItem('USER_TEAM');
    localStorage.removeItem('USER');
  };

  /**

   * Esse método de login DEVE SER MELHORADO, pois esta solução é temporária

   * e abre brechas de segurança.

   */

  saveLocalStorage = (USERNAME, UUID, PHOTO, TOKEN, TEAM) => {
    localStorage.setItem('USER_USERNAME', USERNAME);
    localStorage.setItem('USER_UUID', UUID);
    localStorage.setItem('USER_PHOTO', PHOTO);
    localStorage.setItem('USER_TOKEN', TOKEN);
    localStorage.setItem('USER_TEAM', TEAM);
  };

  /**

   * Essa função foi criada para que o `useruuid` mais recente e atualizado

   * do state seja buscado sem risco de pegar um valor vazio ou desatualizado.

   * Sem isso, ao buscar no componentDidMout, vinha vazio o state.

   */

  getUserUuidFromState = () => {
    const { useruuid } = this.state;

    return useruuid;
  };

  render() {
    const { isAuth, username, useruuid, photo, teamName } = this.state;

    const authMaintanance = teamName === 'GERENCIA' || teamName === 'OFICINA';
    const authSchedule = teamName === 'GERENCIA' || teamName === 'EXPEDICAO';
    const authRefuel = teamName === 'GERENCIA' || teamName === 'OFICINA';

    return (
      <div className="App">
        <Router history={history}>
          {!isAuth && (
            <Route
              path="*"
              render={props => (
                <Login {...props} handleLogin={this.handleLogin} />
              )}
            />
          )}

          {isAuth ? (
            <div>
              <Menu
                onLogout={this.handleLogout}
                team={teamName}
                username={username}
                empresa=""
                photo={photo}
              />

              <Header />
              <ToastContainer hideProgressBar autoClose={false} />
            </div>
          ) : null}

          <Switch>
            {isAuth && (
              <>
                <Route
                  path="/home"
                  render={props => <Home team={teamName} />}
                />
                <Route path="/usuarios" exact component={Usuarios} />
                <Route path="/usuarios/novo" exact component={NovoUsuario} />
                {authMaintanance && (
                  <Route
                    path="/maintenances"
                    exact
                    render={props => (
                      <MaintenanceContainer useruuid={useruuid} />
                    )}
                  />
                )}
                {authRefuel && (
                  <Route
                    path="/refuels"
                    exact
                    render={props => <RefuelContainer useruuid={useruuid} />}
                  />
                )}
                {authSchedule && (
                  <Route
                    path="/schedules"
                    exact
                    render={props => <ScheduleContainer useruuid={useruuid} />}
                  />
                )}
                {authRefuel && (
                  <Route
                    path="/tickets"
                    exact
                    render={props => <TicketContainer useruuid={useruuid} />}
                  />
                )}
                {authMaintanance && (
                  <Route
                    path="/inventories"
                    exact
                    render={props => (
                      <PieceInventoryContainer useruuid={useruuid} />
                    )}
                  />
                )}
                {authMaintanance && (
                  <Route
                    path="/employees/:uuid"
                    exact
                    component={EmployeeContainer}
                  />
                )}
                {authMaintanance && (
                  <Route
                    path="/employees"
                    exact
                    component={EmployeeContainer}
                  />
                )}
                {authMaintanance && (
                  <Route path="/roles/:uuid" exact component={RoleContainer} />
                )}
                {authMaintanance && (
                  <Route path="/roles" exact component={RoleContainer} />
                )}
                {authMaintanance && (
                  <Route path="/asus/:uuid" exact component={AsuContainer} />
                )}
                {authMaintanance && (
                  <Route path="/asus" exact component={AsuContainer} />
                )}

                <Route
                  path="/"
                  exact
                  render={props => <Home team={teamName} />}
                />
              </>
            )}
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
