import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import ReactSelect from '../components/ReactSelect';

import { FormStyled } from '../../styles/form';

const handleValidate = ({
  name,
  cpf,
  rg,
  rg_expiration,
  rg_emission,
  cnh,
  cnh_expiration,
  title,
  section,
  zone,
  birth_date,
  phone,
  address,
  type,
  bond,
}) => {
  const errors = {};

  if (!name) errors.name = 'OBRIGATÓRIO';
  if (!rg) errors.rg = 'OBRIGATÓRIO';
  if (!rg_expiration) errors.rg_expiration = 'OBRIGATÓRIO';
  if (!rg_emission) errors.rg_emission = 'OBRIGATÓRIO';
  if (!cpf) errors.cpf = 'OBRIGATÓRIO';
  if (!cnh) errors.cnh = 'OBRIGATÓRIO';
  if (!cnh_expiration) errors.cnh_expiration = 'OBRIGATÓRIO';
  if (bond === 'CONTRATADO' && !title) errors.title = 'OBRIGATÓRIO';
  if (bond === 'CONTRATADO' && !section) errors.section = 'OBRIGATÓRIO';
  if (bond === 'CONTRATADO' && !zone) errors.zone = 'OBRIGATÓRIO';
  if (!birth_date) errors.birth_date = 'OBRIGATÓRIO';
  if (!phone) errors.phone = 'OBRIGATÓRIO';
  if (!address) errors.address = 'OBRIGATÓRIO';
  if (!type) errors.type = 'OBRIGATÓRIO';
  if (!bond) errors.bond = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.employee) {
    await props.onEmployeeCreation({ ...values });
  } else {
    await props.onEmployeeUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const EmployeeForm = props => {
  const {
    employees,
    employee,
    onEmployeeEdit,
    onEmployeeDelete,
    onEmployeeRefresh,
  } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: employee ? employee.name : '',
          cpf: employee ? employee.cpf : '',
          rg: employee ? employee.rg : '',
          rg_expiration:
            employee && employee.rg_expiration
              ? employee.rg_expiration.substring(0, 10)
              : '',
          rg_emission:
            employee && employee.rg_emission
              ? employee.rg_emission.substring(0, 10)
              : '',
          cnh: employee ? employee.cnh : '',
          cnh_expiration:
            employee && employee.cnh_expiration
              ? employee.cnh_expiration.substring(0, 10)
              : '',
          title: employee ? employee.title : '',
          section: employee ? employee.section : '',
          zone: employee ? employee.zone : '',
          birth_date:
            employee && employee.birth_date
              ? employee.birth_date.substring(0, 10)
              : '',
          phone: employee ? employee.phone : '',
          address: employee ? employee.address : '',
          type: employee ? employee.type : '',
          bond: employee ? employee.bond : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleReset,
          isSubmitting,
        }) => (
          <FormStyled>
            <h1>{employee ? 'EDITAR FUNCIONÁRIO' : 'NOVO FUNCIONÁRIO'}</h1>

            <div className="formData">
              <strong>NOME</strong>

              <input
                placeholder="Digite o nome do funcionário..."
                name="name"
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.name && touched.name)}
              />

              {errors.name && touched.name && <span>{errors.name}</span>}
            </div>

            <div className="formData">
              <strong>DATA de NASCIMENTO</strong>

              <input
                name="birth_date"
                type="date"
                value={values.birth_date}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.birth_date && touched.birth_date)}
              />

              {errors.birth_date && touched.birth_date && (
                <span>{errors.birth_date}</span>
              )}
            </div>

            <div className="formData">
              <strong>TELEFONE</strong>

              <input
                placeholder="Digite o telefone do funcionário..."
                name="phone"
                type="text"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.phone && touched.phone)}
              />

              {errors.phone && touched.phone && <span>{errors.phone}</span>}
            </div>

            <div className="formData">
              <strong>ENDEREÇO</strong>

              <input
                placeholder="Digite o endereço do funcionário..."
                name="address"
                type="text"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.address && touched.address)}
              />

              {errors.address && touched.address && (
                <span>{errors.address}</span>
              )}
            </div>

            <div className="formData">
              <strong>RG</strong>

              <input
                placeholder="Digite o RG do funcionário..."
                name="rg"
                type="text"
                value={values.rg}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.rg && touched.rg)}
              />

              {errors.rg && touched.rg && <span>{errors.rg}</span>}
            </div>

            <div className="formData">
              <strong>EMISSÃO RG</strong>

              <input
                name="rg_emission"
                type="date"
                value={values.rg_emission}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.rg_emission && touched.rg_emission)}
              />

              {errors.rg_emission && touched.rg_emission && (
                <span>{errors.rg_emission}</span>
              )}
            </div>

            <div className="formData">
              <strong>VALIDADE RG</strong>

              <input
                name="rg_expiration"
                type="date"
                value={values.rg_expiration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.rg_expiration && touched.rg_expiration)}
              />

              {errors.rg_expiration && touched.rg_expiration && (
                <span>{errors.rg_expiration}</span>
              )}
            </div>

            <div className="formData">
              <strong>CPF</strong>

              <input
                placeholder="Digite o CPF do funcionário..."
                name="cpf"
                type="text"
                value={values.cpf}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.cpf && touched.cpf)}
              />

              {errors.cpf && touched.cpf && <span>{errors.cpf}</span>}
            </div>

            <div className="formData">
              <strong>CNH</strong>

              <input
                placeholder="Digite o CNH do funcionário..."
                name="cnh"
                type="text"
                value={values.cnh}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.cnh && touched.cnh)}
              />

              {errors.cnh && touched.cnh && <span>{errors.cnh}</span>}
            </div>

            <div className="formData">
              <strong>VALIDADE CNH</strong>

              <input
                name="cnh_expiration"
                type="date"
                value={values.cnh_expiration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.cnh_expiration && touched.cnh_expiration)}
              />

              {errors.cnh_expiration && touched.cnh_expiration && (
                <span>{errors.cnh_expiration}</span>
              )}
            </div>

            <div className="formData">
              <strong>TÍTULO</strong>

              <input
                placeholder="Digite o Título do funcionário..."
                name="title"
                type="text"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.title && touched.title)}
              />

              {errors.title && touched.title && <span>{errors.title}</span>}
            </div>

            <div className="formData">
              <strong>SEÇÃO</strong>

              <input
                placeholder="Digite o Seção do funcionário..."
                name="section"
                type="text"
                value={values.section}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.section && touched.section)}
              />

              {errors.section && touched.section && (
                <span>{errors.section}</span>
              )}
            </div>

            <div className="formData">
              <strong>ZONA</strong>

              <input
                placeholder="Digite o Zona do funcionário..."
                name="zone"
                type="text"
                value={values.zone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.zone && touched.zone)}
              />

              {errors.zone && touched.zone && <span>{errors.zone}</span>}
            </div>

            <div className="formData">
              <strong>VÍNCULO</strong>

              <select
                name="bond"
                value={values.bond}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.bond && touched.bond)}
              >
                <>
                  <option value="" />
                  <option value="CONTRATADO">CONTRATADO</option>
                  <option value="TERCEIRIZADO">TERCEIRIZADO</option>
                </>
              </select>

              {errors.bond && touched.bond && <span>{errors.bond}</span>}
            </div>

            <div className="formData">
              <strong>EMPRESA</strong>

              <select
                name="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.type && touched.type)}
              >
                <>
                  <option value="" />
                  <option value="AEB">AEB</option>
                  <option value="NSA">NSA</option>
                </>
              </select>

              {errors.type && touched.type && <span>{errors.type}</span>}
            </div>

            <button type="submit" disabled={!dirty || isSubmitting}>
              {employee ? 'ALTERAR' : 'CADASTRAR'}
            </button>

            <button
              type="button"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              DESFAZER
            </button>

            {employee && (
              <button type="button" onClick={onEmployeeRefresh}>
                LIMPAR
              </button>
            )}

            {employee && !employee.maintenanceemployees[0] && (
              <button
                type="button"
                onClick={onEmployeeDelete}
                className="delete"
              >
                EXCLUIR
              </button>
            )}

            <div className="division">
              <hr />
              <p>OU</p>
              <hr />
            </div>

            <ReactSelect
              placeholder="Escolher funcionário existente"
              options={employees}
              onSetValue={onEmployeeEdit}
            />
          </FormStyled>
        )}
      </Formik>
    </div>
  );
};

EmployeeForm.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  employee: PropTypes.shape({
    name: PropTypes.string,
    cpf: PropTypes.string,
    rg: PropTypes.string,
    rg_expiration: PropTypes.string,
    rg_emission: PropTypes.string,
    cnh: PropTypes.string,
    cnh_expiration: PropTypes.string,
    title: PropTypes.string,
    section: PropTypes.string,
    zone: PropTypes.string,
    birth_date: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    type: PropTypes.string,
    bond: PropTypes.string,
    maintenanceemployees: PropTypes.arrayOf(),
  }),
  onEmployeeEdit: PropTypes.func.isRequired,
  onEmployeeDelete: PropTypes.func.isRequired,
  onEmployeeRefresh: PropTypes.func.isRequired,
};

EmployeeForm.defaultProps = {
  employees: [],
  employee: null,
};

export default EmployeeForm;
