import React, { Component } from 'react';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { FormStyled } from '../../styles/form';
import SelectStyled from '../../styles/select';

class TicketFilterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEquipment: null,
      selectedEmployee: null,
      selectedLocal: null,
    };
  }

  handleValidate = () => {
    const errors = {};

    return errors;
  };

  handleSubmit = () => async (values, { setSubmitting }) => {
    const { onList } = this.props;
    const { selectedEquipment, selectedEmployee, selectedLocal } = this.state;

    const data = {
      ...values,
      equipment: selectedEquipment ? selectedEquipment.value : null,
      employee: selectedEmployee ? selectedEmployee.value : null,
      local: selectedLocal ? selectedLocal.value : null,
    };

    if (values || selectedEquipment || selectedEmployee || selectedLocal) {
      await onList({ ...data });
    }

    setSubmitting(false);
  };

  render() {
    const { equipments, employees, locals } = this.props;

    const { selectedEquipment, selectedEmployee, selectedLocal } = this.state;

    const equipmentOptions = equipments
      ? equipments.map(equipment => ({
          value: equipment.uuid,
          label: equipment.code,
        }))
      : [];
    const employeeOptions = employees
      ? employees.map(employee => ({
          value: employee.uuid,
          label: employee.name,
        }))
      : [];
    const localOptions = locals
      ? locals.map(local => ({
          value: local.uuid,
          label: local.name,
        }))
      : [];

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            equipment: selectedEquipment,
            employee: selectedEmployee,
            local: selectedLocal,
          }}
          validate={this.handleValidate}
          onSubmit={this.handleSubmit(this.props)}
        >
          {({ dirty, handleReset, isSubmitting }) => (
            <FormStyled>
              <h1>Filtros</h1>

              <ReactSelect
                placeholder="VEÍCULO"
                isDisabled={!equipments}
                styles={SelectStyled}
                value={selectedEquipment}
                onChange={e => {
                  this.setState({ selectedEquipment: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={equipmentOptions}
              />

              <ReactSelect
                placeholder="FUNCIONÁRIO"
                isDisabled={!employees}
                styles={SelectStyled}
                value={selectedEmployee}
                onChange={e => {
                  this.setState({ selectedEmployee: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={employeeOptions}
              />

              <ReactSelect
                placeholder="LOCAL"
                isDisabled={!locals}
                styles={SelectStyled}
                value={selectedLocal}
                onChange={e => {
                  this.setState({ selectedLocal: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={localOptions}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                style={{ margin: '10px' }}
              >
                BUSCAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={() => {
                  handleReset();
                  this.setState({
                    selectedEquipment: null,
                    selectedEmployee: null,
                    selectedLocal: null,
                  });
                }}
                disabled={
                  (!dirty &&
                    (!selectedEquipment &&
                      !selectedEmployee &&
                      !selectedLocal)) ||
                  isSubmitting
                }
                style={{ margin: '10px' }}
              >
                DESFAZER
              </Button>
            </FormStyled>
          )}
        </Formik>
      </div>
    );
  }
}

TicketFilterForm.propTypes = {
  onList: PropTypes.func.isRequired,
  equipments: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  employees: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  locals: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
};

TicketFilterForm.defaultProps = {
  equipments: [],
  employees: [],
  locals: [],
};

export default TicketFilterForm;
