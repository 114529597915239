import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import io from 'socket.io-client';

import swal from '@sweetalert/with-react';

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';

import ScheduleForm from './ScheduleForm';

import ScheduleList from './ScheduleList';

import ScheduleFilterForm from './ScheduleFilterForm';

import EmployeeContainer from '../Employee/EmployeeContainer';

import LocalContainer from '../Local/LocalContainer';

import MaterialContainer from '../Material/MaterialContainer';

import PartnerContainer from '../Partner/PartnerContainer';

import SnackBar from '../../Utilities/SnackBar';

import Parameters from '../../services/parameters';

import API from '../../services/api';

const styles = theme => ({
  root: {
    width: '90%',

    margin: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 2,

    overflowX: 'auto',
  },
});

class ScheduleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenances: [],

      maintenance: null,

      loadingList: true,

      indexForm: -1,

      filtro: '',

      employeeForm: false,

      employee: null,

      employees: [{ uuid: null, name: 'CARREGANDO' }],

      partnerForm: false,

      partner: null,

      partners: [{ uuid: null, code: 'CARREGANDO' }],

      localForm: false,

      locals: [{ uuid: null, name: 'CARREGANDO' }],

      materialForm: false,

      material: null,

      materials: [{ uuid: null, name: 'CARREGANDO' }],

      begin: new Date().toISOString().substring(0, 10),

      end: new Date().toISOString().substring(0, 10),

      origin: null,

      destiny: null,

      type: null,

      snackBarOpen: false,

      closedList: false,
    };

    // MAITENANCE BIND

    this.handleMaintenanceCreation = this.handleMaintenanceCreation.bind(this);

    this.handleMaintenanceUpdate = this.handleMaintenanceUpdate.bind(this);

    this.handleMaintenanceEdit = this.handleMaintenanceEdit.bind(this);

    this.handleMaintenanceGet = this.handleMaintenanceGet.bind(this);

    this.handleMaintenanceDelete = this.handleMaintenanceDelete.bind(this);

    // employee BIND

    this.handleEmployeesList = this.handleEmployeesList.bind(this);

    this.handleEmployeeFormState = this.handleEmployeeFormState.bind(this);

    // partner BIND

    this.handlePartnersList = this.handlePartnersList.bind(this);

    this.handlePartnerFormState = this.handlePartnerFormState.bind(this);

    // local BIND

    this.handleLocalsList = this.handleLocalsList.bind(this);

    this.handleLocalFormState = this.handleLocalFormState.bind(this);
  }

  refMaintenance = React.createRef();

  componentDidMount() {
    this.registerToSocket();

    this.getListOptions();

    this.getTodosList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('schedule', () => this.getTodosList());
  };

  getListOptions = async () => {
    try {
      const responsePartners = await API.get(`partners`);
      const responseLocals = await API.get(`locals`);
      const responseMaterials = await API.get(`materials`);
      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        partners: responsePartners.data,
        locals: responseLocals.data,
        materials: responseMaterials.data,
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceCreation = async object => {
    const {
      opening_date,

      value,

      qty,

      volume,

      observation,

      type,
    } = object;

    const data = {
      date: opening_date,

      value,

      qty,

      partnerUuid: object.partner ? object.partner.uuid : null,

      materialUuid: object.material ? object.material.uuid : null,

      originUuid: object.origin ? object.origin.uuid : null,

      volume,

      destinyUuid: object.destiny ? object.destiny.uuid : null,

      observation,

      type: type.name,
    };

    try {
      const response = await API.post(`schedules`, { ...data });

      this.handleMaintenanceEdit(response.data);

      await this.getTodosList();

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceUpdate = async object => {
    const { maintenance } = this.state;

    const {
      opening_date,

      value,

      observation,

      volume,

      type,
    } = object;

    const data = {
      date: opening_date,

      value,

      volume,

      partnerUuid: object.partner ? object.partner.uuid : null,

      materialUuid: object.material ? object.material.uuid : null,

      originUuid: object.origin ? object.origin.uuid : null,

      destinyUuid: object.destiny ? object.destiny.uuid : null,

      employeeUuid: object.employee ? object.employee.uuid : null,

      observation,

      type: type.name,
    };

    try {
      const response = await API.put(`schedules/${maintenance.uuid}`, {
        ...data,
      });

      this.handleMaintenanceEdit(response.data);

      await this.getTodosList();

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceDelete = async maintenance => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da Programação?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`schedules/${maintenance.uuid}`);

        this.setState({ snackBarOpen: true });

        this.handleMaintenanceNew();

        await this.getTodosList();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceEdit = maintenance => {
    this.setState(prevState => ({
      maintenance,
    }));

    this.handleMaintenanceGet(maintenance.uuid);

    this.scrollToMyRef(this.refMaintenance);
  };

  handleClosedList = async () => {
    await this.setState(prevState => ({
      closedList: !prevState.closedList,

      loadingList: true,
    }));

    this.getTodosList();
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleMaintenanceNew = () => {
    this.setState(prevState => ({
      maintenance: null,
    }));

    this.scrollToMyRef(this.refMaintenance);
  };

  getTodosList = async values => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      loadingList: true,
    }));

    if (values) {
      // eslint-disable-next-line no-unused-vars
      await this.setState(prevState => ({
        begin: values.date,
        end: values.date2,
        partner: values.partner ? values.partner : null,
        material: values.material ? values.material : null,
        origin: values.origin ? values.origin : null,
        destiny: values.destiny ? values.destiny : null,
      }));
    }

    const { begin, end, type, partner, material, origin, destiny } = this.state;

    // const type = values && values.type ? values.type : null;
    const response = await API.get(
      `schedules/${begin}/${end}/${type || null}/${partner ||
        null}/${material || null}/${origin || null}/${destiny || null}`
    );

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenances: response.data,

      loadingList: false,
    }));
  };

  handleTypeFilter = async value => {
    await this.setState({ type: value });
    this.getTodosList();
  };

  handleMaintenanceGet = async uuid => {
    const response = await API.get(`schedules/${uuid}`);

    this.setState(prevState => ({
      maintenance: response.data[0],
    }));
  };

  handleSnackBarClose = (event, reason) => {
    // if (reason === 'clickaway') {

    //  return;

    // }

    this.setState({ snackBarOpen: false });
  };

  // EMPLOYEES HELPERS

  handleEmployeesList = (employees, employee) => {
    this.setState(prevState => ({
      employees,

      employee,
    }));
  };

  handleEmployeeFormState() {
    const { employeeForm } = this.state;
    this.setState(prevState => ({
      employeeForm: !employeeForm,
    }));
  }

  // partners helper

  handlePartnersList = (partners, partner) => {
    this.setState(prevState => ({
      partners,

      partner,
    }));
  };

  handlePartnerFormState() {
    const { partnerForm } = this.state;
    this.setState(prevState => ({
      partnerForm: !partnerForm,
    }));
  }

  // locals helper

  handleLocalsList = locals => {
    this.setState(prevState => ({
      locals,
    }));
  };

  handleLocalFormState() {
    const { localForm } = this.state;
    this.setState(prevState => ({
      localForm: !localForm,
    }));
  }

  // materials helper

  handleMaterialsList = (materials, material) => {
    this.setState(prevState => ({
      materials,

      material,
    }));
  };

  handleMaterialFormState() {
    const { materialForm } = this.state;
    this.setState(prevState => ({
      materialForm: !materialForm,
    }));
  }

  exportToCSV = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  generateExcelData = data => {
    const arrayExcel = [];

    data.forEach((reportLine, index) => {
      const {
        date,

        value,

        volume,

        partner,

        material,

        origin,

        destiny,

        employee,

        observation,
      } = reportLine;

      const DATA = new Date(date)

        .toLocaleString('pt-BR', {
          timeZone: 'UTC',
        })

        .substring(0, 10);

      const CLIENTE = partner.name;

      const MATERIAL = material.name;

      const PESO = value;

      const VOLUME = volume;

      const ORIGEM = origin.name;

      const DESTINO = destiny.name;

      const MOTORISTA = employee && employee.name;

      const OBSERVACAO = observation;

      const objeto = {
        DATA,

        CLIENTE,

        MATERIAL,

        PESO,

        VOLUME,

        ORIGEM,

        DESTINO,

        MOTORISTA,

        OBSERVACAO,
      };

      arrayExcel.push(objeto);
    });

    return arrayExcel;
  };

  render() {
    const { classes, useruuid } = this.props;

    const {
      maintenances,
      begin,
      locals,
      partners,
      materials,
      indexForm,
      type,
    } = this.state;

    const formatedBegin = new Date(begin).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    });

    const nomeArquivo = `PROGRAMÇÃO NSA DE ${formatedBegin}`;

    const excelData = this.generateExcelData(maintenances);

    return (
      <Fragment>
        <center>
          <Paper className={classes.root}>
            <div className="page-header">
              <h1>PROGRAMAÇÃO NSA</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleMaintenanceNew()}
                >
                  NOVA
                </Button>
              </div>
            </div>
            <hr style={{ width: '90%', marginBottom: '20px' }} />
            <ScheduleFilterForm
              onList={this.getTodosList}
              partners={partners}
              materials={materials}
              locals={locals}
            />
            <hr style={{ width: '90%', margin: '20px' }} />
            <div className="page-header">
              <h1>TOTAL: {maintenances.length}</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={type === 'REGIONAL'}
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleTypeFilter('REGIONAL')}
                >
                  REGIONAL
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={type === 'EXTERNO'}
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleTypeFilter('EXTERNO')}
                >
                  EXTERNO
                </Button>
              </div>

              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: '30px', float: 'left' }}
                onClick={() => this.exportToCSV(excelData, nomeArquivo)}
              >
                EXCEL
              </Button>
            </div>
            <ScheduleList
              maintenances={this.state.maintenances}
              loadingList={this.state.loadingList}
              onMaintenanceEdit={this.handleMaintenanceEdit}
              onMaintenanceDeletonlie={this.handleMaintenanceDelete}
            />
            <div ref={this.refMaintenance} />
            <div className="page-header">
              <h1>PROGRAMAÇÂO</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => {
                    if (indexForm === 0) {
                      this.setState({ indexForm: -1 });
                    } else {
                      this.setState({ indexForm: 0 });
                    }
                  }}
                >
                  MATERIAL
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => {
                    if (indexForm === 1) {
                      this.setState({ indexForm: -1 });
                    } else {
                      this.setState({ indexForm: 1 });
                    }
                  }}
                >
                  LOCAL
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => {
                    if (indexForm === 2) {
                      this.setState({ indexForm: -1 });
                    } else {
                      this.setState({ indexForm: 2 });
                    }
                  }}
                >
                  CLIENTE
                </Button>

                {/* {useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
                useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                useruuid === '7eff000c-91d9-4c54-b597-79bd93938325' ? ( */}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '30px', float: 'left' }}
                    onClick={() => {
                      if (indexForm === 3) {
                        this.setState({ indexForm: -3 });
                      } else {
                        this.setState({ indexForm: 3 });
                      }
                    }}
                  >
                    FUNCIONÁRIO
                  </Button>
                {/* ) : null} */}
              </div>
            </div>

            <MaterialContainer
              onMaterialsList={this.handleMaterialsList}
              onMaterialCreation={() => this.setState({ indexForm: -1 })}
              visible={indexForm === 0}
            />

            <LocalContainer
              onLocalsList={this.handleLocalsList}
              onLocalCreation={() => this.setState({ indexForm: -1 })}
              visible={indexForm === 1}
            />

            <PartnerContainer
              onPartnersList={this.handlePartnersList}
              onPartnerCreation={() => this.setState({ indexForm: -1 })}
              visible={indexForm === 2}
            />

            <EmployeeContainer
              onEmployeesList={this.handleEmployeesList}
              onEmployeeCreation={() => this.setState({ indexForm: -1 })}
              visible={indexForm === 3}
            />

            <ScheduleForm
              onMaintenanceCreation={this.handleMaintenanceCreation}
              onMaintenanceUpdate={this.handleMaintenanceUpdate}
              onMaintenanceNew={this.handleMaintenanceNew}
              onMaintenanceDelete={this.handleMaintenanceDelete}
              maintenance={this.state.maintenance}
              employees={this.state.employees}
              employee={this.state.employee}
              partners={this.state.partners}
              partner={this.state.partner}
              locals={this.state.locals}
              materials={this.state.materials}
              material={this.state.material}
              useruuid={useruuid}
            />
          </Paper>

          <SnackBar
            onSnackBarClose={this.handleSnackBarClose}
            snackBarOpen={this.state.snackBarOpen}
          />
        </center>
      </Fragment>
    );
  }
}

ScheduleContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ScheduleContainer);
