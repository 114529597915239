import styled from 'styled-components';

import { Form } from 'formik';

export const FormStyled = styled(Form)`
  padding: 32px 64px;

  border-radius: 4px;

  margin: 0 64px;

  -webkit-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  -moz-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  div.line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div.formData {
      width: 50%;
      max-width: 300px;

      & + div {
        margin-left: 32px;
      }
    }
  }

  div.formData {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 32px;
    padding: 0;

    strong {
      font-size: 14px;
      margin-bottom: 8px;
      color: #333;
      opacity: 75%;
    }

    input {
      width: 100%;
      height: 40px;
      border: 0;
      padding: 6px 12px;
      font-size: 16px;
      color: #333;
      border: 1px solid #ecec;

      &:disabled {
        background: none;
        color: #999;
        cursor: default;
      }
    }

    select {
      width: 100%;
      height: 40px;
      border: 0;
      font-size: 16px;
      color: #333;
      background: none;
      border-bottom: 1px solid #333;

      &:disabled {
        background: none;
        color: #999;
        cursor: default;
      }
    }

    textarea {
      width: 100%;
      height: 40px;
      border: 0;
      padding-top: 15px;
      font-size: 16px;
      color: #333;
      border-bottom: 1px solid #333;
      outline: none;
      font-family: Arial, Helvetica, sans-serif;

      &:disabled {
        background: none;
        color: #999;
        cursor: default;
      }
    }

    span {
      margin-top: 8px;
      color: red;
      font-size: 14px;
    }
  }

  div.division {
    margin: 32px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    hr {
      height: 1px;
      border: 0;
      width: 100%;
      background: #999;
      margin: 0;
    }

    p {
      font-size: 16px;
      color: #999;
      margin: 0 4px;
    }
  }

  button {
    height: 35px;

    border-radius: 4px;

    padding: 0 15px;

    background: #055aab;

    font-size: 16px;

    color: #fff;

    margin-top: 32px;

    & + button {
      margin-left: 16px;
    }

    &:hover {
      background: rgba($color: #055aab, $alpha: 0.2);
    }

    &.delete {
      background: red;
      background: rgba($color: 'red', $alpha: 0.2);
    }

    &:disabled {
      background: #ddd;

      color: #999;

      cursor: default;
    }
  }
`;
