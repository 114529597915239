import React, { Component } from 'react';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';

import API from '../../services/api';
import MaterialForm from './MaterialForm';

class MaterialContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materials: null,
    };

    this.handleMaterialCreation = this.handleMaterialCreation.bind(this);

    this.getMaterialsList();
  }

  refMaterials = React.createRef();

  getMaterialsList = async () => {
    const { onMaterialsList } = this.props;
    const { material: selectedMaterial } = this.state;

    try {
      const response = await API.get(`materials`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        materials: response.data,
      }));

      onMaterialsList(response.data, selectedMaterial);
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaterialRefresh = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      material: null,
    }));
  };

  handleMaterialCreation = async object => {
    const { onMaterialCreation } = this.props;
    const name = object.name.toUpperCase();

    try {
      const response = await API.post(`materials`, { name });

      toast.success('Material cadastrado com sucesso.');

      this.getMaterialsList(response.data);

      onMaterialCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaterialUpdate = async object => {
    const { material } = this.state;

    const name = object.name.toUpperCase();

    try {
      const response = await API.put(`materials/${material.uuid}`, { name });

      this.getMaterialsList(response.data);

      toast.success('Material alterado com sucesso.');

      this.setState({ material: null });
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaterialEdit = object => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      material: object,
    }));
  };

  handleMaterialDelete = async () => {
    const { material } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do material?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        const response = await API.delete(`materials/${material.uuid}`);

        this.setState({ material: null });

        this.getMaterialsList(response.data);

        toast.success('Material deletado com sucesso.');
      }
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { visible } = this.props;
    const { materials, material } = this.state;

    return (
      <div>
        {visible ? (
          <>
            <div ref={this.refMaterials} />

            <MaterialForm
              material={material}
              materials={materials}
              onMaterialRefresh={this.handleMaterialRefresh}
              onMaterialCreation={this.handleMaterialCreation}
              onMaterialEdit={this.handleMaterialEdit}
              onMaterialUpdate={this.handleMaterialUpdate}
              onMaterialDelete={this.handleMaterialDelete}
            />
          </>
        ) : null}
      </div>
    );
  }
}

MaterialContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onMaterialsList: PropTypes.func.isRequired,
  onMaterialCreation: PropTypes.func.isRequired,
};

export default MaterialContainer;
