import React from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Moment from 'moment';

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit * 1,

    width: '40%',

    textAlign: 'center',
  },
});

const handleValidate = ({ closing_description, closing_date, time }) => {
  const errors = {};

  if (!closing_description) errors.closing_description = 'OBRIGATÓRIO';

  if (!closing_date) errors.closing_date = 'OBRIGATÓRIO';

  if (!time) errors.time = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onMaintenanceClose({ ...values });

  setSubmitting(false);
};

const TodoForm = props => {
  const { classes } = props;

  const hora_atual = Moment().format('HH:mm');

  console.log(hora_atual);

  return (
    <div>
      <h1>FECHAMENTO</h1>

      <Formik
        enableReinitialize
        initialValues={{
          closing_description: !props.maintenance.closing_date
            ? ''
            : props.maintenance.closing_description,

          closing_date: !props.maintenance.closing_date
            ? new Date().toISOString().substring(0, 10)
            : props.maintenance.closing_date.substring(0, 10),

          time: !props.maintenance
            ? hora_atual
            : props.maintenance.closing_date
            ? props.maintenance.closing_date.substring(11, 16)
            : hora_atual,
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {props => (
          <Form>
            <div>
              <TextField
                id="closing_date"
                name="closing_date"
                label="Data"
                variant="outlined"
                type="date"
                disabled
                value={props.values.closing_date}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                className={classes.textField}
                error={
                  !!(props.errors.closing_date && props.touched.closing_date)
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {props.errors.closing_date && props.touched.closing_date && (
                <div style={{ color: 'red' }}>{props.errors.closing_date}</div>
              )}

              <TextField
                id="time"
                name="time"
                variant="outlined"
                value={props.values.time}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                label="Hora"
                type="time"
                disabled
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800, // 30 min
                }}
                error={!!(props.errors.time && props.touched.time)}
              />

              {props.errors.time && props.touched.time && (
                <div style={{ color: 'red' }}>{props.errors.time}</div>
              )}
            </div>

            <div>
              <TextField
                label="Descrição de Fechamento"
                placeholder="Digite a Descrição de Fechamento..."
                multiline
                margin="normal"
                id="closing_description"
                variant="outlined"
                name="closing_description"
                type="closing_description"
                value={props.values.closing_description}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                style={{
                  width: '90%',

                  textAlign: 'left',
                }}
                error={
                  !!(
                    props.errors.closing_description &&
                    props.touched.closing_description
                  )
                }
              />

              {props.errors.closing_description &&
                props.touched.closing_description && (
                  <div style={{ color: 'red' }}>
                    {props.errors.closing_description}
                  </div>
                )}
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!props.dirty || props.isSubmitting}
              style={{ marginTop: '10px' }}
            >
              FINALIZAR
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

TodoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TodoForm);
