/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import { RefuelListStyles } from './RefuelListStyles';
import Loading from '../components/Loading';

const listItem = props =>
  props.maintenances.map(refuel => (
    <div
      className="item"
      key={refuel.uuid}
      onClick={e => {
        props.onMaintenanceEdit(refuel);
      }}
    >
      {/* <p className="po">{usuario.nome}</p> */}
      <p className="code">
        {new Date(refuel.date).toLocaleString('pt-BR', {
          timeZone: 'UTC',
        })}
      </p>
      <p className="date">{refuel.equipment.code}</p>
      <p className="type">{refuel.type}</p>
      <p className="type">{refuel.km}</p>
      <p className="type">{refuel.qty}</p>
      <p className="type">
        {refuel.value.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="type">
        {refuel.media &&
          refuel.media.toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
          })}
      </p>
    </div>
  ));

const listItemGroup = props =>
  props.maintenances.map((refuel, index) => (
    <div className="item" key={index}>
      {/* <p className="po">{usuario.nome}</p> */}
      <p className="code">{refuel.equipment.code}</p>
      <p className="date">
        {refuel.begin.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="date">
        {refuel.end.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="date">
        {refuel.rode.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="date">
        {refuel.refuel.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="date">
        {refuel.value.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="date">
        {refuel.media.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
    </div>
  ));

const listHeader = () => (
  <div className="header" key={1} style={{ background: 'white' }}>
    <p className="code">DATA</p>
    <p className="date">PLACA</p>
    <p className="type">TIPO</p>
    <p className="type">KM ATUAL</p>
    <p className="type">QUANTIDADE</p>
    <p className="type">VALOR</p>
    <p className="type">MEDIA</p>
  </div>
);

const listHeaderGroup = () => (
  <div className="header" key={1} style={{ background: 'white' }}>
    <p className="code">PLACA</p>
    <p className="date">INICIAL</p>
    <p className="date">FINAL</p>
    <p className="date">RODADO</p>
    <p className="date">ABASTECIDO</p>
    <p className="date">VALOR</p>
    <p className="date">MEDIA</p>
  </div>
);

function MaintenanceList(props) {
  return (
    <Fragment>
      {!props.loadingList ? (
        props.maintenances.length > 0 ? (
          <Fragment>
            <RefuelListStyles>
              {props.group ? listHeaderGroup() : listHeader()}
              {props.group ? listItemGroup(props) : listItem(props)}
            </RefuelListStyles>
          </Fragment>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}
      <hr style={{ width: '90%', marginTop: '20px' }} />
    </Fragment>
  );
}

export default MaintenanceList;
