import React from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

import SelectMUI from '@material-ui/core/Select';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 1,

    marginBottom: theme.spacing.unit * 3,

    width: '40%',

    textAlign: 'center',
  },

  rootSelect: {
    display: 'flex',

    flexWrap: 'wrap',

    width: '90%',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 1,

    width: '40%',

    textAlign: 'center',
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const handleValidate = ({ quantity, value, piece }) => {
  const errors = {};

  if (!quantity) errors.quantity = 'OBRIGATÓRIO';

  if (!value) errors.value = 'OBRIGATÓRIO';

  if (!piece) errors.piece = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onMaintenancePieceCreation({ ...values });

  setSubmitting(false);

  resetForm();
};

const MaintenancePieceForm = props => {
  // const onMaintenanceNew = () => props.onMaintenanceNew();

  const { classes, pieces } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          quantity: '',

          value: '0',

          piece: props.piece ? props.piece.uuid : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {props => (
          <Form>
            <Paper className={classes.root}>
              <h1>PEÇA DA MANUTENÇÃO</h1>

              <div className={classes.formControl}>
                <div>
                  <InputLabel
                    style={{ disableAnimation: false }}
                    disableAnimation={false}
                    htmlFor="piece"
                  >
                    PEÇA
                  </InputLabel>
                </div>

                <SelectMUI
                  id="piece"
                  name="piece"
                  value={props.values.piece}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  inputProps={{
                    name: 'piece',

                    id: 'piece',
                  }}
                  style={{
                    width: '90%',

                    margin: '10px',

                    marginBottom: '20px',
                  }}
                  error={!!(props.errors.piece && props.touched.piece)}
                >
                  {pieces &&
                    pieces.length > 0 &&
                    pieces.map(piece => (
                      <MenuItem value={piece.uuid}>
                        {piece.description} ( {piece.total || 0} )
                      </MenuItem>
                    ))}
                </SelectMUI>
              </div>

              <div className={classes.formControl}>
                <TextField
                  id="quantity"
                  label="Quantidade"
                  value={props.values.quantity}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  variant="outlined"
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={!!(props.errors.quantity && props.touched.quantity)}
                />

                {/*

                <TextField

                  id="value"

                  label="Valor"

                  value={props.values.value}

                  onChange={props.handleChange}

                  onBlur={props.handleBlur}

                  variant="outlined"

                  type="number"

                  className={classes.textFieldNumber}

                  InputLabelProps={{

                    shrink: true,

                  }}

                  margin="normal"

                  error={

                    props.errors.value && props.touched.value ? true : false

                  }

                />

                */}
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.isSubmitting}
                style={{ margin: '10px' }}
              >
                SALVAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>
            </Paper>
          </Form>
        )}
      </Formik>
    </div>
  );
};

MaintenancePieceForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaintenancePieceForm);
