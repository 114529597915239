import React, { Component } from 'react';

import moment from 'moment';

import PropTypes from 'prop-types';

import { ListStyled, ListLink } from './styles';

import Loading from '../components/Loading';

class AsuList extends Component {
  AsuListItem = asus =>
    asus.map(asu => (
      <ListLink
        gridheader="1fr 1fr 1fr"
        gridlist="1fr 1fr 1fr"
        color="#011826"
        strongcolor="#fff"
        to={`/asus/${asu.uuid}`}
        key={asu.uuid}
      >
        <strong>{asu.employee.name ? asu.employee.name : '---'}</strong>
        <strong>{asu.company ? asu.company : '---'}</strong>
        <strong>
          {asu.execution_at
            ? moment(asu.execution_at).format('DD/MM/YYYY')
            : '---'}
        </strong>
      </ListLink>
    ));

  render() {
    const { isLoading, asus } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {asus && (
              <>
                {asus.length > 0 ? (
                  <ListStyled gridheader="1fr 1fr 1fr" gridlist="1fr 1fr 1fr">
                    <header>
                      <div className="total">
                        <h1>TOTAL: </h1>

                        <p>{asus.length}</p>
                      </div>

                      <div className="titles">
                        <strong>FUNCIONÁRIO</strong>
                        <strong>EMPRESA</strong>
                        <strong>DATA DE EXECUÇÃO</strong>
                      </div>
                    </header>

                    {this.AsuListItem(asus)}
                  </ListStyled>
                ) : (
                  <ListStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

AsuList.propTypes = {
  isLoading: PropTypes.bool,

  asus: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      company: PropTypes.string,
      executation_at: PropTypes.string,
      employee: PropTypes.shape({}),
    })
  ),
};

AsuList.defaultProps = {
  asus: [],

  isLoading: false,
};

export default AsuList;
