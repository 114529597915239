import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

import API from '../../services/api';

import Loading from '../components/Loading';

// Components

import RoleForm from './form';

import RoleList from './list';

import ExamRoleContainer from './ExamRole';

// Styles

import { Wrapper, Container, Content } from './styles';

class RoleContainer extends Component {
  state = {
    modalAddRole: false,

    isLoading: true,

    roles: [],

    role: null,

    isLoadingRole: false,
  };

  async componentDidMount() {
    const { match } = this.props;
    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getRole(uuid);
    }

    this.getRoles();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  getRoles = async () => {
    const res = await API.get(`roles`);

    this.setState({
      roles: res.data,

      isLoading: false,
    });
  };

  async getRole(uuid) {
    this.setState({
      isLoadingRole: true,

      modalAddRole: true,
    });

    await API.get(`roles/${uuid}`).then(res => {
      const role = res.data[0];

      this.setState({
        role,

        isLoadingRole: false,
      });
    });
  }

  handleRoleDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão de Função?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      await API.delete(`roles/${uuid}`).then(() => {
        this.getRoles();

        this.setState({
          role: null,

          modalAddRole: false,
        });
      });
    }
  };

  handleRoleCreation = async values => {
    try {
      const description = values.description.toUpperCase();

      await API.post(
        'roles',
        {
          description,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getRoles();

      this.setState({ modalAddRole: false });
    } catch (err) {
      alert(err);
    }
  };

  handleRoleUpdate = async values => {
    const { role } = this.state;

    try {
      const description = values.description.toUpperCase();

      await API.put(
        `roles/${role.uuid}`,
        {
          description,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getRoles();

      this.setState({ modalAddRole: false, role: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddRole,

      isLoadingRole,

      roles,

      isLoading,

      role,
    } = this.state;

    const useruuid = localStorage.getItem('USER_UUID');

    return (
      <Wrapper>
        {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
          useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
          useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
          useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
          <Container>
            <header>
              <h1>FUNCIONÁRIOS</h1>

              <Link
                to="/roles"
                onClick={() =>
                  this.setState({
                    modalAddRole: !modalAddRole,

                    role: null,
                  })
                }
              >
                {!modalAddRole ? 'ADICIONAR' : 'LISTA'}
              </Link>
            </header>

            <Content>
              {modalAddRole ? (
                <>
                  {!isLoadingRole ? (
                    <>
                      <RoleForm
                        onRoleCreation={this.handleRoleCreation}
                        onRoleUpdate={this.handleRoleUpdate}
                        isLoadingRole={isLoadingRole}
                        onRoleDelete={this.handleRoleDelete}
                        role={role}
                      />

                      {role && <ExamRoleContainer role={role} />}
                    </>
                  ) : (
                    <Loading />
                  )}
                </>
              ) : (
                <>
                  <RoleList
                    isLoading={isLoading}
                    onRoleGet={this.getRole}
                    roles={roles}
                  />
                </>
              )}
            </Content>
          </Container>
        )}
      </Wrapper>
    );
  }
}

RoleContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default RoleContainer;
