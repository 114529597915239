import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../../services/api';

// Components

import AsuExamList from './list';

import AsuExamForm from './form';

import ExamContainer from '../../Exam';

// Styles

import { SubContainer, SubContent } from '../styles';

class AsuExamContainer extends Component {
  state = {
    isLoading: true,

    asuExams: null,

    exams: null,

    examModal: false,
  };

  async componentDidMount() {
    this.mount();
  }

  mount() {
    this.getAsuExams();

    this.getExams();
  }

  async getAsuExams() {
    this.setState({
      isLoading: true,
    });

    const { asu } = this.props;

    API.get(`asuexams/list/${asu.uuid}`).then(res => {
      const result = res.data;

      this.setState({
        asuExams: result,

        isLoading: false,
      });
    });
  }

  handleAsuExamCreation = async values => {
    const { asu } = this.props;

    try {
      await API.post(
        'asuexams',

        {
          examUuid: values.examUuid,
          asuUuid: asu.uuid,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getAsuExams();
    } catch (err) {
      alert(err);
    }
  };

  async getExams() {
    this.setState({
      isLoading: true,
    });

    API.get(`exams`).then(res => {
      const result = res.data;

      this.setState({
        exams: result,

        isLoading: false,
      });
    });
  }

  handleAsuExamDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão de Exame?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`asuexams/${uuid}`).then(() => {
        this.getAsuExams();
      });
    }
  };

  // Exams

  handleExamsList = list => {
    this.setState({
      exams: list,
    });
  };

  render() {
    const { isLoading, asuExams, exams, examModal } = this.state;

    return (
      <SubContainer>
        <header>
          <h1>FUNÇÕES</h1>

          <button
            type="button"
            onClick={() => {
              this.setState({ examModal: !examModal });
            }}
          >
            NOVO EXAME
          </button>
        </header>

        <SubContent>
          <ExamContainer
            onExamsList={this.handleExamsList}
            onExamCreation={() => this.setState({ examModal: !examModal })}
            visible={examModal}
          />

          <AsuExamList
            isLoading={isLoading}
            onAsuExamDelete={this.handleAsuExamDelete}
            asuExams={asuExams}
          />

          <AsuExamForm
            onAsuExamCreation={this.handleAsuExamCreation}
            exams={exams}
          />
        </SubContent>
      </SubContainer>
    );
  }
}

AsuExamContainer.propTypes = {
  asu: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default AsuExamContainer;
