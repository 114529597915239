import React, { Fragment } from 'react';

import { TankListStyles } from './TankListStyles';

import Loading from '../components/Loading';

const listItem = props =>
  props.tankRefuels.map(tankRefuel => (
    <div
      className="item"
      key={tankRefuel.uuid}

      /* onClick={e => {

        props.onMaintenanceEdit(refuel);

      }} */
    >
      {/* <p className="po">{usuario.nome}</p> */}

      <p className="code">
        {new Date(tankRefuel.createdAt).toLocaleString('pt-BR')}
      </p>

      <p className="type">{tankRefuel.qty}</p>

      <p className="type">{tankRefuel.document}</p>

      <p className="type">{tankRefuel.value}</p>
    </div>
  ));

const listHeader = () => (
  <div className="header" key={1} style={{ background: 'white' }}>
    <p className="code">DATA</p>

    <p className="date">QUANTIDADE</p>

    <p className="type">TIPO</p>

    <p className="type">VALOR</p>
  </div>
);

function TankList(props) {
  return (
    <Fragment>
      {!props.loadingRefuels ? (
        props.tankRefuels &&
        props.tankRefuels[0] &&
        props.tankRefuels.length > 0 ? (
          <Fragment>
            <TankListStyles>
              {listHeader()}

              {listItem(props)}
            </TankListStyles>
          </Fragment>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}
    </Fragment>
  );
}

export default TankList;
