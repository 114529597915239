import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import swal from '@sweetalert/with-react';
import EmployeeContainer from '../../Employee/EmployeeContainer';
import MaintenanceEmployeeForm from './MaintenanceEmployeeForm';
import MaintenanceEmployeeList from './MaintenanceEmployeeList';
import API from '../../../services/api';

class MaintenanceEmployeeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingList: true,

      maintenanceEmployees: null,

      maintenanceEmployee: null,

      employeeForm: false,

      employee: null,

      employees: [{ uuid: null, name: 'CARREGANDO' }],
    };

    this.handleMaintenanceEmployeeCreation = this.handleMaintenanceEmployeeCreation.bind(
      this
    );
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { maintenance } = this.props;

    if (maintenance !== prevProps.maintenance) {
      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        loadingList: true,
      }));

      this.getMaintenanceEmployeesList();
    }
  }

  refMaintenanceEmployees = React.createRef();

  getMaintenanceEmployeesList = async maintenanceEmployee => {
    const { maintenance, onMaintenanceEmployeesList } = this.props;

    try {
      const response = await API.get(
        `maintenanceemployees/${maintenance.uuid}`
      );

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        maintenanceEmployees: response.data,

        loadingList: false,
      }));
      onMaintenanceEmployeesList(response.data, maintenanceEmployee);
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceEmployeeUpdate = async object => {
    const { maintenanceEmployee } = this.state;

    const data = {
      employeeUuid: object.employee,

      start: `${object.start_date}T${object.start_time}`,

      end:
        object.end_date && object.end_time
          ? `${object.end_date}T${object.end_time}`
          : null,
    };

    try {
      const response = await API.put(
        `maintenanceemployees/${maintenanceEmployee.uuid}`,
        { ...data }
      );

      this.handleMaintenanceEmployeeNew();

      await this.getMaintenanceEmployeesList(response.data);
    } catch (error) {
      toast.error(error);
    }
  };

  handleMaintenanceEmployeeCreation = async maintenanceEmployee => {
    const { maintenance, onMaintenanceEmployeeCreation } = this.props;

    const data = {
      maintenanceUuid: maintenance.uuid,

      employeeUuid: maintenanceEmployee.employee,

      start:
        maintenanceEmployee.start_time && maintenanceEmployee.start_date
          ? `${maintenanceEmployee.start_date}T${maintenanceEmployee.start_time}`
          : null,

      end:
        maintenanceEmployee.end_date && maintenanceEmployee.end_time
          ? `${maintenanceEmployee.end_date}T${maintenanceEmployee.end_time}`
          : null,
    };

    try {
      const response = await API.post(`maintenanceemployees`, { ...data });

      this.getMaintenanceEmployeesList(response.data);
      this.handleMaintenanceEmployeeNew();
      onMaintenanceEmployeeCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceEmployeeNew = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenanceEmployee: null,

      employee: null,
    }));
  };

  handleMaintenanceEmployeeEdit = maintenanceEmployee => {
    const { onMaintenanceEmployeeCreation } = this.props;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenanceEmployee,
    }));

    onMaintenanceEmployeeCreation(true);
  };

  handleMaintenanceEmployeeDelete = async maintenanceEmployee => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão do funcionário da manutenção?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`maintenanceemployees/${maintenanceEmployee.uuid}`);
        await this.getMaintenanceEmployeesList();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  // EMPLOYEES HELPERS

  handleEmployeesList = (employees, employee) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      employees,

      employee,
    }));
  };

  handleEmployeeFormState() {
    const { employeeForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      employeeForm: !employeeForm,
    }));
  }

  render() {
    const { useruuid } = this.props;

    return (
      <div>
        <div ref={this.refMaintenanceEmployees} />

        {this.props.maintenanceEmployeeForm ? (
          <Fragment>
            <MaintenanceEmployeeList
              maintenanceEmployees={this.state.maintenanceEmployees}
              onMaintenanceEmployeeEdit={this.handleMaintenanceEmployeeEdit}
              onMaintenanceEmployeeDelete={this.handleMaintenanceEmployeeDelete}
              loadingList={this.state.loadingList}

              // onMaintenanceEdit={this.handleMaintenanceEdit}
            />

            <div className="page-header">
              <h1>ADICIONAR</h1>

              {useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
              useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
              useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
              useruuid === '7eff000c-91d9-4c54-b597-79bd93938325' ? (
                <div className="last-wrap">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => this.handleEmployeeFormState()}
                    style={{ marginRight: '30px', float: 'right' }}
                  >
                    FUNCIONÁRIO
                  </Button>
                </div>
              ) : null}
            </div>

            <EmployeeContainer
              onEmployeesList={this.handleEmployeesList}
              onEmployeeCreation={() => this.handleEmployeeFormState()}
              employeeForm={this.state.employeeForm}
            />

            <MaintenanceEmployeeForm
              onMaintenanceEmployeeUpdate={this.handleMaintenanceEmployeeUpdate}
              onMaintenanceEmployeeNew={this.handleMaintenanceEmployeeNew}
              onMaintenanceEmployeeCreation={
                this.handleMaintenanceEmployeeCreation
              }
              maintenanceEmployee={this.state.maintenanceEmployee}
              employees={this.state.employees}
              employee={this.state.employee}
            />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

export default MaintenanceEmployeeContainer;
