import React from 'react';

import { Formik } from 'formik';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import imgloading from '../../../img/load.gif';

import { FormStyled } from '../styles';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ examUuid }) => {
  const errors = {};

  if (!examUuid) errors.examUuid = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onExamRoleCreation({ ...values });

  setSubmitting(false);

  resetForm();
};

const ExamRoleForm = props => {
  const { exams } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        examUuid: '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleBlur,

        errors,

        touched,

        isSubmitting,

        handleReset,

        dirty,
      }) => (
        <FormStyled>
          <h1>ADICIONAR EXAME</h1>

          <div className="formData">
            <strong>EXAME</strong>

            <select
              id="examUuid"
              name="examUuid"
              value={values.examUuid}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <>
                <option value="" />

                {exams &&
                  exams.length > 0 &&
                  exams.map(object => (
                    <option key={object.uuid} value={object.uuid}>
                      {object.description}
                    </option>
                  ))}
              </>
            </select>

            {errors.examUuid && touched.examUuid && (
              <span>{errors.examUuid}</span>
            )}
          </div>

          <button variant="contained" type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

ExamRoleForm.propTypes = {
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

ExamRoleForm.defaultProps = {
  exams: [],
};

export default ExamRoleForm;
