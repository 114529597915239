import React, { Component } from 'react';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import CalibrationForm from './CalibrationForm';
import API from '../../services/api';
import Parameters from '../../services/parameters';

class CalibrationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipments: null,
      equipment: null,
      snackBarOpen: false,
    };

    this.handleEquipmentCreation = this.handleEquipmentCreation.bind(this);

    this.getEquipmentsList();
  }

  componentDidMount() {
    this.registerToSocket();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('equipment', () => this.getEquipmentsList());
  };

  refEquipment = React.createRef();

  getEquipmentsList = async () => {
    const { onEquipmentsList } = this.props;
    const { equipment: selectedEquipment } = this.state;

    try {
      const response = await API.get('/equipments');
      const dataFilter = response.data.filter(data => data.type === 'CAVALO')
      this.setState(prevState => ({
        equipments: dataFilter,
      }));

      onEquipmentsList(dataFilter, selectedEquipment);
    } catch (err) {
      toast.error(err);
    }
  };

  handleEquipmentRefresh = () => {
    this.setState(prevState => ({
      equipment: null,
    }));
  };

  handleEquipmentCreation = async equipment => {
    const { onEquipmentCreation } = this.props;

    const data = {
      code: equipment.code.toUpperCase(),

      year: equipment.year,

      type: equipment.type,
    };

    try {
      const response = await API.post('/equipments', { ...data });

      this.getEquipmentsList(response.data);
      console.log('response.data',response.data)
      onEquipmentCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { calibrationForm, onCalibrationCreation } = this.props;
    const { equipments, equipment } = this.state;

    return (
      <div>
        {calibrationForm ? (
          <>
            <div ref={this.refEquipment} />
            <CalibrationForm
              equipments={equipments}
              equipment={equipment}
              onEquipmentRefresh={this.handleEquipmentRefresh}
              onEquipmentCreation={this.handleEquipmentCreation}
              onCalibrationCreate={onCalibrationCreation}
            />
          </>
        ) : null}
      </div>
    );
  }
}

CalibrationContainer.propTypes = {
  onEquipmentsList: PropTypes.func.isRequired,
  onEquipmentCreation: PropTypes.func.isRequired,
  calibrationForm: PropTypes.bool.isRequired,
  onCalibrationCreate: PropTypes.func.isRequired,
};

export default CalibrationContainer;
