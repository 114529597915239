/* eslint-disable no-nested-ternary */

import React, { Fragment } from 'react';

import { TicketListStyles } from './TicketListStyles';

import Loading from '../components/Loading';

const listItem = props =>
  props.tickets.map(object => (
    <div
      className="item"
      key={object.uuid}
      onClick={e => {
        props.onTicketEdit(object);
      }}
    >
      <p className="code">
        {new Date(object.date).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })}
      </p>

      <p className="date">{object.equipment ? object.equipment.code : '-'}</p>

      <p className="type">{object.employee ? object.employee.name : '-'}</p>

      <p className="type">{object.local ? object.local.name : '-'}</p>
    </div>
  ));

const listHeader = () => (
  <div className="header" key={1} style={{ background: 'white' }}>
    <p className="code">DATA</p>

    <p className="date">VEÍCULO</p>

    <p className="type">FUNCIONÁRIO</p>

    <p className="type">LOCAL</p>
  </div>
);

function TicketList(props) {
  return (
    <Fragment>
      {!props.loadingList ? (
        props.tickets.length > 0 ? (
          <Fragment>
            <TicketListStyles>
              {listHeader()}

              {listItem(props)}
            </TicketListStyles>
          </Fragment>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}

      <hr style={{ width: '90%', marginTop: '20px' }} />
    </Fragment>
  );
}

export default TicketList;
