import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../services/api';

// Components

import ExamForm from './form';

class ExamContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exams: null,
    };

    this.handleExamCreation = this.handleExamCreation.bind(this);

    this.getExamsList();
  }

  getExamsList = async () => {
    const { onExamsList } = this.props;

    try {
      const response = await API.get(`exams`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        exams: response.data,
      }));

      onExamsList(response.data);
    } catch (err) {
      toast.error(err);
    }
  };

  handleExamRefresh = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      exam: null,
    }));
  };

  handleExamCreation = async object => {
    const { onExamCreation } = this.props;
    const description = object.description.toUpperCase();

    const data = {
      description,
      expiration: object.expiration || null,
    };

    try {
      const response = await API.post(`exams`, data);

      toast.success('Exame cadastrado com sucesso.');

      this.getExamsList(response.data);

      onExamCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handleExamUpdate = async object => {
    const { exam } = this.state;
    const description = object.description.toUpperCase();

    const data = {
      description,
      expiration: object.expiration || null,
    };

    try {
      const response = await API.put(`exams/${exam.uuid}`, data);

      this.getExamsList(response.data);

      toast.success('Exame alterado com sucesso.');

      this.setState({ exam: null });
    } catch (err) {
      toast.error(err);
    }
  };

  handleExamEdit = object => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      exam: object,
    }));
  };

  handleExamDelete = async () => {
    const { exam } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do exame?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        const response = await API.delete(`exams/${exam.uuid}`);

        this.setState({ exam: null });

        this.getExamsList(response.data);

        toast.success('Exame deletado com sucesso.');
      }
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { visible } = this.props;
    const { exams, exam } = this.state;

    return (
      <>
        {visible ? (
          <ExamForm
            exams={exams}
            exam={exam}
            onExamRefresh={this.handleExamRefresh}
            onExamCreation={this.handleExamCreation}
            onExamEdit={this.handleExamEdit}
            onExamUpdate={this.handleExamUpdate}
            onExamDelete={this.handleExamDelete}
          />
        ) : null}
      </>
    );
  }
}

ExamContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onExamsList: PropTypes.func.isRequired,
  onExamCreation: PropTypes.func.isRequired,
};

export default ExamContainer;
