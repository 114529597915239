import React, { Component } from 'react';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import EquipmentForm from './EquipmentForm';
import API from '../../services/api';
import Parameters from '../../services/parameters';

class EquipmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipments: null,
      equipment: null,
    };

    this.handleEquipmentCreation = this.handleEquipmentCreation.bind(this);

    this.getEquipmentsList();
  }

  componentDidMount() {
    this.registerToSocket();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('equipment', () => this.getEquipmentsList());
  };

  refEquipment = React.createRef();

  getEquipmentsList = async () => {
    const { onEquipmentsList } = this.props;
    const { equipment: selectedEquipment } = this.state;

    try {
      const response = await API.get('/equipments');

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        equipments: response.data,
      }));

      onEquipmentsList(response.data, selectedEquipment);
    } catch (err) {
      toast.error(err);
    }
  };

  handleEquipmentRefresh = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      equipment: null,
    }));
  };

  handleEquipmentCreation = async equipment => {
    const { onEquipmentCreation } = this.props;

    const data = {
      code: equipment.code.toUpperCase(),

      year: equipment.year,

      type: equipment.type,
    };

    try {
      const response = await API.post('/equipments', { ...data });

      this.getEquipmentsList(response.data);
      onEquipmentCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handleEquipmentUpdate = async object => {
    const { equipment } = this.state;

    const data = {
      code: object.code.toUpperCase(),

      year: object.year,

      type: object.type,
    };

    try {
      const response = await API.put(`equipments/${equipment.uuid}`, data);

      this.getEquipmentsList(response.data);

      toast.success('Veículo alterado com sucesso.');

      this.setState({ equipment: null });
    } catch (err) {
      toast.error(err);
    }
  };

  handleEquipmentEdit = object => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      equipment: object,
    }));
  };

  handleEquipmentDelete = async () => {
    const { equipment } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do veículo?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        const response = await API.delete(`equipments/${equipment.uuid}`);

        this.setState({ equipment: null });

        this.getEquipmentsList(response.data);

        toast.success('Veículo deletado com sucesso.');
      }
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { equipmentForm } = this.props;
    const { equipments, equipment } = this.state;

    return (
      <div>
        {equipmentForm ? (
          <>
            <div ref={this.refEquipment} />
            <EquipmentForm
              equipments={equipments}
              equipment={equipment}
              onEquipmentRefresh={this.handleEquipmentRefresh}
              onEquipmentCreation={this.handleEquipmentCreation}
              onEquipmentEdit={this.handleEquipmentEdit}
              onEquipmentUpdate={this.handleEquipmentUpdate}
              onEquipmentDelete={this.handleEquipmentDelete}
            />
          </>
        ) : null}
      </div>
    );
  }
}

EquipmentContainer.propTypes = {
  onEquipmentsList: PropTypes.func.isRequired,
  onEquipmentCreation: PropTypes.func.isRequired,
  equipmentForm: PropTypes.bool.isRequired,
};

export default EquipmentContainer;
