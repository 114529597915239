import styled from 'styled-components';

const columnsSize = '0.5fr 1.2fr 1.2fr 0.5fr 0.5fr 0.8fr 0.8fr 1.2fr';

export const TicketListStyles = styled.div`
  p {
    font-size: 14px;

    line-height: 20px;
  }

  .btn.excluir {
    background-color: #ff5757;

    &:hover {
      background: transparent;

      border: solid 1px #1c2826;

      cursor: pointer;
    }
  }

  .header {
    padding: 10px 20px;

    display: grid;

    align-items: center;

    grid-template-columns: ${columnsSize};

    grid-row-gap: 10px;

    grid-column-gap: 10px;

    @media (max-width: 620px) {
      grid-template-columns: 1fr;
    }
  }

  .item {
    margin-right: 5%;

    margin-left: 5%;

    background: #c1cad6;

    border-radius: 5px;

    padding: 10px 20px;

    margin-bottom: 4px;

    font-size: 14px;

    display: grid;

    align-items: center;

    grid-template-columns: ${columnsSize};

    grid-row-gap: 10px;

    grid-column-gap: 10px;

    @media (max-width: 620px) {
      grid-template-columns: 1fr;
    }

    &:hover {
      background: transparent;

      border: solid 1px #1c2826;

      cursor: pointer;
    }

    .date {
      &.altered {
        color: red;
      }

      &.current {
        color: #666;
      }
    }
  }
`;
