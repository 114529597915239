import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import SelectStyled from '../../styles/select';

import ReactSelect from 'react-select';

import Moment from 'moment';

import { differenceInDays } from 'date-fns';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '30%',

    textAlign: 'center',
  },
});

const maintUuid = [
  '757b3456-08c1-45fc-9b66-75df65251611',
  'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a',
  'b6a91ff3-327d-4a37-8cc4-4391bdab5e03',
  'dd2bd744-b06e-4bf3-8398-839e82bc86e1',
];

const handleValidate = props => ({
  opening_date,

  type,

  time,

  equipment,

  km,

  qty,

  value,

  arla,
}) => {
  const errors = {};

  if (!opening_date) errors.opening_date = 'OBRIGATÓRIO';

  if (!time) errors.time = 'OBRIGATÓRIO';

  if (!type) errors.type = 'OBRIGATÓRIO';

  if (!qty) errors.qty = 'OBRIGATÓRIO';

  if (!value) errors.value = 'OBRIGATÓRIO';

  if (!equipment) errors.equipment = 'OBRIGATÓRIO';

  if (!props.maintenance && props.tankArla.tank - arla < 0) {
    errors.arla = 'Saldo Insuficiente - Tanque Arla';
  }

  if (!props.maintenance && props.tank.tank - qty < 0) {
    errors.qty = 'Saldo Insuficiente - Tanque';
  }

  const selectedEquipment = props.equipments.filter(
    object => object.uuid === equipment
  );

  if (
    !props.maintenance &&
    km - selectedEquipment[0].odometers[0].km >= 19500
  ) {
    errors.km = 'NECESSITA MANUTENÇÃO';
  }

  if (
    selectedEquipment &&
    type !== 'EXTERNO' &&
    selectedEquipment.type === 'CAVALO'
  ) {
    if (!km) errors.km = 'OBRIGATÓRIO';

    if (
      !props.maintenance &&
      selectedEquipment.odometers[0] &&
      selectedEquipment.odometers[0].km > km
    ) {
      errors.km = 'KM menor que o anterior!';
    }
  }

  if (
    !props.calibration[0].createdAt
  ) {
    errors.createdAt = 'NECESSITA DE CALIBRAGEM'
  }

  const currentDay = new Date();
  const lastCalibration = selectedEquipment[0].calibrations[0] ? new Date(selectedEquipment[0].calibrations[0].createdAt) : new Date();
  const difference = differenceInDays(currentDay, lastCalibration);

  if
    (!selectedEquipment[0].calibrations[0] ||
    (difference > 30))
   {
    errors.km = 'NECESSITA CALIBRAGEM'
  }
  
  console.log('props.useruuid', props);
  if (!maintUuid.includes(props.useruuid)) {
    return errors;
  } else return {};
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.maintenance) {
    console.log('Entrou if', props);
    await props.onMaintenanceCreation({ ...values });
  } else {
    await props.onMaintenanceUpdate({ ...values });
  }

  setSubmitting(false);
};

const MaintenanceForm = props => {
  const {
    classes,

    equipments,

    onMaintenanceDelete,

    useruuid,

    tank,
  } = props;

  const hora_atual = Moment().format('HH:mm');

  const onMaintenanceNew = () => props.onMaintenanceNew();

  const { maintenance } = props;

  const types = [
    { id: 1, name: 'INTERNO' },
    { id: 1, name: 'EXTERNO' },
  ];

  return (
    <div>
      <Paper className={classes.root}>
        {props.maintenance ? (
          <h1>Abastecimento Cadastrado</h1>
        ) : (
          <h1>Novo Abastecimento</h1>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            opening_date: !props.maintenance
              ? new Date().toISOString().substring(0, 10)
              : props.maintenance.date.substring(0, 10),

            time: !props.maintenance
              ? hora_atual
              : props.maintenance.date.substring(11, 16),

            type: !props.maintenance ? '' : props.maintenance.type,

            equipment:
              props.maintenance && props.maintenance.equipment
                ? props.maintenance.equipment.uuid
                : props.equipment
                ? props.equipment.uuid
                : '',

            km: props.maintenance ? props.maintenance.km : '',

            qty: props.maintenance ? props.maintenance.qty : '',

            value: props.maintenance
              ? props.maintenance.value
              : tank
              ? tank.tank_refuels[0].value
              : '',

            bead_number: props.maintenance
              ? props.maintenance.bead_number
                ? props.maintenance.bead_number
                : ''
              : '',

            arla: props.maintenance
              ? props.maintenance.arla
                ? props.maintenance.arla
                : ''
              : '',

            // timer01: props.maintenance ? props.maintenance.timer01 : '',

            // timer02: props.maintenance && props.maintenance.timer02 ? props.maintenance.timer02 : '',
          }}
          validate={handleValidate(props)}
          onSubmit={handleSubmit(props)}
        >
          {props => (
            <Form>
              <div>
                <TextField
                  id="opening_date"
                  name="opening_date"
                  label="Data"
                  type="date"
                  variant="outlined"
                  value={props.values.opening_date}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={
                    !!(props.errors.opening_date && props.touched.opening_date)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={props.values.type !== 'EXTERNO'}
                />

                <TextField
                  id="time"
                  name="time"
                  variant="outlined"
                  value={props.values.time}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Hora"
                  type="time"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 30 min
                  }}
                  error={!!(props.errors.time && props.touched.time)}
                  disabled={props.values.type !== 'EXTERNO'}
                />
              </div>

              <div>
                <ReactSelect
                  styles={SelectStyled}
                  id="type"
                  name="type"
                  value={props.values.type.value}
                  error={props.errors.type}
                  placeholder="TIPO"
                  onChange={selectedOption => {
                    props.setFieldValue('type', selectedOption.name);
                  }}
                  isSearchable
                  options={types}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando tipos'}
                  noOptionsMessage={() => 'Não há tipos cadastrados'}
                />
              </div>

              <div>
                <ReactSelect
                  styles={SelectStyled}
                  id="equipment"
                  name="equipment"
                  value={props.values.equipment.uuid}
                  error={props.errors.equipment}
                  placeholder="VEICULO ABASTECIDO"
                  onChange={selectedOption => {
                    props.setFieldValue('equipment', selectedOption.uuid);
                  }}
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => `${item.code} | ${item.type}`}
                  getOptionValue={item => item.code}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando veiculos'}
                  noOptionsMessage={() => 'Não há veiculos cadastrados'}
                />
              </div>

              <div
                className={classes.formControl}
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  'justify-content': 'center',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    id="km"
                    label="Km"
                    variant="outlined"
                    value={props.values.km}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    type="number"
                    style={{ minWidth: '300px' }}
                    className={classes.textFieldNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    error={!!(props.errors.km && props.touched.km)}
                  />

                  {props.errors.km && props.touched.km && (
                    <div style={{ color: 'red' }}>{props.errors.km}</div>
                  )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    id="qty"
                    label="Litros"
                    variant="outlined"
                    value={props.values.qty}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    type="number"
                    style={{ minWidth: '300px' }}
                    className={classes.textFieldNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    error={!!(props.errors.qty && props.touched.qty)}
                    disabled={
                      !maintenance
                        ? false
                        : !(
                            useruuid ===
                              '757b3456-08c1-45fc-9b66-75df65251611' ||
                            useruuid ===
                              'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                            useruuid ===
                              'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                            useruuid === '7eff000c-91d9-4c54-b597-79bd93938325'
                          )
                    }
                  />

                  {props.errors.qty && props.touched.qty && (
                    <div style={{ color: 'red' }}>{props.errors.qty}</div>
                  )}
                </div>
              </div>

              <div
                className={classes.formControl}
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <TextField
                  id="value"
                  label="Valor"
                  variant="outlined"
                  value={props.values.value}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={!!(props.errors.value && props.touched.value)}
                  disabled={props.values.type !== 'EXTERNO'}
                />

                {props.errors.value && props.touched.value && (
                  <div style={{ color: 'red' }}>{props.errors.value}</div>
                )}

                <TextField
                  id="bead_number"
                  label="Numero do talão"
                  variant="outlined"
                  value={props.values.bead_number}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={
                    !!(props.errors.bead_number && props.touched.bead_number)
                  }
                />

                {props.errors.bead_number && props.touched.bead_number && (
                  <div style={{ color: 'red' }}>{props.errors.bead_number}</div>
                )}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    id="arla"
                    label="Arla"
                    variant="outlined"
                    value={props.values.arla}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    type="number"
                    style={{ minWidth: '300px' }}
                    className={classes.textFieldNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    error={!!(props.errors.arla && props.touched.arla)}
                    disabled={maintenance}
                  />

                  {props.errors.arla && props.touched.arla && (
                    <div style={{ color: 'red' }}>{props.errors.arla}</div>
                  )}
                </div>
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  (!props.dirty ||
                    props.isSubmitting ||
                    Object.keys(props.errors).length !== 0) &&
                  !maintUuid.includes(useruuid)
                }
                style={{ margin: '10px' }}
              >
                SALVAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>

              {maintenance ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onMaintenanceNew}
                    style={{ margin: '10px' }}
                  >
                    LIMPAR
                  </Button>

                  {useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                  useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325' ||
                  useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ? (
                    <button
                      type="button"
                      onClick={e => {
                        onMaintenanceDelete(maintenance);
                      }}
                      className="btn excluir"
                      style={{ margin: '10px' }}
                    >
                      Excluir
                    </button>
                  ) : null}
                </Fragment>
              ) : null}
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

MaintenanceForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaintenanceForm);
