import React from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '40%',

    textAlign: 'center',
  },

  rootSelect: {
    display: 'flex',

    flexWrap: 'wrap',

    width: '90%',
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const handleValidate = () => {
  const errors = {};

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onInventoryCreation({ ...values });

  setSubmitting(false);
  resetForm();
};

const PieceIventoryForm = props => {
  const { classes, movements } = props;

  return (
    <div>
      <Paper className={classes.root}>
        <h1>FINALIZAÇÃO</h1>

        <Formik
          enableReinitialize
          initialValues={{
            document: '',
          }}
          validate={handleValidate(props)}
          onSubmit={handleSubmit(props)}
        >
          {props => (
            <Form>
              <div>
                <TextField
                  id="document"
                  name="document"
                  label="Documento"
                  type="text"
                  variant="outlined"
                  value={props.values.document}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={!!(props.errors.document && props.touched.document)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {props.errors.document && props.touched.document && (
                  <div style={{ color: 'red' }}>{props.errors.document}</div>
                )}
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  !props.dirty ||
                  props.isSubmitting ||
                  !(movements && movements[0])
                }
                style={{ margin: '10px' }}
              >
                FINALIZAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

PieceIventoryForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PieceIventoryForm);
