import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from '../components/ReactSelect';
import imgloading from '../../img/load.gif';

import { FormStyled } from './styles';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ description }) => {
  const errors = {};

  if (!description) errors.name = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.exam) {
    await props.onExamCreation({ ...values });
  } else {
    await props.onExamUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const ExamForm = props => {
  const { exams, exam, onExamEdit, onExamDelete, onExamRefresh } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          description: exam ? exam.description : '',
          expiration: exam ? exam.expiration : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleReset,
          isSubmitting,
        }) => (
          <FormStyled>
            <h1>{exam ? 'EDITAR EXAME' : 'NOVO EXAME'}</h1>

            <div className="formData">
              <strong>DESCRIÇÃO</strong>

              <input
                name="description"
                type="text"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.description && touched.description)}
              />

              {errors.description && touched.description && (
                <span>{errors.description}</span>
              )}
            </div>

            <div className="formData">
              <strong>EXPIRAÇÃO(DIAS)</strong>

              <input
                name="expiration"
                type="number"
                value={values.expiration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.expiration && touched.expiration)}
              />

              {errors.expiration && touched.expiration && (
                <span>{errors.expiration}</span>
              )}
            </div>

            <button variant="contained" type="submit" disabled={isSubmitting}>
              <Loadingbtn className="loadingbtn" />
              {exam ? 'ALTERAR' : 'CADASTRAR'}
            </button>

            <button
              type="button"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              CANCELAR
            </button>

            {exam && (
              <button type="button" onClick={onExamRefresh}>
                LIMPAR
              </button>
            )}

            {exam && (
              <button type="button" onClick={onExamDelete} className="delete">
                EXCLUIR
              </button>
            )}

            <div className="division">
              <hr />
              <p>OU</p>
              <hr />
            </div>

            <ReactSelect
              placeholder="Escolher exame existente"
              options={exams}
              getOptionLabel={option => option.description}
              onSetValue={onExamEdit}
            />
          </FormStyled>
        )}
      </Formik>
    </div>
  );
};

ExamForm.propTypes = {
  exams: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  exam: PropTypes.shape({
    description: PropTypes.string,
    expiration: PropTypes.number,
  }),
  onExamEdit: PropTypes.func.isRequired,
  onExamDelete: PropTypes.func.isRequired,
  onExamRefresh: PropTypes.func.isRequired,
};

ExamForm.defaultProps = {
  exams: [],
  exam: null,
};

export default ExamForm;
