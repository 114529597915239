import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import ReactSelect from '../components/ReactSelect';

import { FormStyled } from './styles';

const handleValidate = () => {
  const errors = {};

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList(values);

  setSubmitting(false);
};

const fechamentoFilterForm = props => {
  const { employees } = props;

  return (
    <Formik
      onSubmit={handleSubmit(props)}
      validate={handleValidate}
      initialValues={{
        employee: '',
      }}
    >
      {({
        values,

        handleBlur,

        handleReset,

        errors,

        touched,

        isSubmitting,

        setFieldValue,

        dirty,
      }) => (
        <FormStyled>
          <h1>FILTRO</h1>

          <div className="formData">
            <strong>FUNCIONÁRIO</strong>

            <ReactSelect
              id="employee"
              name="employee"
              placeholder="Escolher funcionário"
              options={employees}
              noOptionsMessage={()=> 'Não há itens na lista !'}
              value={values.employee}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.uuid}
              onChange={e => setFieldValue('employee', e)}
              onBlur={handleBlur}
            />

            {errors.employee && touched.employee && (
              <span>{errors.employee}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            PESQUISAR
          </button>

          <button
            type="button"
            disabled={isSubmitting || !dirty}
            onClick={handleReset}
          >
            LIMPAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

export default fechamentoFilterForm;

fechamentoFilterForm.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
};

fechamentoFilterForm.defaultProps = { employees: [] };
