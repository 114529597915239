const ReactSelectStyle = {
  control: base => ({
    ...base,

    height: 56,

    width: '100%',

    minHeight: 40,

    borderRadius: 0,

    border: '1px solid #ecece',

    margin: 10,

    marginBottom: '20px',

    fontSize: 16,

    color: '#333',

    padding: 'auto 15px',
  }),

  container: provided => ({
    ...provided,

    width: '90%',

  }),

  valueContainer: provided => ({
    ...provided,
  }),

  input: provided => ({
    ...provided,
  }),

  placeholder: provided => ({
    ...provided,

    color: '#999',
  }),

  option: (provided, state) => ({
    ...provided,

    background: '#fff',

    color: state.isSelected ? '#333' : '#999',

    fontSize: 14,

    padding: 10,
  }),
};

export default ReactSelectStyle;
