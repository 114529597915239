import React, { useState } from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';

import ReactSelect from 'react-select';

import Checkbox from '@material-ui/core/Checkbox';
import SelectStyled from '../../styles/select';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '30%',

    textAlign: 'center',
  },
});

const handleValidate = ({ date, date2, group }) => {
  const errors = {};

  if (!date) errors.date = 'OBRIGATÓRIO';

  if (!date2) errors.date2 = 'OBRIGATÓRIO';

  console.log(group);

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onRefuelsList({ ...values });

  setSubmitting(false);
};

const MaintenanceForm = props => {
  const { classes, equipments } = props;
  const [selectedType, setSelectedType] = useState(null);
  const [selectedEquip, setSelectedEquip] = useState(null);

  const equiOptions = equipments
    ? equipments.map(equip => ({
        value: equip.uuid,
        label: equip.code,
      }))
    : [];

  const typeOptions = [
    {
      value: 'INTERNO',
      label: 'INTERNO',
    },
    {
      value: 'EXTERNO',
      label: 'EXTERNO',
    },
  ];

  return (
    <div>
      <h1>Filtros</h1>

      <Formik
        enableReinitialize
        initialValues={{
          date: new Date().toISOString().substring(0, 10),

          date2: new Date().toISOString().substring(0, 10),

          group: false,

          type: null,
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {props => (
          <Form>
            {/* date */}

            <div className="item">
              <TextField
                id="date"
                name="date"
                label="Data Inicial"
                type="date"
                variant="outlined"
                value={props.values.date}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                className={classes.textField}
                error={!!(props.errors.date && props.touched.date)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '20%' }}
              />

              <TextField
                id="date2"
                name="date2"
                label="Data Final"
                type="date"
                variant="outlined"
                value={props.values.date2}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                className={classes.textField}
                error={!!(props.errors.date2 && props.touched.date2)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '20%' }}
              />
            </div>

            {/* type */}

            <div>
              <ReactSelect
                id="type"
                name="type"
                placeholder="TIPO"
                styles={SelectStyled}
                value={selectedType}
                onChange={e => {
                  setSelectedType(e);
                  props.setFieldValue('type', e.value);
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={typeOptions}
                noOptionsMessage="Listagem de tipos vazia !"
              />

              <div>
                <ReactSelect
                  id="equipmentUuid"
                  name="equipmentUuid"
                  placeholder="EQUIPAMENTO"
                  isDisabled={!equipments}
                  styles={SelectStyled}
                  value={selectedEquip}
                  onChange={e => {
                    setSelectedEquip(e);
                    props.setFieldValue('equipmentUuid', e.value);
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={equiOptions}
                  noOptionsMessage="Listagem de equipamentos vazia !"
                />
              </div>

              <div>
                <Checkbox
                  id="group"
                  name="group"
                  value={props.values.group}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  inputProps={{
                    name: 'group',

                    id: 'group',
                  }}
                />

                <InputLabel htmlFor="group">AGRUPAR POR VEICULO</InputLabel>
              </div>
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={props.isSubmitting}
              style={{ marginTop: '20px' }}
            >
              BUSCAR
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

MaintenanceForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaintenanceForm);
