import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

import SelectMUI from '@material-ui/core/Select';

import Checkbox from '@material-ui/core/Checkbox';

import Moment from 'moment';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '30%',

    textAlign: 'center',
  },

  rootSelect: {
    display: 'flex',

    flexWrap: 'wrap',

    width: '90%',
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const handleValidate = props => ({
  opening_description,

  opening_date,

  type,

  observation,

  time,

  equipment,

  situation,

  km,

  timer01,

  timer02,
}) => {
  const errors = {};

  if (!opening_description) errors.opening_description = 'OBRIGATÓRIO';

  if (!opening_date) errors.opening_date = 'OBRIGATÓRIO';

  if (!time) errors.time = 'OBRIGATÓRIO';

  if (!type) errors.type = 'OBRIGATÓRIO';

  if (!equipment) errors.equipment = 'OBRIGATÓRIO';

  const selectedEquipment = props.equipments.filter(
    object => object.uuid === equipment
  );

  if (selectedEquipment && selectedEquipment.type === 'CAVALO') {
    if (!km) errors.km = 'OBRIGATÓRIO';

    if (
      !props.maintenance &&
      selectedEquipment.odometers[0] &&
      selectedEquipment.odometers[0].km > km
    ) {
      errors.km = 'KM menor que o anterior!';
    }
  }

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.maintenance) {
    await props.onMaintenanceCreation({ ...values });
  } else {
    await props.onMaintenanceUpdate({ ...values });
  }

  setSubmitting(false);
};

const MaintenanceForm = props => {
  const { classes, equipments, onMaintenanceDelete, useruuid } = props;

  const hora_atual = Moment().format('HH:mm');

  const onMaintenanceNew = () => props.onMaintenanceNew();

  const { maintenance } = props;

  console.log(props, 'props')
  console.log(maintenance, 'maintenance')

  return (
    <div>
      <Paper className={classes.root}>
        {props.maintenance ? (
          <Fragment>
            <h1>Nº {props.maintenance.number}</h1>
          </Fragment>
        ) : (
          <h1>Nova</h1>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            opening_description: !props.maintenance
              ? ''
              : props.maintenance.opening_description,

            opening_date: !props.maintenance
              ? new Date().toISOString().substring(0, 10)
              : props.maintenance.opening_date.substring(0, 10),

            time: !props.maintenance
              ? hora_atual
              : props.maintenance.opening_date.substring(11, 16),

            type: !props.maintenance ? '' : props.maintenance.type,

            observation: !props.maintenance
              ? ''
              : props.maintenance.observation,

            equipment:
              props.maintenance && props.maintenance.equipment
                ? props.maintenance.equipment.uuid
                : props.equipment
                ? props.equipment.uuid
                : '',

            situation: props.maintenance
              ? props.maintenance.situation
              : 'INICIADA',

            km: props.maintenance ? props.maintenance.km : '',

            oil_change: props.maintenance
              ? props.maintenance.oil_change
                ? props.maintenance.oil_change
                : false
              : false,

            // timer01: props.maintenance ? props.maintenance.timer01 : '',

            // timer02: props.maintenance && props.maintenance.timer02 ? props.maintenance.timer02 : '',
          }}
          validate={handleValidate(props)}
          onSubmit={handleSubmit(props)}
        >
          {props => (
            <Form>
              <div>
                <TextField
                  id="opening_date"
                  name="opening_date"
                  label="Data"
                  type="date"
                  variant="outlined"
                  disabled
                  value={props.values.opening_date}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={
                    !!(props.errors.opening_date && props.touched.opening_date)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="time"
                  name="time"
                  variant="outlined"
                  disabled
                  value={props.values.time}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Hora"
                  type="time"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 30 min
                  }}
                  error={!!(props.errors.time && props.touched.time)}
                />
              </div>

              <div
                className={classes.formControl}
                style={{ marginBottom: '20px' }}
              >
                <TextField
                  id="km"
                  label="Km"
                  variant="outlined"
                  value={props.values.km}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={!!(props.errors.km && props.touched.km)}
                  disabled={
                    !maintenance
                      ? false
                      : !(
                          useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                          useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                          useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                          useruuid === '7eff000c-91d9-4c54-b597-79bd93938325'
                        )
                  }
                />

                {props.errors.km && props.touched.km && (
                  <div style={{ color: 'red' }}>{props.errors.km}</div>
                )}
              </div>

              <div className={classes.formControl}>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="equipment"
                >
                  VEÍCULO EM MANUTENÇÃO
                </InputLabel>

                <SelectMUI
                  id="equipment"
                  name="equipment"
                  value={props.values.equipment}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  inputProps={{
                    name: 'equipment',

                    id: 'equipment',
                  }}
                  style={{
                    width: '90%',

                    margin: '10px',

                    marginBottom: '20px',
                  }}
                  error={!!(props.errors.equipment && props.touched.equipment)}
                >
                  {equipments &&
                    equipments.length > 0 &&
                    equipments.map(equipment => (
                      <MenuItem value={equipment.uuid}>
                        {`${equipment.code} | ${equipment.type}`}
                      </MenuItem>
                    ))}
                </SelectMUI>
              </div>

              <div className={classes.formControl}>
                <div>
                  <InputLabel
                    style={{ disableAnimation: false }}
                    disableAnimation={false}
                    htmlFor="TYPE"
                  >
                    TIPO
                  </InputLabel>
                </div>

                <SelectMUI
                  id="type"
                  name="type"
                  value={props.values.type}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  inputProps={{
                    name: 'type',

                    id: 'type-simple',
                  }}
                  style={{
                    width: '90%',

                    margin: '10px',

                    marginBottom: '20px',
                  }}
                  error={!!(props.errors.type && props.touched.type)}
                >
                  <MenuItem value="PREVENTIVA">PREVENTIVA</MenuItem>

                  <MenuItem value="CORRETIVA">CORRETIVA</MenuItem>

                  <MenuItem value="PREVENTIVA/CORRETIVA">
                    PREVENTIVA/CORRETIVA
                  </MenuItem>

                  <MenuItem value="MATERIAL">MATERIAL</MenuItem>
                </SelectMUI>
              </div>

              <div className={classes.formControl}>
                <div>
                  <InputLabel
                    style={{ disableAnimation: false }}
                    disableAnimation={false}
                    htmlFor="TYPE"
                  >
                    TROCOU O ÓLEO
                  </InputLabel>
                </div>

                <Checkbox
                  id="oil_change"
                  name="oil_change"
                  value={props.values.oil_change}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  checked={props.values.oil_change}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </div>

              <div>
                <TextField
                  label="Descrição de Abertura"
                  placeholder="Digite a descrição de abertura..."
                  multiline
                  margin="normal"
                  id="opening_description"
                  name="opening_description"
                  type="opening_description"
                  variant="outlined"
                  value={props.values.opening_description}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  style={{
                    width: '90%',

                    textAlign: 'left',

                    marginBottom: '20px',
                  }}
                  error={
                    !!(
                      props.errors.opening_description &&
                      props.touched.opening_description
                    )
                  }
                />
              </div>

              <div>
                <TextField
                  label="Observação"
                  placeholder="Digite a observação..."
                  multiline
                  margin="normal"
                  id="observation"
                  name="observation"
                  type="observation"
                  variant="outlined"
                  value={props.values.observation}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  style={{
                    width: '90%',

                    textAlign: 'left',

                    marginBottom: '20px',
                  }}
                  error={
                    !!(props.errors.observation && props.touched.observation)
                  }
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                SALVAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>

              {maintenance ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onMaintenanceNew}
                    style={{ margin: '10px' }}
                  >
                    LIMPAR
                  </Button>

                  {useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                  useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                  useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                  useruuid === '7eff000c-91d9-4c54-b597-79bd93938325' ? (
                    <button
                      type="button"
                      onClick={e => {
                        onMaintenanceDelete(maintenance);
                      }}
                      className="btn excluir"
                      style={{ margin: '10px' }}
                    >
                      Excluir
                    </button>
                  ) : null}
                </Fragment>
              ) : null}
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

MaintenanceForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaintenanceForm);
