import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';

import ReactSelect from 'react-select';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';

import SelectStyled from '../../styles/select';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '40%',

    textAlign: 'center',
  },

  rootSelect: {
    display: 'flex',

    flexWrap: 'wrap',

    width: '90%',
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const handleValidate = props => ({
  opening_date,

  value,

  material,

  partner,

  employee,

  qty,

  origin,

  destiny,

  observation,

  volume,

  type,
}) => {
  const errors = {};

  if (!opening_date) errors.opening_date = 'OBRIGATÓRIO';

  if (!value) errors.value = 'OBRIGATÓRIO';

  if (!volume) errors.volume = 'OBRIGATÓRIO';

  if (!partner) errors.partner = 'OBRIGATÓRIO';

  if (!material) errors.material = 'OBRIGATÓRIO';

  if (!origin) errors.origin = 'OBRIGATÓRIO';

  if (!destiny) errors.destiny = 'OBRIGATÓRIO';

  if (!qty && !props.maintenance) errors.qty = 'OBRIGATÓRIO';

  if (!type) errors.type = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.maintenance) {
    await props.onMaintenanceCreation({ ...values });
  } else {
    await props.onMaintenanceUpdate({ ...values });
  }

  setSubmitting(false);
};

const MaintenanceForm = props => {
  const {
    classes,

    employees,

    partners,

    locals,

    materials,

    partner,

    material,

    onMaintenanceDelete,

    onMaintenanceNew,
  } = props;

  const { maintenance } = props;

  const types = [
    { id: 1, name: 'REGIONAL' },
    { id: 1, name: 'EXTERNO' },
  ];

  const typeValue = types.find(
    type => type.name === (props.maintenance ? props.maintenance.type : '')
  );

  return (
    <div>
      <Paper className={classes.root}>
        {props.maintenance ? (
          <h1>ALTERAR PROGRAMAÇÃO</h1>
        ) : (
          <h1>NOVA PROGRAMAÇÃO</h1>
        )}

        <Formik
          enableReinitialize
          initialValues={{
            opening_date: !props.maintenance
              ? new Date().toISOString().substring(0, 10)
              : props.maintenance.date.substring(0, 10),

            partner:
              props.maintenance && props.maintenance.partner
                ? props.maintenance.partner
                : partner || '',

            material:
              props.maintenance && props.maintenance.material
                ? props.maintenance.material
                : material || '',

            employee:
              props.maintenance && props.maintenance.employee
                ? props.maintenance.employee
                : '',

            origin:
              props.maintenance && props.maintenance
                ? props.maintenance.origin
                : '',

            destiny:
              props.maintenance && props.maintenance.destiny
                ? props.maintenance.destiny
                : '',

            qty: 1,

            value: props.maintenance ? props.maintenance.value : '',

            volume: props.maintenance ? props.maintenance.volume : '',

            observation: props.maintenance ? props.maintenance.observation : '',

            type: typeValue || '',
          }}
          validate={handleValidate(props)}
          onSubmit={handleSubmit(props)}
        >
          {props => (
            <Form>
              <div style={{ marginBottom: '20px' }}>
                <TextField
                  id="opening_date"
                  name="opening_date"
                  label="Data de Carregamento"
                  type="date"
                  variant="outlined"
                  value={props.values.opening_date}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={
                    !!(props.errors.opening_date && props.touched.opening_date)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="value"
                  label="Peso por Viagem"
                  variant="outlined"
                  value={props.values.value}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={!!(props.errors.value && props.touched.value)}
                />

                {props.errors.value && props.touched.value && (
                  <div style={{ color: 'red' }}>{props.errors.value}</div>
                )}

                <TextField
                  id="volume"
                  label="Volume por Viagem"
                  variant="outlined"
                  value={props.values.volume}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="number"
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={!!(props.errors.volume && props.touched.volume)}
                />

                {props.errors.volume && props.touched.volume && (
                  <div style={{ color: 'red' }}>{props.errors.volume}</div>
                )}
              </div>

              {!maintenance && (
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    id="qty"
                    label="Quantidade de Viagens"
                    variant="outlined"
                    value={props.values.qty}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    type="number"
                    className={classes.textFieldNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    error={!!(props.errors.qty && props.touched.qty)}
                  />

                  {props.errors.qty && props.touched.qty && (
                    <div style={{ color: 'red' }}>{props.errors.qty}</div>
                  )}
                </div>
              )}

              <div>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="TYPE"
                >
                  TIPO
                </InputLabel>

                <ReactSelect
                  styles={SelectStyled}
                  id="type"
                  name="type"
                  value={props.values.type}
                  error={props.errors.type}
                  placeholder="Selecione sistema..."
                  onChange={selectedOption => {
                    props.setFieldValue('type', selectedOption);
                  }}
                  isSearchable
                  options={types}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando tipos'}
                  noOptionsMessage={() => 'Não há tipos cadastrados'}
                />
              </div>

              <div className={classes.formControl}>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="partner"
                >
                  CLIENTE SOLICITANTE
                </InputLabel>

                <ReactSelect
                  styles={SelectStyled}
                  id="partner"
                  name="partner"
                  placeholder="Selecione o solicitante..."
                  error={props.errors.partner}
                  value={props.values.partner}
                  onChange={selectedOption => {
                    props.setFieldValue('partner', selectedOption);
                  }}
                  isSearchable
                  options={partners}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando cliente'}
                  noOptionsMessage={() => 'Não há clientes cadastrados'}
                />
              </div>

              <div className={classes.formControl}>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="material"
                >
                  MATERIAL TRANSPORTADO
                </InputLabel>

                <ReactSelect
                  styles={SelectStyled}
                  id="material"
                  name="material"
                  placeholder="Selecione o tipo de material..."
                  error={props.errors.material}
                  value={props.values.material}
                  onChange={selectedOption => {
                    props.setFieldValue('material', selectedOption);
                  }}
                  isSearchable
                  options={materials}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando materiais'}
                  noOptionsMessage={() => 'Não há materiais cadastrados'}
                />
              </div>

              <div className={classes.formControl}>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="origin"
                >
                  ORIGEM DO TRANSPORTE
                </InputLabel>

                <ReactSelect
                  styles={SelectStyled}
                  id="origin"
                  name="origin"
                  placeholder="Selecione o local de origem..."
                  error={props.errors.origin}
                  value={props.values.origin}
                  onChange={selectedOption => {
                    props.setFieldValue('origin', selectedOption);
                  }}
                  isSearchable
                  options={locals}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando origens'}
                  noOptionsMessage={() => 'Não há origens cadastrados'}
                />
              </div>

              <div className={classes.formControl}>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="destiny"
                >
                  DESTINO DO TRANSPORTE
                </InputLabel>

                <ReactSelect
                  styles={SelectStyled}
                  id="destiny"
                  name="destiny"
                  placeholder="Selecione o local de destination..."
                  error={props.errors.destiny}
                  value={props.values.destiny}
                  onChange={selectedOption => {
                    props.setFieldValue('destiny', selectedOption);
                  }}
                  isSearchable
                  options={locals}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando destinos'}
                  noOptionsMessage={() => 'Não há destinos cadastrados'}
                />
              </div>

              {maintenance && (
                <div className={classes.formControl}>
                  <InputLabel
                    style={{ disableAnimation: false }}
                    disableAnimation={false}
                    htmlFor="employee"
                  >
                    MOTORISTA DA VIAGEM
                  </InputLabel>

                  <ReactSelect
                    styles={SelectStyled}
                    id="employee"
                    name="employee"
                    placeholder="Selecione o funcionário..."
                    error={props.errors.employee}
                    value={props.values.employee}
                    onChange={selectedOption => {
                      props.setFieldValue('employee', selectedOption);
                    }}
                    isSearchable
                    options={[{ name: '', uuid: null }, ...employees]}
                    getOptionLabel={item => `${item.name}${item.bond ? ` - ${item.bond}` : ''}`}
                    getOptionValue={item => item.uuid}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isLoading={false}
                    loadingMessage={() => 'Buscando funcionários'}
                    noOptionsMessage={() => 'Não há funcionários cadastrados'}
                  />
                </div>
              )}

              <div>
                <TextField
                  label="Observação"
                  placeholder="Digite a observação..."
                  multiline
                  margin="normal"
                  id="observation"
                  name="observation"
                  type="observation"
                  variant="outlined"
                  value={props.values.observation}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  style={{
                    width: '90%',

                    textAlign: 'left',

                    marginBottom: '20px',
                  }}
                  error={
                    !!(props.errors.observation && props.touched.observation)
                  }
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                SALVAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>

              {maintenance ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onMaintenanceNew}
                    style={{ margin: '10px' }}
                  >
                    NOVA
                  </Button>

                  <button
                    type="button"
                    onClick={e => {
                      onMaintenanceDelete(maintenance);
                    }}
                    className="btn excluir"
                    style={{ margin: '10px' }}
                  >
                    Excluir
                  </button>
                </Fragment>
              ) : null}
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

MaintenanceForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaintenanceForm);
