import React from 'react';

import { Formik } from 'formik';

import { FormStyled } from './styles';

const handleValidate = () => {
  const errors = {};

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList(values);

  setSubmitting(false);
};

const fechamentoFilterForm = props => {
  return (
    <Formik
      onSubmit={handleSubmit(props)}
      validate={handleValidate}
      initialValues={{
        name: '',
        cpf: '',
        cnh: '',
        type: '',
      }}
    >
      {({
        values,

        handleChange,

        handleBlur,

        handleReset,

        errors,

        touched,

        isSubmitting,

        dirty,
      }) => (
        <FormStyled>
          <h1>FILTRO</h1>

          <div className="formData">
            <strong>NOME</strong>

            <input
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.name && touched.name)}
            />

            {errors.name && touched.name && <span>{errors.name}</span>}
          </div>

          <div className="formData">
            <strong>CPF</strong>

            <input
              id="cpf"
              name="cpf"
              value={values.cpf}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.cpf && touched.cpf)}
            />

            {errors.cpf && touched.cpf && <span>{errors.cpf}</span>}
          </div>

          <div className="formData">
            <strong>CNH</strong>

            <input
              id="cnh"
              name="cnh"
              value={values.cnh}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.cnh && touched.cnh)}
            />

            {errors.cnh && touched.cnh && <span>{errors.cnh}</span>}
          </div>

          <div className="formData">
            <strong>TIPO</strong>

            <select
              id="type"
              name="type"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" />

              <option value="AEB">AEB</option>

              <option value="NSA">NSA</option>
            </select>

            {errors.type && touched.type && <span>{errors.type}</span>}
          </div>

          <button type="submit" disabled={isSubmitting}>
            PESQUISAR
          </button>

          <button
            type="button"
            disabled={isSubmitting || !dirty}
            onClick={handleReset}
          >
            LIMPAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

export default fechamentoFilterForm;

fechamentoFilterForm.propTypes = {};

fechamentoFilterForm.defaultProps = {};
