import React from 'react';

import { MaintenancePieceStyles } from './MaintenancePieceStyles';

import Loading from '../../components/Loading';

const maintenancePieceListItem = props =>
  props.maintenancePieces.map(maintenancePiece => (
    <div
      className="item"
      key={maintenancePiece.uuid}

      // onClick={(e) => {

      //  props.onMaintenanceEdit(maintenance);

      // }}
    >
      {/* <p className="po">{usuario.nome}</p> */}

      <p className="code">{maintenancePiece.piece.description}</p>

      <p className="quantity">{maintenancePiece.quantity}</p>

      <p className="value">R$ {maintenancePiece.value.toLocaleString()}</p>

      <p className="total">
        R${' '}
        {(maintenancePiece.value * maintenancePiece.quantity).toLocaleString()}
      </p>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onMaintenancePieceDelete(maintenancePiece);
        }}
        className="btn excluir"
      >
        Excluir
      </button>
    </div>
  ));

const maintenancePieceList = props => {
  return (
    <div>
      <hr style={{ width: '90%', marginBottom: '20px' }} />

      {!props.loadingList ? (
        props.maintenancePieces.length > 0 ? (
          <MaintenancePieceStyles>
            {maintenancePieceListItem(props)}
          </MaintenancePieceStyles>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}
      <hr style={{ width: '90%', marginTop: '20px' }} />
    </div>
  );
};

export default maintenancePieceList;
