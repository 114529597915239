import React, { Component } from 'react';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';

import API from '../../services/api';
import LocalForm from './LocalForm';

class LocalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locals: null,
    };

    this.handleLocalCreation = this.handleLocalCreation.bind(this);

    this.getLocalsList();
  }

  refLocals = React.createRef();

  getLocalsList = async () => {
    const { onLocalsList } = this.props;
    const { local: selectedLocal } = this.state;

    try {
      const response = await API.get(`locals`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        locals: response.data,
      }));

      onLocalsList(response.data, selectedLocal);
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  handleLocalRefresh = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      local: null,
    }));
  };

  handleLocalCreation = async object => {
    const { onLocalCreation } = this.props;
    const name = object.name.toUpperCase();

    try {
      const response = await API.post(`locals`, { name });

      toast.success('Local cadastrado com sucesso.');

      this.getLocalsList(response.data);

      onLocalCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handleLocalUpdate = async object => {
    const { local } = this.state;

    const name = object.name.toUpperCase();

    try {
      const response = await API.put(`locals/${local.uuid}`, { name });

      this.getLocalsList(response.data);

      toast.success('Local alterado com sucesso.');

      this.setState({ local: null });
    } catch (err) {
      toast.error(err);
    }
  };

  handleLocalEdit = object => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      local: object,
    }));
  };

  handleLocalDelete = async () => {
    const { local } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do local?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        const response = await API.delete(`locals/${local.uuid}`);

        this.setState({ local: null });

        this.getLocalsList(response.data);

        toast.success('Local deletado com sucesso.');
      }
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { visible } = this.props;
    const { locals, local } = this.state;

    return (
      <div>
        {visible ? (
          <>
            <div ref={this.refLocals} />

            <LocalForm
              local={local}
              locals={locals}
              onLocalRefresh={this.handleLocalRefresh}
              onLocalCreation={this.handleLocalCreation}
              onLocalEdit={this.handleLocalEdit}
              onLocalUpdate={this.handleLocalUpdate}
              onLocalDelete={this.handleLocalDelete}
            />
          </>
        ) : null}
      </div>
    );
  }
}

LocalContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onLocalsList: PropTypes.func.isRequired,
  onLocalCreation: PropTypes.func.isRequired,
};

export default LocalContainer;
