import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import swal from '@sweetalert/with-react';
import PieceContainer from '../../Piece/PieceContainer';
import MaintenancePieceForm from './MaintenancePieceForm';
import MaintenancePieceList from './MaintenancePieceList';

import API from '../../../services/api';

class MaintenancePieceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingList: true,

      maintenancePieces: null,

      pieceForm: false,

      piece: null,

      pieces: [{ uuid: null, code: 'CARREGANDO' }],
    };

    this.handleMaintenancePieceCreation = this.handleMaintenancePieceCreation.bind(
      this
    );
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):

    const { maintenance } = this.props;

    if (maintenance !== prevProps.maintenance) {
      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        loadingList: true,
      }));
      this.getMaintenancePiecesList();
    }
  }

  refMaintenancePieces = React.createRef();

  getMaintenancePiecesList = async maintenancePiece => {
    const { maintenance, onMaintenancePiecesList } = this.props;

    try {
      const response = await API.get(`maintenancepieces/${maintenance.uuid}`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        maintenancePieces: response.data,
        loadingList: false,
      }));

      onMaintenancePiecesList(response.data, maintenancePiece);
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenancePieceCreation = async maintenancePiece => {
    const { maintenance, onMaintenancePieceCreation } = this.props;

    const data = {
      maintenanceUuid: maintenance.uuid,

      pieceUuid: maintenancePiece.piece,

      quantity: maintenancePiece.quantity,

      value: maintenancePiece.value,
    };

    try {
      const response = await API.post(`maintenancepieces`, { ...data });

      this.getMaintenancePiecesList(response.data);

      onMaintenancePieceCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenancePieceDelete = async maintenancePiece => {
    const { onMaintenancePieceDelete } = this.props;
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da peça da manutenção?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`maintenancepieces/${maintenancePiece.uuid}`);

        await this.getMaintenancePiecesList();

        onMaintenancePieceDelete();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  // PIECES HELPERS

  handlePiecesList = (pieces, piece) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      pieces,
      piece,
    }));
  };

  handlePieceFormState() {
    const { pieceForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      pieceForm: !pieceForm,
    }));
  }

  render() {
    return (
      <div>
        <div ref={this.refMaintenancePiece} />

        {this.props.maintenancePieceForm ? (
          <Fragment>
            <MaintenancePieceList
              maintenancePieces={this.state.maintenancePieces}
              onMaintenancePieceDelete={this.handleMaintenancePieceDelete}
              loadingList={this.state.loadingList}

              // onMaintenanceEdit={this.handleMaintenanceEdit}
            />

            <div className="page-header">
              <h1>ADICIONAR</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => this.handlePieceFormState()}
                  style={{ marginRight: '30px', float: 'right' }}
                >
                  NOVA PEÇA
                </Button>
              </div>
            </div>

            <PieceContainer
              onPiecesList={this.handlePiecesList}
              onPieceCreation={() => this.handlePieceFormState()}
              pieceForm={this.state.pieceForm}
            />

            <MaintenancePieceForm
              onMaintenancePieceCreation={this.handleMaintenancePieceCreation}
              pieces={this.state.pieces}
              piece={this.state.piece}
            />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

export default MaintenancePieceContainer;
