import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ListStyled, ListLink } from './styles';

import Loading from '../components/Loading';

class EmployeeList extends Component {
  EmployeeListItem = employees =>
    employees.map(employee => (
      <ListLink
        gridheader="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridlist="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        color="#011826"
        strongcolor="#fff"
        to={`/employees/${employee.uuid}`}
        key={employee.uuid}
      >
        <strong>{employee.name ? employee.name : '---'}</strong>
        <strong>{employee.rg ? employee.rg : '---'}</strong>
        <strong>{employee.cpf ? employee.cpf : '---'}</strong>
        <strong>{employee.cnh ? employee.cnh : '---'}</strong>
        <strong>{employee.phone ? employee.phone : '---'}</strong>
        <strong>{employee.bond ? employee.bond : '---'}</strong>
        <strong>{employee.type ? employee.type : '---'}</strong>
      </ListLink>
    ));

  render() {
    const { isLoading, employees } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {employees && (
              <>
                {employees.length > 0 ? (
                  <ListStyled
                    gridheader="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                    gridlist="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                  >
                    <header>
                      <div className="total">
                        <h1>TOTAL: </h1>

                        <p>{employees.length}</p>
                      </div>

                      <div className="titles">
                        <strong>NOME</strong>
                        <strong>RG</strong>
                        <strong>CPF</strong>
                        <strong>CNH</strong>
                        <strong>TELEFONE</strong>
                        <strong>TIPO</strong>
                        <strong>VÍNCULO</strong>
                      </div>
                    </header>

                    {this.EmployeeListItem(employees)}
                  </ListStyled>
                ) : (
                  <ListStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

EmployeeList.propTypes = {
  isLoading: PropTypes.bool,

  employees: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      cpf: PropTypes.string,
      rg: PropTypes.string,
      rg_expiration: PropTypes.string,
      rg_emission: PropTypes.string,
      cnh: PropTypes.string,
      cnh_expiration: PropTypes.string,
      title: PropTypes.string,
      section: PropTypes.string,
      zone: PropTypes.string,
      birth_date: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.string,
      type: PropTypes.string,
      bond: PropTypes.string,
    })
  ),
};

EmployeeList.defaultProps = {
  employees: [],

  isLoading: false,
};

export default EmployeeList;
