import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import ReactSelectStyle from './styles';

class ReactSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  render() {
    const { options, onSetValue, placeholder, ...rest } = this.props;

    const { value } = this.state;

    return (
      <Select
        options={options}
        getOptionValue={option => option.uuid}
        getOptionLabel={option => option.name}
        styles={ReactSelectStyle}
        value={value}
        onChange={e => {
          onSetValue(e);
          this.setState({ value: null });
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

export default ReactSelect;

ReactSelect.propTypes = {
  options: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.strinf,
  }),
  onSetValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

ReactSelect.defaultProps = {
  options: [],
  placeholder: '',
};
