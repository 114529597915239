import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import ReactSelect from '../components/ReactSelect';

import { FormStyled } from '../../styles/form';

const handleValidate = ({ code, type }) => {
  const errors = {};

  if (!code) errors.code = 'OBRIGATÓRIO';

  if (!type) errors.type = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.equipment) {
    await props.onEquipmentCreation({ ...values });
  } else {
    await props.onEquipmentUpdate({ ...values });
  }
  setSubmitting(false);

  resetForm();
};

const EquipmentForm = props => {
  const {
    equipments,
    equipment,
    onEquipmentEdit,
    onEquipmentDelete,
    onEquipmentRefresh,
  } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          code: equipment ? equipment.code : '',

          year: equipment ? equipment.year : '',

          type: equipment ? equipment.type : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleReset,
          isSubmitting,
        }) => (
          <FormStyled>
            <h1>{equipment ? 'EDITAR VEÍCULO' : 'NOVO VEÍCULO'}</h1>

            <div className="formData">
              <strong>CÓDIGO</strong>

              <input
                placeholder="Digite a placa do cliente..."
                name="code"
                type="text"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.code && touched.code)}
              />

              {errors.code && touched.code && <span>{errors.code}</span>}
            </div>

            <div className="formData">
              <strong>TIPO</strong>

              <select
                name="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.type && touched.type)}
              >
                <option value="" />
                <option value="CAVALO">CAVALO</option>
                <option value="CARRETA">CARRETA</option>
              </select>

              {errors.type && touched.type && <span>{errors.type}</span>}
            </div>

            <div className="formData">
              <strong>ANO</strong>

              <input
                placeholder="Digite o ano do veículo..."
                name="year"
                type="text"
                value={values.year}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.year && touched.year)}
              />

              {errors.year && touched.year && <span>{errors.year}</span>}
            </div>

            <button type="submit" disabled={!dirty || isSubmitting}>
              {equipment ? 'ALTERAR' : 'CADASTRAR'}
            </button>

            <button
              type="button"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              DESFAZER
            </button>

            {equipment && (
              <button type="button" onClick={onEquipmentRefresh}>
                LIMPAR
              </button>
            )}

            {equipment && (
              <button
                type="button"
                onClick={onEquipmentDelete}
                className="delete"
              >
                EXCLUIR
              </button>
            )}

            <div className="division">
              <hr />
              <p>OU</p>
              <hr />
            </div>

            <ReactSelect
              placeholder="Escolher veículo existente"
              options={equipments}
              onSetValue={onEquipmentEdit}
              getOptionLabel={option => `${option.code} || ${option.type}`}
            />
          </FormStyled>
        )}
      </Formik>
    </div>
  );
};

EquipmentForm.propTypes = {
  equipments: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  equipment: PropTypes.shape({
    code: PropTypes.string,
    year: PropTypes.string,
    type: PropTypes.string,
  }),
  onEquipmentEdit: PropTypes.func.isRequired,
  onEquipmentDelete: PropTypes.func.isRequired,
  onEquipmentRefresh: PropTypes.func.isRequired,
};

EquipmentForm.defaultProps = {
  equipments: [],
  equipment: null,
};

export default EquipmentForm;
