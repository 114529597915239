import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import io from 'socket.io-client';
import swal from '@sweetalert/with-react';
import RefuelForm from './RefuelForm';
import RefuelList from './RefuelList';
import RefuelFilterForm from './RefuelFilterForm';
import EquipmentContainer from '../Equipment/EquipmentContainer';
import TankContainer from '../Tank/TankContainer';
import SnackBar from '../../Utilities/SnackBar';
import API from '../../services/api';

import Parameters from '../../services/parameters';

const styles = theme => ({
  root: {
    width: '90%',
    margin: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
});

class RefuelContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenances: [],
      maintenance: null,
      loadingList: true,

      tank: null,
      tankForm: false,

      filtro: '',

      equipmentForm: false,
      equipment: null,
      equipments: [{ uuid: null, code: 'CARREGANDO' }],

      begin: new Date().toISOString().substring(0, 10),
      end: new Date().toISOString().substring(0, 10),
      group: false,

      snackBarOpen: false,

      closedList: false,
    };

    // MAITENANCE BIND
    this.handleMaintenanceCreation = this.handleMaintenanceCreation.bind(this);
    this.handleMaintenanceUpdate = this.handleMaintenanceUpdate.bind(this);
    this.handleMaintenanceEdit = this.handleMaintenanceEdit.bind(this);
    this.handleMaintenanceGet = this.handleMaintenanceGet.bind(this);
    this.handleMaintenanceDelete = this.handleMaintenanceDelete.bind(this);

    // EQUIPMENT BIND
    this.handleEquipmentsList = this.handleEquipmentsList.bind(this);
    this.handleEquipmentFormState = this.handleEquipmentFormState.bind(this);
  }

  refMaintenance = React.createRef();

  componentDidMount() {
    this.registerToSocket();
    this.getTodosList();
    this.getEquipmentsList();
  }

  getTank = async () => {
    try {
      const response = await API.get(`tanks`);
      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        tank: response.data.tank,
        tankArla: response.data.tankArla,
        tanks: [response.data.tank, response.data.tankArla],
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('refuel', () => this.getTodosList());
    socket.on('tankRefuel', () => this.getTank());
  };

  handleMaintenanceCreation = async object => {
    const data = {
      date: `${object.opening_date}T${object.time}`,
      type: object.type,
      equipmentUuid: object.equipment,
      km: object.km ? object.km : 0,
      qty: object.qty ? object.qty : 0,
      value: object.value ? object.value : 0,
      bead_number: object.bead_number ? object.bead_number : null,
      arla: object.arla ? object.arla : null,
    };

    try {
      const response = await API.post(`refuels`, { ...data });

      this.handleMaintenanceEdit(response.data);
      await this.getTodosList();
      this.getEquipmentsList();
      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error('Erro ao criar abastecimento.');
    }
  };

  handleMaintenanceUpdate = async object => {
    const { maintenance } = this.state;

    const data = {
      date: `${object.opening_date}T${object.time}`,
      type: object.type,
      // equipmentUuid: object.equipment,
      km: object.km ? object.km : 0,
      qty: object.qty ? object.qty : 0,
      value: object.value ? object.value : 0,
      bead_number: object.bead_number ? object.bead_number : null,
      arla: object.arla ? object.arla : null,
    };

    try {
      const response = await API.put(`refuels/${maintenance.uuid}`, {
        ...data,
      });

      this.handleMaintenanceEdit(response.data);
      await this.getTodosList();
      this.getEquipmentsList();
      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  getEquipmentsList = async () => {
    try {
      const response = await API.get(`equipments`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        equipments: response.data,
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceDelete = async maintenance => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do Abastecimento?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`refuels/${maintenance.uuid}`);

        this.setState({ snackBarOpen: true });
        this.handleMaintenanceNew();
        await this.getTodosList();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceEdit = maintenance => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenance,
      equipmentForm: false,
    }));

    this.handleMaintenanceGet(maintenance.uuid);

    this.scrollToMyRef(this.refMaintenance);
  };

  handleClosedList = async () => {
    await this.setState(prevState => ({
      closedList: !prevState.closedList,
      loadingList: true,
    }));
    this.getTodosList();
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleMaintenanceNew = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenance: null,
      equipment: null,
      equipmentForm: false,
    }));
    this.scrollToMyRef(this.refMaintenance);
  };

  getTodosList = async values => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      loadingList: true,
    }));

    const begin = values
      ? values.date
      : new Date().toISOString().substring(0, 10);
    const end = values
      ? values.date2
      : new Date().toISOString().substring(0, 10);
    const group = values && values.group ? values.group : false;

    if (values) {
      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        group: values.group ? values.group : false,
      }));
    }

    const equipmentUuid =
      values && values.equipmentUuid ? values.equipmentUuid : null;

    const type = values && values.type ? values.type : null;

    try {
      const response = await API.get(
        `refuels/${begin}/${end}/${equipmentUuid}/${group}/${type}/`
      );

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        maintenances: response.data,
        loadingList: false,
      }));

      this.getTank();
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceGet = async uuid => {
    try {
      const response = await API.get(`refuels/${uuid}`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        maintenance: response.data[0],
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleSnackBarClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //  return;
    // }

    this.setState({ snackBarOpen: false });
  };

  // EQUIPMENTS HELPERS
  handleEquipmentsList = (equipments, equipment) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      equipments,
      equipment,
    }));
  };

  handleTankFormState() {
    const { tankForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      tankForm: !tankForm,
      equipmentForm: false,
    }));
  }

  handleEquipmentFormState() {
    const { equipmentForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      equipmentForm: !equipmentForm,
      tankForm: false,
    }));
  }

  handleChangeFiltro = async event => {
    // eslint-disable-next-line no-unused-vars
    await this.setState(prevState => ({ filtro: event.target.value }));
    this.getTodosList();
  };

  render() {
    const { classes, useruuid } = this.props;

    return (
      <Fragment>
        <center>
          <Paper className={classes.root}>
            <div className="page-header">
              <h1>ABASTECIMENTOS</h1>
              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleMaintenanceNew()}
                >
                  NOVO
                </Button>
              </div>
            </div>

            <hr style={{ width: '90%', marginBottom: '20px' }} />

            <RefuelFilterForm
              equipments={this.state.equipments}
              onRefuelsList={this.getTodosList}
            />

            <hr style={{ width: '90%', margin: '20px' }} />

            <RefuelList
              maintenances={this.state.maintenances}
              loadingList={this.state.loadingList}
              onMaintenanceEdit={this.handleMaintenanceEdit}
              onMaintenanceDelete={this.handleMaintenanceDelete}
              group={this.state.group}
            />

            <div ref={this.refMaintenance} />

            <div className="page-header">
              <h1>ABASTECIMENTO</h1>
              <div className="last-wrap">
                {useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                useruuid === '7eff000c-91d9-4c54-b597-79bd93938325' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '30px', float: 'left' }}
                    onClick={() => this.handleEquipmentFormState()}
                  >
                    VEÍCULO
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleTankFormState()}
                >
                  TANQUE
                </Button>
              </div>
            </div>

            <h1>
              TANQUE: {this.state.tank && this.state.tank.tank.toLocaleString()}
            </h1>

            <h1>
              TANQUE ARLA:{' '}
              {this.state.tankArla && this.state.tankArla.tank.toLocaleString()}
            </h1>

            <TankContainer
              tankForm={this.state.tankForm}
              onTankRefuelCreation={() => this.getTank()}
              tanks={this.state.tanks}
            />

            <EquipmentContainer
              onEquipmentsList={this.handleEquipmentsList}
              onEquipmentCreation={() => this.handleEquipmentFormState()}
              equipmentForm={this.state.equipmentForm}
            />

            <RefuelForm
              onMaintenanceCreation={this.handleMaintenanceCreation}
              onMaintenanceUpdate={this.handleMaintenanceUpdate}
              onMaintenanceNew={this.handleMaintenanceNew}
              onMaintenanceDelete={this.handleMaintenanceDelete}
              maintenance={this.state.maintenance}
              equipments={this.state.equipments}
              equipment={this.state.equipment}
              useruuid={useruuid}
              tank={this.state.tank}
              tankArla={this.state.tankArla}
            />
          </Paper>

          <SnackBar
            onSnackBarClose={this.handleSnackBarClose}
            snackBarOpen={this.state.snackBarOpen}
          />
        </center>
      </Fragment>
    );
  }
}

RefuelContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RefuelContainer);
