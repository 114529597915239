import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

import SelectMUI from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '30%',

    textAlign: 'center',
  },

  rootSelect: {
    display: 'flex',

    flexWrap: 'wrap',

    width: '90%',
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const handleValidate = ({ qty, document, value, tankUuid }) => {
  const errors = {};

  if (!tankUuid) errors.tankUuid = 'OBRIGATÓRIO';

  if (!qty) errors.qty = 'OBRIGATÓRIO';

  if (!document) errors.document = 'OBRIGATÓRIO';

  if (!value) errors.value = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onCreation({ ...values });

  setSubmitting(false);

  resetForm();
};

const EquipmentForm = props => {
  const { classes, tanks, onSelectTank } = props;

  return (
    <Fragment>
      <h1>ENTRADA TANQUE</h1>

      <Formik
        enableReinitialize
        initialValues={{
          document: '',

          qty: '',

          value: '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {props => (
          <Form>
            <div className={classes.formControl}>
              <InputLabel
                style={{ disableAnimation: false }}
                disableAnimation={false}
                htmlFor="tankUuid"
              >
                TANQUE
              </InputLabel>

              <SelectMUI
                id="tankUuid"
                name="tankUuid"
                value={props.values.tankUuid}
                onChange={e => {
                  onSelectTank(e.target);
                  props.setFieldValue('tankUuid', e.target.value);
                }}
                onBlur={props.handleBlur}
                inputProps={{
                  name: 'tankUuid',

                  id: 'tankUuid',
                }}
                style={{
                  width: '90%',

                  margin: '10px',

                  marginBottom: '20px',
                }}
                error={!!(props.errors.tankUuid && props.touched.tankUuid)}
              >
                {tanks &&
                  tanks.length > 0 &&
                  tanks.map(item => (
                    <MenuItem value={item.uuid}>
                      {`${
                        item.description === 'Arla' ? 'TANQUE ARLA' : 'TANQUE'
                      } | ${item.tank}`}
                    </MenuItem>
                  ))}
              </SelectMUI>
            </div>

            <div
              className={classes.formControl}
              style={{ marginBottom: '20px' }}
            >
              <TextField
                id="qty"
                label="Litros"
                variant="outlined"
                value={props.values.qty}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="number"
                className={classes.textFieldNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                error={!!(props.errors.qty && props.touched.qty)}
              />

              {props.errors.qty && props.touched.qty && (
                <div style={{ color: 'red' }}>{props.errors.qty}</div>
              )}

              <TextField
                id="document"
                label="Documento"
                variant="outlined"
                value={props.values.document}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="text"
                className={classes.textFieldNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                error={!!(props.errors.document && props.touched.document)}
              />

              {props.errors.document && props.touched.document && (
                <div style={{ color: 'red' }}>{props.errors.document}</div>
              )}

              <TextField
                id="value"
                label="valor"
                variant="outlined"
                value={props.values.value}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="number"
                className={classes.textFieldNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                error={!!(props.errors.value && props.touched.value)}
              />

              {props.errors.value && props.touched.value && (
                <div style={{ color: 'red' }}>{props.errors.value}</div>
              )}
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={props.isSubmitting}
              style={{ margin: '10px' }}
            >
              SALVAR
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="reset"
              onClick={props.handleReset}
              disabled={!props.dirty || props.isSubmitting}
              style={{ margin: '10px' }}
            >
              RESET
            </Button>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

EquipmentForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EquipmentForm);
