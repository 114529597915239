import React from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { MdDelete } from 'react-icons/md';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

import SelectMUI from '@material-ui/core/Select';

import { ListStyled, ListItem } from '../../css/Styles/list-grid';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '40%',

    textAlign: 'center',
  },

  rootSelect: {
    display: 'flex',

    flexWrap: 'wrap',

    width: '90%',
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const handleValidate = ({ quantity, pieceUuid }) => {
  const errors = {};

  if (!quantity || quantity === 0) errors.quantity = 'OBRIGATÓRIO';

  if (!pieceUuid) errors.pieceUuid = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onAdd({ ...values });

  setSubmitting(false);
  resetForm();
};

const MovementForm = props => {
  const {
    classes,

    pieces,

    piece,

    movements,

    onDelete,
  } = props;
  return (
    <div>
      <Paper className={classes.root}>
        <h1>ADICIONAR MOVIMENTAÇÃO</h1>

        <Formik
          enableReinitialize
          initialValues={{
            quantity: 0,
            pieceUuid: piece ? piece.uuid : '',
          }}
          validate={handleValidate(props)}
          onSubmit={handleSubmit(props)}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset,
          }) => (
            <Form>
              <div className={classes.formControl}>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="pieceUuid"
                >
                  PEÇA
                </InputLabel>

                <SelectMUI
                  id="pieceUuid"
                  name="pieceUuid"
                  value={values.pieceUuid}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    name: 'pieceUuid',

                    id: 'pieceUuid',
                  }}
                  style={{
                    width: '90%',

                    margin: '35px',

                    marginBottom: '20px',

                    marginTop: '10px',
                  }}
                  error={!!(errors.pieceUuid && touched.pieceUuid)}
                >
                  {pieces &&
                    pieces.length > 0 &&
                    pieces.map(object => (
                      <MenuItem value={object.uuid} key={object.uuid}>
                        {object.description} ({object.total || 0})
                      </MenuItem>
                    ))}
                </SelectMUI>
                {errors.pieceUuid && touched.pieceUuid && (
                  <div style={{ color: 'red' }}>{errors.pieceUuid}</div>
                )}
              </div>

              <div>
                <TextField
                  id="quantity"
                  name="quantity"
                  label="Quantidade"
                  type="number"
                  variant="outlined"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.textField}
                  error={!!(errors.quantity && touched.quantity)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {errors.quantity && touched.quantity && (
                  <div style={{ color: 'red' }}>{errors.quantity}</div>
                )}
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!dirty || isSubmitting}
                style={{ margin: '10px' }}
              >
                ADICIONAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>
            </Form>
          )}
        </Formik>
        {movements && movements[0] && (
          <ListStyled gridHeader="4fr 2fr 2fr 0.5fr">
            <header>
              <div className="titles">
                <strong>PEÇA</strong>
                <strong>QUANTIDADE</strong>
                <strong>ATUAL</strong>
                <strong />
              </div>
            </header>
            {movements.map((movement, index) => (
              <ListItem
                gridList="4fr 2fr 2fr 0.5fr"
                color="#333"
                strongColor="#fff"
                key={`${movement.pieceUuid}-${movement.quantity}`}
              >
                <strong>{movement.piece.description}</strong>
                <strong>{movement.quantity}</strong>
                <strong>{movement.piece.total || 0}</strong>
                <button type="button" onClick={() => onDelete(index)}>
                  <MdDelete color="#ff5757" size={20} />
                </button>
              </ListItem>
            ))}
          </ListStyled>
        )}
      </Paper>
    </div>
  );
};

MovementForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MovementForm);
