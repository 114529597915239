import React, { Component } from 'react';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';

import API from '../../services/api';
import EmployeeForm from './EmployeeForm';

class EmployeeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: null,
    };

    this.handleEmployeeCreation = this.handleEmployeeCreation.bind(this);

    this.getEmployeesList();
  }

  refEmployees = React.createRef();

  getEmployeesList = async () => {
    const { onEmployeesList } = this.props;
    const { partner: selectedPartner } = this.state;

    try {
      const response = await API.get(`employees/list/null/null/null/null`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        employees: response.data,
      }));

      onEmployeesList(response.data, selectedPartner);
    } catch (err) {
      toast.error(err);
    }
  };

  handleEmployeeRefresh = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      employee: null,
    }));
  };

  handleEmployeeCreation = async object => {
    const { onEmployeeCreation } = this.props;
    const name = object.name.toUpperCase();

    const data = {
      name,
      cpf: object.cpf,
      rg: object.rg,
      rg_expiration: object.rg_expiration,
      rg_emission: object.rg_emission,
      cnh: object.cnh,
      cnh_expiration: object.cnh_expiration,
      title: object.title,
      section: object.section,
      zone: object.zone,
      birth_date: object.birth_date,
      phone: object.phone,
      address: object.address,
      type: object.type,
      bond: object.bond,
    };

    try {
      const response = await API.post(`employees`, data);

      toast.success('Funcionário cadastrado com sucesso.');

      this.getEmployeesList(response.data);

      onEmployeeCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handleEmployeeUpdate = async object => {
    const { employee } = this.state;
    const name = object.name.toUpperCase();

    const data = {
      name,
      cpf: object.cpf,
      rg: object.rg,
      rg_expiration: object.rg_expiration,
      rg_emission: object.rg_emission,
      cnh: object.cnh,
      cnh_expiration: object.cnh_expiration,
      title: object.title,
      section: object.section,
      zone: object.zone,
      birth_date: object.birth_date,
      phone: object.phone,
      address: object.address,
      type: object.type,
      bond: object.bond,
    };

    try {
      const response = await API.put(`employees/${employee.uuid}`, data);

      this.getEmployeesList(response.data);

      toast.success('Funcionário alterado com sucesso.');

      this.setState({ employee: null });
    } catch (err) {
      toast.error(err);
    }
  };

  handleEmployeeEdit = object => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      employee: object,
    }));
  };

  handleEmployeeDelete = async () => {
    const { employee } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do funcionário?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        const response = await API.delete(`employees/${employee.uuid}`);

        this.setState({ employee: null });

        this.getEmployeesList(response.data);

        toast.success('Funcionário deletado com sucesso.');
      }
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { visible } = this.props;
    const { employees, employee } = this.state;

    return (
      <div>
        {visible ? (
          <>
            <div ref={this.refEmployees} />

            <EmployeeForm
              employees={employees}
              employee={employee}
              onEmployeeRefresh={this.handleEmployeeRefresh}
              onEmployeeCreation={this.handleEmployeeCreation}
              onEmployeeEdit={this.handleEmployeeEdit}
              onEmployeeUpdate={this.handleEmployeeUpdate}
              onEmployeeDelete={this.handleEmployeeDelete}
            />
          </>
        ) : null}
      </div>
    );
  }
}

EmployeeContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onEmployeesList: PropTypes.func.isRequired,
  onEmployeeCreation: PropTypes.func.isRequired,
};

export default EmployeeContainer;
