import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ListStyled, ListLink } from './styles';

import Loading from '../components/Loading';

class RoleList extends Component {
  RoleListItem = roles =>
    roles.map(role => (
      <ListLink
        gridheader="auto"
        gridlist="auto"
        color="#011826"
        strongcolor="#fff"
        to={`/roles/${role.uuid}`}
        key={role.uuid}
      >
        <strong>{role.description ? role.description : '---'}</strong>
      </ListLink>
    ));

  render() {
    const { isLoading, roles } = this.props;

    return (
      <>
        {!isLoading ? (
          <>
            {roles && (
              <>
                {roles.length > 0 ? (
                  <ListStyled gridheader="auto" gridlist="auto">
                    <header>
                      <div className="total">
                        <h1>TOTAL: </h1>

                        <p>{roles.length}</p>
                      </div>

                      <div className="titles">
                        <strong>DESCRIÇÃO</strong>
                      </div>
                    </header>

                    {this.RoleListItem(roles)}
                  </ListStyled>
                ) : (
                  <ListStyled>
                    <strong>LISTA VAZIA</strong>
                  </ListStyled>
                )}
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

RoleList.propTypes = {
  isLoading: PropTypes.bool,

  roles: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

RoleList.defaultProps = {
  roles: [],

  isLoading: false,
};

export default RoleList;
