import React from 'react';

import { IoMdTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

import Loading from '../../components/Loading';

import { ListStyled, ListItem } from '../styles';

const examRoleListItem = props =>
  props.examRoles.map(examRole => (
    <ListItem
      gridheader="90% 10%"
      gridlist="90% 10%"
      color="#011826"
      strongcolor="#fff"
      key={examRole.uuid}
    >
      <strong>{examRole.exam && examRole.exam.description}</strong>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onExamRoleDelete(examRole.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function ExamRoleList(props) {
  const { isLoading, examRoles } = props;

  return (
    <>
      {!isLoading || examRoles != null ? (
        <>
          {examRoles && examRoles.length > 0 ? (
            <ListStyled gridheader="90% 10%" gridlist="90% 10%">
              <header>
                <div className="titles">
                  <strong>EXAME</strong>

                  <strong />
                </div>
              </header>

              {examRoleListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>SEM FUNÇÕES</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

ExamRoleList.propTypes = {
  isLoading: PropTypes.bool,

  examRoles: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      exam: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
};

ExamRoleList.defaultProps = {
  isLoading: false,

  examRoles: [],
};

export default ExamRoleList;
