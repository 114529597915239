import React, { Component } from 'react';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';

import API from '../../services/api';
import Parameters from '../../services/parameters';
import PartnerForm from './PartnerForm';

class PartnerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: null,
    };

    this.handlePartnerCreation = this.handlePartnerCreation.bind(this);

    this.getPartnersList();
  }

  componentDidMount() {
    this.registerToSocket();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('partner', () => this.getPartnersList());
  };

  refAccount = React.createRef();

  getPartnersList = async () => {
    const { onPartnersList } = this.props;
    const { partner: selectedPartner } = this.state;

    try {
      const response = await API.get(`partners`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        partners: response.data,
      }));

      onPartnersList(response.data, selectedPartner);
    } catch (err) {
      toast.error(err);
    }
  };

  handlePartnerRefresh = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      partner: null,
    }));
  };

  handlePartnerCreation = async object => {
    const { onPartnerCreation } = this.props;
    const name = object.name.toUpperCase();
    const { cnpj } = object;

    const data = {
      name,
      cnpj: cnpj || null,
    };

    try {
      const response = await API.post(`partners`, { ...data });

      toast.success('Cliente cadastrado com sucesso.');

      this.getPartnersList(response.data);

      onPartnerCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  handlePartnerUpdate = async object => {
    const { partner } = this.state;

    const name = object.name.toUpperCase();
    const { cnpj } = object;
    try {
      const response = await API.put(`partners/${partner.uuid}`, {
        name,
        cnpj: cnpj || null,
      });

      this.getPartnersList(response.data);

      toast.success('Cliente alterado com sucesso.');

      this.setState({ partner: null });
    } catch (err) {
      toast.error(err);
    }
  };

  handlePartnerEdit = object => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      partner: object,
    }));
  };

  handlePartnerDelete = async () => {
    const { partner } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do cliente?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        const response = await API.delete(`partners/${partner.uuid}`);

        this.setState({ partner: null });

        this.getPartnersList(response.data);

        toast.success('Cliente deletado com sucesso.');
      }
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    const { visible } = this.props;
    const { partners, partner } = this.state;

    return (
      <div>
        {visible ? (
          <>
            <div ref={this.refAccount} />

            <PartnerForm
              partners={partners}
              partner={partner}
              onPartnerRefresh={this.handlePartnerRefresh}
              onPartnerCreation={this.handlePartnerCreation}
              onPartnerEdit={this.handlePartnerEdit}
              onPartnerUpdate={this.handlePartnerUpdate}
              onPartnerDelete={this.handlePartnerDelete}
            />
          </>
        ) : null}
      </div>
    );
  }
}

PartnerContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onPartnersList: PropTypes.func.isRequired,
  onPartnerCreation: PropTypes.func.isRequired,
};

export default PartnerContainer;
