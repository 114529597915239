import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import ReactSelect from 'react-select';

import SelectStyled from '../../styles/select';

import { InputLabel } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '40%',

    textAlign: 'center',
  },
});

const handleValidate = props => ({
    date,

    dead_line,

    value,

    dt_discharge,

    number,

    equipmentUuid,

    employeeUuid,

    localUuid,

  }) => {

  const errors = {};

  if(!date) errors.date = 'OBRIGATÓRIO';
  if(!dead_line) errors.dead_line = 'OBRIGATÓRIO';
  if(!value) errors.value = 'OBRIGATÓRIO';
  if(!dt_discharge) errors.dt_discharge = 'OBRIGATÓRIO';
  if(!number) errors.number = 'OBRIGATÓRIO';
  if(!equipmentUuid) errors.equipmentUuid = 'OBRIGATÓRIO';
  if(!employeeUuid) errors.employeeUuid = 'OBRIGATÓRIO';
  if(!localUuid) errors.localUuid = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  console.log(values);
  if (!props.ticket) {
    await props.onTicketCreation({ ...values });
  } else {
    await props.onTicketUpdate({ ...values });
  }

  setSubmitting(false);
};

const TicketForm = props => {
  const {
    classes,

    ticket,

    equipments,

    equipment,

    employees,

    employee,

    locals,

    local,

    onTicketDelete,

    onTicketNew,
  } = props;

  return (
    <div>
      <Paper className={classes.root}>
        {ticket ? <h1>ALTERAR MULTA</h1> : <h1>NOVA MULTA</h1>}

        <Formik
          enableReinitialize
          initialValues={{
            date: ticket && ticket.date ? ticket.date.substring(0, 10) : '',

            dead_line:
              ticket && ticket.dead_line
                ? ticket.dead_line.substring(0, 10)
                : '',

            value: !ticket ? '' : ticket.value,

            number: !ticket ? '' : ticket.number,

            dt_discharge:
              ticket && ticket.dt_discharge
                ? ticket.dt_discharge.substring(0, 10)
                : '',

            value_discharge: !ticket ? '' : ticket.value_discharge,

            equipmentUuid:
              ticket && ticket.equipment
                ? ticket.equipment.uuid
                : equipment
                ? equipment.uuid
                : '',

            employeeUuid:
              ticket && ticket.employee
                ? ticket.employee.uuid
                : employee
                ? employee.uuid
                : '',

            localUuid:
              ticket && ticket.local
                ? ticket.local.uuid
                : local
                ? local.uuid
                : '',
          }}
          validate={handleValidate(props)}
          onSubmit={handleSubmit(props)}
        >
          {props => (
            <Form>
              <div style={{ marginBottom: '20px' }}>
                <TextField
                  id="date"
                  name="date"
                  label="Data"
                  type="date"
                  variant="outlined"
                  value={props.values.date}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={!!(props.errors.date && props.touched.date)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {props.errors.date && props.touched.date && (
                  <div style={{ color: 'red' }}>{props.errors.date}</div>
                )}

                <TextField
                  id="dead_line"
                  name="dead_line"
                  label="Prazo"
                  type="date"
                  variant="outlined"
                  value={props.values.dead_line}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={!!(props.errors.dead_line && props.touched.dead_line)}
                />

                {props.errors.dead_line && props.touched.dead_line && (
                  <div style={{ color: 'red' }}>{props.errors.dead_line}</div>
                )}
              </div>

              <div style={{ marginBottom: '20px' }}>
                <TextField
                  id="value"
                  name="value"
                  label="Valor"
                  type="text"
                  variant="outlined"
                  value={props.values.value}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={!!(props.errors.value && props.touched.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {props.errors.value && props.touched.value && (
                  <div style={{ color: 'red' }}>{props.errors.value}</div>
                )}

                <TextField
                  id="number"
                  name="number"
                  label="Número"
                  type="text"
                  variant="outlined"
                  value={props.values.number}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={!!(props.errors.number && props.touched.number)}
                />

                {props.errors.number && props.touched.number && (
                  <div style={{ color: 'red' }}>{props.errors.number}</div>
                )}
              </div>

              <div style={{ marginBottom: '20px' }}>
                <TextField
                  id="dt_discharge"
                  name="dt_discharge"
                  label="Data de Baixa"
                  type="date"
                  variant="outlined"
                  value={props.values.dt_discharge}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={
                    !!(props.errors.dt_discharge && props.touched.dt_discharge)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {props.errors.dt_discharge && props.touched.dt_discharge && (
                  <div style={{ color: 'red' }}>
                    {props.errors.dt_discharge}
                  </div>
                )}

                <TextField
                  id="value_discharge"
                  name="value_discharge"
                  label="Valor de Baixa"
                  type="text"
                  variant="outlined"
                  value={props.values.value_discharge}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textFieldNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  error={
                    !!(
                      props.errors.value_discharge &&
                      props.touched.value_discharge
                    )
                  }
                />

                {props.errors.value_discharge &&
                  props.touched.value_discharge && (
                    <div style={{ color: 'red' }}>
                      {props.errors.value_discharge}
                    </div>
                  )}
              </div>

              <div>

              <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="equipmentUuid"
                >
                  VEÍCULO
                </InputLabel>

              <ReactSelect
                  styles={SelectStyled}
                  id="equipmentUuid"
                  name="equipmentUuid"
                  value={props.values.equipmentUuid.uuid}
                  error={props.errors.equipmentUuid}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption => {
                    props.setFieldValue('equipmentUuid', selectedOption.uuid);
                  }}
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => item.code}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando Veiculos'}
                  noOptionsMessage={() => 'Não há Veiculos cadastrados'}
                />
              </div>

              <div>

              <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="employeeUuid"
                >
                  FUNCIONÁRIO
                </InputLabel>

              <ReactSelect
                  styles={SelectStyled}
                  id="employeeUuid"
                  name="employeeUuid"
                  value={props.values.employeeUuid.uuid}
                  error={props.errors.employeeUuid}
                  placeholder="Selecione um funcionário"
                  onChange={selectedOption => {
                    props.setFieldValue('employeeUuid', selectedOption.uuid)
                  }}
                  isSearchable
                  options={employees}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando Funcionarios'}
                  noOptionsMessage={() => 'Não há Funcionarios cadastrados'}
                />

              </div>

              <div>

              <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="localUuid"
                >
                  LOCAL
                </InputLabel>

              <ReactSelect
                  styles={SelectStyled}
                  id="localUuid"
                  name="localUuid"
                  value={props.values.localUuid.uuid}
                  error={ !!(
                    props.errors.equipmentUuid && props.touched.equipmentUuid
                  )}
                  placeholder="Selecione um local"
                  onChange={selectedOption => {
                    props.setFieldValue('localUuid', selectedOption.uuid);
                  }}
                  isSearchable
                  options={locals}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isLoading={false}
                  loadingMessage={() => 'Buscando Locais'}
                  noOptionsMessage={() => 'Não há Locais cadastrados'}
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                SALVAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>

              {ticket ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onTicketNew}
                    style={{ margin: '10px' }}
                  >
                    NOVA
                  </Button>

                  <button
                    type="button"
                    onClick={e => {
                      onTicketDelete(ticket);
                    }}
                    className="btn excluir"
                    style={{ margin: '10px' }}
                  >
                    Excluir
                  </button>
                </Fragment>
              ) : null}
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

TicketForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TicketForm);
