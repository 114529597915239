import moment from 'moment';

export const DateFormat = 'DD/MM/YYYY';

export const TimeFormat = 'HH:mm';

export const isValidDate = data => {
  return data !== undefined && moment(data).isValid();
};

export const formatDateTimeInput = data => {
  if (isValidDate(data)) {
    return moment(data).format('YYYY-MM-DD');
  }

  return '';
};

export const formatShortDateTime = data => {
  if (isValidDate(data)) {
    return moment(data).format('D/M/YY H:mm');
  }

  return '';
};

export const formatDateTime = data => {
  if (isValidDate(data)) {
    return moment(data).format('DD/MM/YYYY HH:mm');
  }

  return '';
};

export const formatShortDate = data => {
  if (isValidDate(data)) {
    return moment(data)
      .utc()

      .format('DD/MM/YY');
  }

  return '';
};

export const formateDateForInput = data => {
  const formatWebApi = moment.ISO_8601;

  const formatSistema = `${DateFormat} ${TimeFormat}`;

  if (moment(data, [formatSistema, formatWebApi], true).isValid()) {
    return moment(data).format(formatSistema);
  }

  return data;
};

export const getHourFromTime = qtdHora => {
  return parseInt(qtdHora.substring(0, 2));
};

export const getMinuteFromTime = qtdHora => {
  return parseInt(qtdHora.substring(3, 5));
};

export const formatDateWepApi = data => {
  if (isValidDate(data)) {
    return moment(data).format('YYYYMMDDHHmm');
  }

  return '';
};
