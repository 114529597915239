import React from 'react';

import { Formik } from 'formik';

import styled from 'styled-components';

import moment from 'moment';

import PropTypes from 'prop-types';

import { FormStyled } from './styles';

import imgloading from '../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({
  name,
  cpf,
  rg,
  rg_expiration,
  rg_emission,
  cnh,
  cnh_expiration,
  title,
  section,
  zone,
  birth_date,
  phone,
  address,
  type,
  bond,
}) => {
  const errors = {};

  if (!name) errors.name = 'OBRIGATÓRIO';
  if (!rg) errors.rg = 'OBRIGATÓRIO';
  if (!rg_expiration) errors.rg_expiration = 'OBRIGATÓRIO';
  if (!rg_emission) errors.rg_emission = 'OBRIGATÓRIO';
  if (!cpf) errors.cpf = 'OBRIGATÓRIO';
  if (!cnh) errors.cnh = 'OBRIGATÓRIO';
  if (!cnh_expiration) errors.cnh_expiration = 'OBRIGATÓRIO';
  if (bond === 'CONTRATADO' && !title) errors.title = 'OBRIGATÓRIO';
  if (bond === 'CONTRATADO' && !section) errors.section = 'OBRIGATÓRIO';
  if (bond === 'CONTRATADO' && !zone) errors.zone = 'OBRIGATÓRIO';
  if (!birth_date) errors.birth_date = 'OBRIGATÓRIO';
  if (!phone) errors.phone = 'OBRIGATÓRIO';
  if (!address) errors.address = 'OBRIGATÓRIO';
  if (!type) errors.type = 'OBRIGATÓRIO';
  if (!bond) errors.bond = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.employee) {
    await props.onEmployeeCreation({ ...values });
  } else {
    await props.onEmployeeUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const EmployeeForm = props => {
  const { employee, onEmployeeDelete } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: employee && (employee.name || ''),

        cpf: employee && (employee.cpf || ''),

        rg: employee && (employee.rg || ''),

        rg_expiration:
          employee && employee.rg_expiration
            ? moment.utc(employee.rg_expiration).format('YYYY-MM-DD')
            : '',

        rg_emission:
          employee && employee.rg_emission
            ? moment.utc(employee.rg_emission).format('YYYY-MM-DD')
            : '',

        cnh: employee && (employee.cnh || ''),

        cnh_expiration:
          employee && employee.cnh_expiration
            ? moment.utc(employee.cnh_expiration).format('YYYY-MM-DD')
            : '',

        birth_date:
          employee && employee.birth_date
            ? moment.utc(employee.birth_date).format('YYYY-MM-DD')
            : '',

        title: employee && (employee.title || ''),

        section: employee && (employee.section || ''),

        zone: employee && (employee.zone || ''),

        phone: employee && (employee.phone || ''),

        address: employee && (employee.address || ''),

        type: employee && (employee.type || ''),

        bond: employee && (employee.bond || ''),
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleReset,

        handleBlur,

        errors,

        touched,

        dirty,

        isSubmitting,
      }) => (
        <FormStyled>
          <h1>FUNCIONÁRIO</h1>

          <div className="formData">
            <strong>NOME</strong>

            <input
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.name && touched.name && <span>{errors.name}</span>}
          </div>

          <div className="formData">
            <strong>DATA DE NASCIMENTO</strong>

            <input
              id="birth_date"
              name="birth_date"
              type="date"
              value={values.birth_date}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.birth_date && touched.birth_date && (
              <span>{errors.birth_date}</span>
            )}
          </div>

          <div className="formData">
            <strong>TELEFONE</strong>

            <input
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.phone && touched.phone && <span>{errors.phone}</span>}
          </div>

          <div className="formData">
            <strong>ENDEREÇO</strong>

            <input
              id="address"
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.address && touched.address && <span>{errors.address}</span>}
          </div>

          <div className="formData">
            <strong>RG</strong>

            <input
              placeholder="Digite o RG do funcionário..."
              name="rg"
              type="text"
              value={values.rg}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.rg && touched.rg)}
            />

            {errors.rg && touched.rg && <span>{errors.rg}</span>}
          </div>

          <div className="formData">
            <strong>EMISSÃO RG</strong>

            <input
              name="rg_emission"
              type="date"
              value={values.rg_emission}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.rg_emission && touched.rg_emission)}
            />

            {errors.rg_emission && touched.rg_emission && (
              <span>{errors.rg_emission}</span>
            )}
          </div>

          <div className="formData">
            <strong>VALIDADE RG</strong>

            <input
              name="rg_expiration"
              type="date"
              value={values.rg_expiration}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.rg_expiration && touched.rg_expiration)}
            />

            {errors.rg_expiration && touched.rg_expiration && (
              <span>{errors.rg_expiration}</span>
            )}
          </div>

          <div className="formData">
            <strong>CPF</strong>

            <input
              id="cpf"
              name="cpf"
              value={values.cpf}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.cpf && touched.cpf && <span>{errors.cpf}</span>}
          </div>

          <div className="formData">
            <strong>CNH</strong>

            <input
              id="cnh"
              name="cnh"
              value={values.cnh}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.cnh && touched.cnh && <span>{errors.cnh}</span>}
          </div>

          <div className="formData">
            <strong>VALIDADE DE CNH</strong>

            <input
              id="cnh_expiration"
              name="cnh_expiration"
              type="date"
              value={values.cnh_expiration}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.cnh_expiration && touched.cnh_expiration && (
              <span>{errors.cnh_expiration}</span>
            )}
          </div>

          <div className="formData">
            <strong>TÍTULO</strong>

            <input
              placeholder="Digite o Título do funcionário..."
              name="title"
              type="text"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.title && touched.title)}
            />

            {errors.title && touched.title && <span>{errors.title}</span>}
          </div>

          <div className="formData">
            <strong>SEÇÃO</strong>

            <input
              placeholder="Digite o Seção do funcionário..."
              name="section"
              type="text"
              value={values.section}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.section && touched.section)}
            />

            {errors.section && touched.section && <span>{errors.section}</span>}
          </div>

          <div className="formData">
            <strong>ZONA</strong>

            <input
              placeholder="Digite o Zona do funcionário..."
              name="zone"
              type="text"
              value={values.zone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.zone && touched.zone)}
            />

            {errors.zone && touched.zone && <span>{errors.zone}</span>}
          </div>

          <div className="formData">
            <strong>VÍNCULO</strong>

            <select
              name="bond"
              value={values.bond}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.bond && touched.bond)}
            >
              <>
                <option value="" />
                <option value="CONTRATADO">CONTRATADO</option>
                <option value="TERCEIRIZADO">TERCEIRIZADO</option>
              </>
            </select>

            {errors.bond && touched.bond && <span>{errors.bond}</span>}
          </div>

          <div className="formData">
            <strong>EMPRESA</strong>

            <select
              id="type"
              name="type"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" />

              <option value="AEB">AEB</option>

              <option value="NSA">NSA</option>
            </select>

            {errors.type && touched.type && <span>{errors.type}</span>}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>

          {employee && (
            <button
              type="button"
              onClick={() => {
                onEmployeeDelete(employee.uuid);
              }}
              className="delete"
              style={{ margin: '10px' }}
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

EmployeeForm.propTypes = {
  employee: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    cpf: PropTypes.string,
    rg: PropTypes.string,
    rg_expiration: PropTypes.string,
    rg_emission: PropTypes.string,
    cnh: PropTypes.string,
    cnh_expiration: PropTypes.string,
    title: PropTypes.string,
    section: PropTypes.string,
    zone: PropTypes.string,
    birth_date: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    type: PropTypes.string,
    bond: PropTypes.string,
  }),

  onEmployeeDelete: PropTypes.func.isRequired,
};

EmployeeForm.defaultProps = {
  employee: null,
};

export default EmployeeForm;
