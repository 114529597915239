import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

import SelectMUI from '@material-ui/core/Select';

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit * 1,

    width: '40%',

    textAlign: 'center',
  },

  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },
});

const handleValidate = ({
  start_date,

  start_time,

  end_date,

  end_time,

  employee,
}) => {
  const errors = {};

  if (!employee) errors.employee = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.maintenanceEmployee && props.maintenanceEmployee.uuid) {
    await props.onMaintenanceEmployeeUpdate({ ...values });
  } else {
    await props.onMaintenanceEmployeeCreation({ ...values });
  }

  setSubmitting(false);
};

const MaintenanceEmployeeForm = props => {
  const { classes, employees } = props;

  const onMaintenanceEmployeeNew = () => props.onMaintenanceEmployeeNew();

  const { maintenanceEmployee } = props;

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          start_date:
            props.maintenanceEmployee && props.maintenanceEmployee.start
              ? props.maintenanceEmployee.start.substring(0, 10)
              : new Date().toISOString().substring(0, 10),

          start_time:
            props.maintenanceEmployee && props.maintenanceEmployee.start
              ? props.maintenanceEmployee.start.substring(11, 16)
              : '',

          end_date:
            props.maintenanceEmployee && props.maintenanceEmployee.end
              ? props.maintenanceEmployee.end.substring(0, 10)
              : new Date().toISOString().substring(0, 10),

          end_time:
            props.maintenanceEmployee && props.maintenanceEmployee.end
              ? props.maintenanceEmployee.end.substring(11, 16)
              : '',

          employee: props.maintenanceEmployee
            ? props.maintenanceEmployee.employee.uuid
            : props.employee
            ? props.employee.uuid
            : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {props => (
          <Form>
            <Paper className={classes.root}>
              <h1>FUNCIONÁRIO DA MANUTENÇÃO</h1>

              <div className={classes.formControl}>
                <div>
                  <InputLabel
                    style={{ disableAnimation: false }}
                    disableAnimation={false}
                    htmlFor="employee"
                  >
                    FUNCIONÁRIO
                  </InputLabel>
                </div>

                <SelectMUI
                  id="employee"
                  name="employee"
                  value={props.values.employee}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  inputProps={{
                    name: 'employee',

                    id: 'employee',
                  }}
                  style={{
                    width: '90%',

                    margin: '10px',

                    marginBottom: '20px',
                  }}
                  error={!!(props.errors.employee && props.touched.employee)}
                >
                  {employees &&
                    employees.length > 0 &&
                    employees.map(employee => (
                      <MenuItem value={employee.uuid}>{employee.name}</MenuItem>
                    ))}
                </SelectMUI>
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.isSubmitting}
                style={{ margin: '10px' }}
              >
                SALVAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>

              {maintenanceEmployee ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onMaintenanceEmployeeNew}
                  style={{ margin: '10px' }}
                >
                  LIMPAR
                </Button>
              ) : null}
            </Paper>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

MaintenanceEmployeeForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaintenanceEmployeeForm);
