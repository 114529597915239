const ReactSelectStyle = {
  control: base => ({
    ...base,
    height: 60,
    minHeight: 40,
    border: 0,
    borderRadius: 0,
    borderBottom: '1px solid #333',
    marginTop: 10,
    fontSize: 16,
    color: '#333',
    padding: 'auto 15px',
  }),

  container: provided => ({
    ...provided,

    width: '100%',
  }),

  valueContainer: provided => ({
    ...provided,
  }),

  input: provided => ({
    ...provided,
  }),

  placeholder: provided => ({
    ...provided,

    color: '#999',
  }),

  option: (provided, state) => ({
    ...provided,
    background: '#fff',
    color: state.isSelected ? '#333' : '#999',
    fontSize: 14,
    padding: 10,
  }),
};

export default ReactSelectStyle;
