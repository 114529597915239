import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import ReactSelect from '../components/ReactSelect';
import SelectStyled from '../../styles/select';

import { FormStyled } from '../../styles/form';

const handleValidate = ({ name }) => {
  const errors = {};

  if (!name) errors.name = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.local) {
    await props.onLocalCreation({ ...values });
  } else {
    await props.onLocalUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const LocalForm = props => {
  const { locals, local, onLocalEdit, onLocalDelete, onLocalRefresh } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: local ? local.name : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleReset,
          isSubmitting,
        }) => (
          <FormStyled>
            <h1>{local ? 'EDITAR LOCAL' : 'NOVO LOCAL'}</h1>

            <div className="formData">
              <strong>
                {local ? 'EDITAR NOME DO LOCAL' : 'CADASTRAR NOVO LOCAL'}
              </strong>

              <input
                placeholder="Digite o nome do local..."
                name="name"
                type="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.name && touched.name)}
              />

              {errors.name && touched.name && <span>{errors.name}</span>}
            </div>

            <button type="submit" disabled={!dirty || isSubmitting}>
              {local ? 'ALTERAR' : 'CADASTRAR'}
            </button>

            <button
              type="button"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              DESFAZER
            </button>

            {local && (
              <button type="button" onClick={onLocalRefresh}>
                LIMPAR
              </button>
            )}

            {local && !local.origin[0] && !local.destiny[0] && (
              <button type="button" onClick={onLocalDelete} className="delete">
                EXCLUIR
              </button>
            )}

            <div className="division">
              <hr />
              <p>OU</p>
              <hr />
            </div>

            <ReactSelect
              styles={SelectStyled}
              placeholder="Escolher Local existente"
              options={locals}
              onSetValue={onLocalEdit}
            />
          </FormStyled>
        )}
      </Formik>
    </div>
  );
};

LocalForm.propTypes = {
  locals: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  local: PropTypes.shape({
    name: PropTypes.string,
    origin: PropTypes.string,
    destiny: PropTypes.string,
  }),
  onLocalEdit: PropTypes.func.isRequired,
  onLocalDelete: PropTypes.func.isRequired,
  onLocalRefresh: PropTypes.func.isRequired,
};

LocalForm.defaultProps = {
  locals: [],
  local: null,
};

export default LocalForm;
