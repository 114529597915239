import styled from 'styled-components';

export const ListStyled = styled.ul`
  width: 100%;

  max-width: 900px;

  box-sizing: border-box;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  cursor: pointer;

  margin: 32px 0;

  header {
    width: 100%;

    display: flex;

    flex-direction: column;

    align-items: flex-start;

    justify-content: flex-start;

    div.total {
      width: 100%;

      display: flex;

      align-items: center;

      justify-content: flex-start;

      h1 {
        font-size: 20px;

        color: #011826;

        margin-right: 8px;
      }

      p {
        color: #333;

        font-size: 20px;
      }
    }

    div.titles {
      width: 100%;

      display: grid;

      grid-template-columns: ${props => props.gridHeader};

      column-gap: 10px;

      align-items: center;

      text-align: center;

      margin-bottom: 16px;

      strong {
        font-size: 14px;

        color: #011826;

        &.name {
          width: 200px;
        }
      }
    }
  }
`;

export const ListItem = styled.li`
  width: 100%;

  height: 50px;

  display: grid;

  grid-template-columns: ${props => props.gridList};

  column-gap: 10px;

  align-items: center;

  padding: 0 32px;

  border-radius: 10px;

  box-sizing: border-box;

  text-align: center;

  background: ${props => props.color};

  border: 2px solid #ddd;

  & + li {
    margin-top: 16px;
  }

  &:hover {
    background: #fff;

    border: 2px solid #333;

    strong {
      color: #333;
    }
  }

  strong {
    font-size: 14px;

    color: ${props => props.strongColor};
  }

  button {
    background: none;

    border: 0;

    border-left: 1px solid #ddd;

    padding-left: 16px;

    margin-left: 16px;

    &:disabled {
      cursor: default;
    }
  }

  a {
    cursor: pointer;

    width: 100%;

    height: 50px;

    display: flex;

    align-items: center;

    justify-content: space-between;

    text-decoration: none;
  }
`;

export const SubListStyled = styled.div`
  width: 100%;

  min-width: 100%;

  display: ${props => (props.visible ? 'grid' : 'none')};

  flex: 1;

  grid-template-columns: repeat(3, 1fr);

  grid-gap: 32px;

  margin: 16px 0;

  padding-bottom: 32px;

  border-bottom: 2px solid #0568a6;

  strong {
    font-size: 14px;

    color: #0568a6;

    padding: 8px 0 8px 8px;

    border-left: 1px solid #666;
  }
`;

export const Legend = styled.ul`
  display: flex;

  align-items: center;

  justify-content: center;

  li {
    display: flex;

    align-items: center;

    justify-content: center;

    & + li {
      margin-left: 16px;
    }

    p {
      margin-left: 8px;
    }
  }
`;

export const LegendItem = styled.div`
  width: 14px;

  height: 14px;

  border-radius: 7px;

  background: ${props => props.color};
`;
