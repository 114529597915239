import React from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormStyled } from './styles';
import imgloading from '../../img/load.gif';
import ReactSelect from '../components/ReactSelect';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ company, execution_at, employee }) => {
  const errors = {};

  if (!employee) errors.employee = 'OBRIGATÓRIO';
  if (!company) errors.company = 'OBRIGATÓRIO';
  if (!execution_at) errors.execution_at = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.asu) {
    await props.onAsuCreation({ ...values });
  } else {
    await props.onAsuUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const AsuForm = props => {
  const { asu, employees, onAsuDelete } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        company: (asu && asu.company) || '',
        execution_at:
          asu && asu.execution_at
            ? moment(asu.execution_at).format('YYYY-MM-DD')
            : '',
        employee: (asu && asu.employee) || '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleReset,

        handleBlur,

        errors,

        touched,

        dirty,

        isSubmitting,

        setFieldValue,
      }) => (
        <FormStyled>
          <h1>ASU</h1>

          <div className="formData">
            <strong>FUNCIONÁRIO</strong>

            <ReactSelect
              id="employee"
              name="employee"
              placeholder="Escolher funcionário"
              options={employees}
              value={values.employee}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.uuid}
              onChange={e => setFieldValue('employee', e)}
              onBlur={handleBlur}
            />

            {errors.employee && touched.employee && (
              <span>{errors.employee}</span>
            )}
          </div>

          <div className="formData">
            <strong>EMPRESA</strong>

            <input
              id="company"
              name="company"
              value={values.company}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.company && touched.company && <span>{errors.company}</span>}
          </div>

          <div className="formData">
            <strong>DATA DE EXECUÇÃO</strong>

            <input
              id="execution_at"
              name="execution_at"
              type="date"
              value={values.execution_at}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.execution_at && touched.execution_at && (
              <span>{errors.execution_at}</span>
            )}
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>

          {asu && (
            <button
              type="button"
              onClick={() => {
                onAsuDelete(asu.uuid);
              }}
              className="delete"
              style={{ margin: '10px' }}
            >
              EXCLUIR
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

AsuForm.propTypes = {
  asu: PropTypes.shape({
    uuid: PropTypes.string,
    company: PropTypes.string,
    execution_at: PropTypes.string,
    employee: PropTypes.shape({}),
  }),

  employees: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),

  onAsuDelete: PropTypes.func.isRequired,
};

AsuForm.defaultProps = {
  asu: null,
  employees: [],
};

export default AsuForm;
