/* eslint-disable no-nested-ternary */

import React, { Fragment } from 'react';

import { MdDelete } from 'react-icons/md';

import { ListStyled, ListItem } from '../../css/Styles/list-grid';

import Loading from '../components/Loading';

const listItem = props =>
  props.inventories.map(object => (
    <ListItem
      gridList="2fr 2fr 4fr 2fr 0.5fr"
      color="#ddd"
      strongColor="#333"
      key={object.uuid}
    >
      <strong>
        {new Date(object.createdAt).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })}
      </strong>

      <strong>
        {object.piece_inventory_history
          ? object.piece_inventory_history.document
          : '-'}
      </strong>

      <strong>
        {object.piece
          ? `
            ${object.piece.description.substring(0, 18)}
            ${object.piece.description.length >= 18 ? '...' : ''}
          `
          : '-'}
      </strong>

      <strong>{object.quantity || '-'}</strong>

      <button
        type="button"
        onClick={() => props.onInventoryDelete(object.uuid)}
      >
        <MdDelete color="#ff5757" size={20} />
      </button>
    </ListItem>
  ));

function PieceIventoryList(props) {
  return (
    <Fragment>
      {!props.loadingList ? (
        props.inventories.length > 0 ? (
          <ListStyled gridHeader="2fr 2fr 4fr 2fr 0.5fr">
            <header>
              <div className="titles">
                <strong>DATA</strong>

                <strong>DOC</strong>

                <strong>PEÇA</strong>

                <strong>QTD</strong>

                <strong />
              </div>
            </header>

            {listItem(props)}
          </ListStyled>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}

      <hr style={{ width: '90%', marginTop: '20px' }} />
    </Fragment>
  );
}

export default PieceIventoryList;
