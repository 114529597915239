import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

import SelectMUI from '@material-ui/core/Select';

import Moment from 'moment';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },

  container: {
    display: 'flex',

    flexWrap: 'wrap',
  },

  textField: {
    margin: theme.spacing.unit * 0.5,

    marginBottom: theme.spacing.unit * 3,

    width: '45%',

    textAlign: 'center',
  },

  textFieldNumber: {
    margin: theme.spacing.unit * 0.5,

    width: '30%',

    textAlign: 'center',
  },

  rootSelect: {
    display: 'flex',

    flexWrap: 'wrap',

    width: '90%',
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const handleValidate = props => ({
  equipment,
}) => {
  const errors = {};

  if (!equipment) errors.equipment = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.calibration) {
    await props.onCalibrationCreate({ ...values });
    setSubmitting(true);
  } else {
    await props.onMaintenanceUpdate({ ...values });
  }

  resetForm();
  setSubmitting(false);
};

const CalibrationForm = props => {
  const { classes, equipments,  } = props;

  const hora_atual = Moment().format('HH:mm');

  const { calibration } = props;

  console.log(props, 'props')
  return (
    <div>
      <Paper className={classes.root}>
          <h1>Nova Calibragem</h1>
        

        <Formik
          enableReinitialize
          initialValues={{

            createdAT: !props.calibration
              ? new Date().toISOString().substring(0, 10)
              : props.calibration.createdAT.substring(0, 10),

            time: !props.calibration
              ? hora_atual
              : props.calibration.createdAT.substring(11, 16),

            equipment:
              props.calibration && props.calibration.equipment
                ? props.calibration.equipment.uuid
                : props.equipment
                ? props.equipment.uuid
                : '',
          }}
          validate={handleValidate(props)}
          onSubmit={handleSubmit(props)}
        >
          {props => (
            <Form>
              <div>
                <TextField
                  id="createdAT"
                  name="createdAT"
                  label="Data"
                  type="date"
                  variant="outlined"
                  disabled
                  value={props.values.createdAT}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  className={classes.textField}
                  error={
                    !!(props.errors.createdAT && props.touched.createdAT)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="time"
                  name="time"
                  variant="outlined"
                  disabled
                  value={props.values.time}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Hora"
                  type="time"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 1800, // 30 min
                  }}
                  error={!!(props.errors.time && props.touched.time)}
                />
              </div>

              <div className={classes.formControl}>
                <InputLabel
                  style={{ disableAnimation: false }}
                  disableAnimation={false}
                  htmlFor="equipment"
                >
                  SELECIONE O VEÍCULO
                </InputLabel>

                <SelectMUI
                  id="equipment"
                  name="equipment"
                  value={props.values.equipment}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  inputProps={{
                    name: 'equipment',

                    id: 'equipment',
                  }}
                  style={{
                    width: '90%',

                    margin: '10px',

                    marginBottom: '20px',
                  }}
                  error={!!(props.errors.equipment && props.touched.equipment)}
                >
                  {equipments &&
                    equipments.length > 0 &&
                    equipments.map(equipment => (
                      <MenuItem value={equipment.uuid}>
                        {`${equipment.code} | ${equipment.type}`}
                      </MenuItem>
                    ))}
                </SelectMUI>
              </div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!props.dirty || props.isSubmitting }
                style={{ margin: '10px' }}
              >
                {props.isSubmitting ? 'Enviando...' : 'SALVAR'}
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{ margin: '10px' }}
              >
                RESET
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

CalibrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CalibrationForm);
