import React from 'react';

import { Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },
});

const handleValidate = ({ description, total }) => {
  const errors = {};

  if (!description) errors.description = 'OBRIGATÓRIO';
  if (!total && total !== 0) errors.total = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onPieceCreation({ ...values });

  setSubmitting(false);

  resetForm();
};

const PieceForm = props => {
  const { classes } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          description: '',
          quantity: 0,
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {props => (
          <Form>
            <Paper className={classes.root}>
              <h1>NOVA PEÇA</h1>

              <div>
                <TextField
                  label="Descrição"
                  placeholder="Digite o descrição da peça..."
                  margin="normal"
                  variant="outlined"
                  id="description"
                  name="description"
                  type="description"
                  value={props.values.description}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  style={{
                    width: '90%',

                    textAlign: 'left',
                  }}
                  error={
                    !!(props.errors.description && props.touched.description)
                  }
                />

                {props.errors.description && props.touched.description && (
                  <div style={{ color: 'red' }}>{props.errors.description}</div>
                )}
              </div>

              <div>
                <TextField
                  label="Quantidade"
                  placeholder="Digite a quantiade de peças..."
                  margin="normal"
                  variant="outlined"
                  id="total"
                  name="total"
                  type="number"
                  value={props.values.total}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  style={{
                    width: '90%',

                    textAlign: 'left',
                  }}
                  error={!!(props.errors.total && props.touched.total)}
                />

                {props.errors.total && props.touched.total && (
                  <div style={{ color: 'red' }}>{props.errors.total}</div>
                )}
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.isSubmitting}
                style={{
                  margin: '10px',
                }}
              >
                SALVAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={props.handleReset}
                disabled={!props.dirty || props.isSubmitting}
                style={{
                  margin: '10px',
                }}
              >
                RESET
              </Button>
            </Paper>
          </Form>
        )}
      </Formik>
    </div>
  );
};

PieceForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PieceForm);
