// 52:46  error  Must use destructuring props assignment    react/destructuring-assignment

// 52:57  error  'onLogout' is missing in props validation  react/prop-types
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import iconLogout from '../../../img/icons/icon-logout.png';
import { UserImage } from './styles';

class Menu extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    empresa: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
  };

  btnMenu = () => {
    const menu = document.querySelector('.main-menu');
    const menulink = document.querySelector('.main-menu nav a');
    menu.classList.remove('ativo');
    menulink.classList.remove('ativo');
  };

  render() {
    const { onLogout, username, empresa, photo, team } = this.props;
    const useruuid = localStorage.getItem('USER_UUID');

    const authMaintanance = team === 'GERENCIA' || team === 'OFICINA';
    const authSchedule = team === 'GERENCIA' || team === 'EXPEDICAO';
    const authRefuel = team === 'GERENCIA' || team === 'OFICINA';

    return (
      <div className="main-menu">
        <div className="scrollmenu">
          <div className="content">
            <div className="icon-menu" onClick={this.btnMenu}>
              <span />

              <span />

              <span />
            </div>

            <div className="user">
              <UserImage src={photo} alt="" />

              <p>{username}</p>

              <p>
                <small>{empresa}</small>
              </p>
            </div>

            <nav>
              <Route>
                <Link onClick={this.btnMenu} to="/home">
                  HOME
                </Link>
                {authMaintanance && (
                  <Link onClick={this.btnMenu} to="/maintenances">
                    MANUTENÇÕES
                  </Link>
                )}
                {authRefuel && (
                  <Link onClick={this.btnMenu} to="/refuels">
                    ABASTECIMENTOS
                  </Link>
                )}
                {authSchedule && (
                  <Link onClick={this.btnMenu} to="/schedules">
                    PROGRAMAÇÕES
                  </Link>
                )}
                {authRefuel && (
                  <Link onClick={this.btnMenu} to="/tickets">
                    MULTAS
                  </Link>
                )}
                {authMaintanance && (
                  <Link onClick={this.btnMenu} to="/inventories">
                    ESTOQUE DE PEÇAS
                  </Link>
                )}
                {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
                  useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                  useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                  useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
                  <Link onClick={this.btnMenu} to="/employees">
                    FUNCIONÁRIOS
                  </Link>
                )}

                {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
                  useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                  useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                  useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
                  <Link onClick={this.btnMenu} to="/roles">
                    FUNÇÕES
                  </Link>
                )}

                {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
                  useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                  useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                  useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
                  <Link onClick={this.btnMenu} to="/asus">
                    ASUS
                  </Link>
                )}
              </Route>
            </nav>

            <div className="logout" onClick={onLogout}>
              <p>Logout</p>

              <img src={iconLogout} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
