import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import ReactSelect from '../components/ReactSelect';

import { FormStyled } from '../../styles/form';

const handleValidate = ({ name }) => {
  const errors = {};

  if (!name) errors.name = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.partner) {
    await props.onPartnerCreation({ ...values });
  } else {
    await props.onPartnerUpdate({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const PartnerForm = props => {
  const {
    partners,
    partner,
    onPartnerEdit,
    onPartnerDelete,
    onPartnerRefresh,
  } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: partner ? partner.name : '',
          cnpj: partner ? partner.cnpj : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleReset,
          isSubmitting,
        }) => (
          <FormStyled>
            <h1>{partner ? 'EDITAR CLIENTE' : 'NOVO CLIENTE'}</h1>

            <div className="formData">
              <strong>
                {partner ? 'EDITAR NOME DO CLIENTE' : 'CADASTRAR NOVO CLIENTE'}
              </strong>

              <input
                placeholder="Digite o nome do cliente..."
                name="name"
                type="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.name && touched.name)}
              />

              {errors.name && touched.name && <span>{errors.name}</span>}
            </div>

            <div className="formData">
              <strong>
                {partner ? 'EDITAR CNPJ/CPF DO CLIENTE ' : 'CNPJ/CPF'}
              </strong>

              <input
                placeholder="Digite o CNPJ/CPF do cliente..."
                id="cnpj"
                name="cnpj"
                value={values.cnpj}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.cnpj && touched.cnpj)}
              />

              {errors.cnpj && touched.cnpj && <span>{errors.cnpj}</span>}
            </div>

            <button type="submit" disabled={!dirty || isSubmitting}>
              {partner ? 'ALTERAR' : 'CADASTRAR'}
            </button>

            <button
              type="button"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              DESFAZER
            </button>

            {partner && (
              <button type="button" onClick={onPartnerRefresh}>
                LIMPAR
              </button>
            )}

            {partner && !partner.schedules[0] && (
              <button
                type="button"
                onClick={onPartnerDelete}
                className="delete"
              >
                EXCLUIR
              </button>
            )}

            <div className="division">
              <hr />
              <p>OU</p>
              <hr />
            </div>

            <ReactSelect
              placeholder="Escolher cliente existente"
              options={partners}
              onSetValue={onPartnerEdit}
            />
          </FormStyled>
        )}
      </Formik>
    </div>
  );
};

PartnerForm.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  partner: PropTypes.shape({
    name: PropTypes.string,
    cnpj: PropTypes.string,
    schedules: PropTypes.arrayOf(),
  }),
  onPartnerEdit: PropTypes.func.isRequired,
  onPartnerDelete: PropTypes.func.isRequired,
  onPartnerRefresh: PropTypes.func.isRequired,
};

PartnerForm.defaultProps = {
  partners: [],
  partner: null,
};

export default PartnerForm;
