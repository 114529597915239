import React, { Component } from 'react';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { FormStyled } from '../../styles/form';
import SelectStyled from '../../styles/select';

class PieceInventoryFilterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPiece: null,
    };
  }

  handleValidate = () => {
    const errors = {};

    return errors;
  };

  handleSubmit = () => async (values, { setSubmitting }) => {
    const { onList } = this.props;
    const { selectedPiece } = this.state;

    const data = {
      ...values,
      piece: selectedPiece ? selectedPiece.value : null,
    };

    if (values || selectedPiece) {
      await onList({ ...data });
    }

    setSubmitting(false);
  };

  render() {
    const { pieces } = this.props;

    const { selectedPiece } = this.state;

    const pieceOptions = pieces
      ? pieces.map(piece => ({
          value: piece.uuid,
          label: piece.description,
        }))
      : [];

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            piece: selectedPiece,
            document: '',
          }}
          validate={this.handleValidate}
          onSubmit={this.handleSubmit(this.props)}
        >
          {({
            dirty,
            handleReset,
            isSubmitting,
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <FormStyled>
              <h1>Filtros</h1>

              <div className="formData">
                <strong>DOCUMENTO</strong>
                <input
                  id="document"
                  name="document"
                  type="text"
                  value={values.document}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(errors.document && touched.document)}
                />
                {errors.document && touched.document && (
                  <span>{errors.document}</span>
                )}
              </div>

              <ReactSelect
                placeholder="PEÇAS"
                isDisabled={!pieces}
                styles={SelectStyled}
                value={selectedPiece}
                onChange={e => {
                  this.setState({ selectedPiece: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={pieceOptions}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                style={{ margin: '10px' }}
              >
                BUSCAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="reset"
                onClick={() => {
                  handleReset();
                  this.setState({
                    selectedPiece: null,
                  });
                }}
                disabled={(!dirty && !selectedPiece) || isSubmitting}
                style={{ margin: '10px' }}
              >
                DESFAZER
              </Button>
            </FormStyled>
          )}
        </Formik>
      </div>
    );
  }
}

PieceInventoryFilterForm.propTypes = {
  onList: PropTypes.func.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
};

PieceInventoryFilterForm.defaultProps = {
  pieces: [],
};

export default PieceInventoryFilterForm;
