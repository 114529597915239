import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import ReactSelect from '../components/ReactSelect';

import { FormStyled } from '../../styles/form';

const handleValidate = ({ name }) => {
  const errors = {};

  if (!name) errors.name = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.material) {
    await props.onMaterialCreation({ ...values });
  } else {
    await props.onMaterialUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const MaterialForm = props => {
  const {
    materials,
    material,
    onMaterialEdit,
    onMaterialDelete,
    onMaterialRefresh,
  } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: material ? material.name : '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleReset,
          isSubmitting,
        }) => (
          <FormStyled>
            <h1>{material ? 'EDITAR MATERIAL' : 'NOVO MATERIAL'}</h1>

            <div className="formData">
              <strong>
                {material
                  ? 'EDITAR NOME DO MATERIAL'
                  : 'CADASTRAR NOVO MATERIAL'}
              </strong>

              <input
                placeholder="Digite o nome do material..."
                name="name"
                type="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.name && touched.name)}
              />

              {errors.name && touched.name && <span>{errors.name}</span>}
            </div>

            <button type="submit" disabled={!dirty || isSubmitting}>
              {material ? 'ALTERAR' : 'CADASTRAR'}
            </button>

            <button
              type="button"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              DESFAZER
            </button>

            {material && (
              <button type="button" onClick={onMaterialRefresh}>
                LIMPAR
              </button>
            )}

            {material && (
              <button
                type="button"
                onClick={onMaterialDelete}
                className="delete"
              >
                EXCLUIR
              </button>
            )}

            <div className="division">
              <hr />
              <p>OU</p>
              <hr />
            </div>

            <ReactSelect
              placeholder="Escolher material existente"
              options={materials}
              onSetValue={onMaterialEdit}
            />
          </FormStyled>
        )}
      </Formik>
    </div>
  );
};

MaterialForm.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  material: PropTypes.shape({
    name: PropTypes.string,
  }),
  onMaterialEdit: PropTypes.func.isRequired,
  onMaterialDelete: PropTypes.func.isRequired,
  onMaterialRefresh: PropTypes.func.isRequired,
};

MaterialForm.defaultProps = {
  materials: [],
  material: null,
};

export default MaterialForm;
