import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { FormStyled } from '../../styles/form';
import SelectStyled from '../../styles/select';

class ScheduleFilterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPartner: null,
      selectedMaterial: null,
      selectedOrigin: null,
      selectedDestiny: null,
    };
  }

  handleValidate = ({ date, date2 }) => {
    const errors = {};

    if (!date) errors.date = 'OBRIGATÓRIO';

    if (!date2) errors.date2 = 'OBRIGATÓRIO';

    return errors;
  };

  handleSubmit = () => async (values, { setSubmitting }) => {
    const { onList } = this.props;
    const {
      selectedPartner,
      selectedMaterial,
      selectedOrigin,
      selectedDestiny,
    } = this.state;

    const data = {
      ...values,
      partner: selectedPartner ? selectedPartner.value : null,
      material: selectedMaterial ? selectedMaterial.value : null,
      origin: selectedOrigin ? selectedOrigin.value : null,
      destiny: selectedDestiny ? selectedDestiny.value : null,
    };

    await onList({ ...data });

    setSubmitting(false);
  };

  render() {
    const { partners, locals, materials } = this.props;

    const {
      selectedPartner,
      selectedMaterial,
      selectedOrigin,
      selectedDestiny,
    } = this.state;

    const partnerOptions = partners
      ? partners.map(partner => ({
          value: partner.uuid,
          label: partner.name,
        }))
      : [];
    const localOptions = locals
      ? locals.map(local => ({
          value: local.uuid,
          label: local.name,
        }))
      : [];
    const materialOptions = materials
      ? materials.map(material => ({
          value: material.uuid,
          label: material.name,
        }))
      : [];

    return (
      <div>
        <Formik
          enableReinitialize={false}
          initialValues={{
            date: new Date().toISOString().substring(0, 10),
            date2: new Date().toISOString().substring(0, 10),
            partner: selectedPartner,
            material: selectedMaterial,
            origin: selectedOrigin,
            destiny: selectedDestiny,
          }}
          validate={this.handleValidate}
          onSubmit={this.handleSubmit(this.props)}
        >
          {({
            values,
            dirty,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleReset,
            isSubmitting,
          }) => (
            <FormStyled>
              <h1>Filtros</h1>

              <div className="line">
                <div className="formData">
                  <strong>DATA INICIAL</strong>
                  <input
                    id="date"
                    name="date"
                    type="date"
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.date && touched.date)}
                  />

                  {errors.date && touched.date && <span>{errors.date}</span>}
                </div>

                <div className="formData">
                  <strong>DATA FINAL</strong>
                  <input
                    id="date2"
                    name="date2"
                    type="date"
                    value={values.date2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!(errors.date2 && touched.date2)}
                  />

                  {errors.date2 && touched.date2 && <span>{errors.date2}</span>}
                </div>
              </div>

              <ReactSelect
                placeholder="CLIENTE"
                isDisabled={!partners}
                styles={SelectStyled}
                value={selectedPartner}
                onChange={e => {
                  this.setState({ selectedPartner: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={partnerOptions}
              />

              <ReactSelect
                placeholder="MATERIAL"
                isDisabled={!materials}
                styles={SelectStyled}
                value={selectedMaterial}
                onChange={e => {
                  this.setState({ selectedMaterial: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={materialOptions}
              />

              <ReactSelect
                placeholder="ORIGEM"
                isDisabled={!locals}
                styles={SelectStyled}
                value={selectedOrigin}
                onChange={e => {
                  this.setState({ selectedOrigin: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={localOptions}
              />

              <ReactSelect
                placeholder="DESTINO"
                isDisabled={!locals}
                styles={SelectStyled}
                value={selectedDestiny}
                onChange={e => {
                  this.setState({ selectedDestiny: e });
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                options={localOptions}
              />

              <button type="submit" disabled={isSubmitting}>
                BUSCAR
              </button>

              <button
                type="button"
                onClick={() => {
                  handleReset();
                  this.setState({
                    selectedPartner: null,
                    selectedMaterial: null,
                    selectedOrigin: null,
                    selectedDestiny: null,
                  });
                }}
                disabled={
                  (!dirty &&
                    (!selectedPartner &&
                      !selectedMaterial &&
                      !selectedOrigin &&
                      !selectedDestiny)) ||
                  isSubmitting
                }
              >
                DESFAZER
              </button>
            </FormStyled>
          )}
        </Formik>
      </div>
    );
  }
}

ScheduleFilterForm.propTypes = {
  onList: PropTypes.func.isRequired,
  partners: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  locals: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  materials: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
};

ScheduleFilterForm.defaultProps = {
  partners: [],
  locals: [],
  materials: [],
};

export default ScheduleFilterForm;
