import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import io from 'socket.io-client';
import swal from '@sweetalert/with-react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMUI from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { PDFDownloadLink } from '@react-pdf/renderer';

import MaintenanceForm from './MaintenanceForm';
import MaintenanceCloseForm from './MaintenanceCloseForm';
import MaintenanceList from './MaintenanceList';
import EquipmentContainer from '../Equipment/EquipmentContainer';
import MaintenancePieceContainer from './MaintenancePiece/MaintenancePieceContainer';
import MaintenanceEmployeeContainer from './MaintenanceEmployee/MaintenanceEmployeeContainer';
import { MaintenancePDF, OilChangePDF, CalibrationPDF } from './MaintenanceDocument';
import SnackBar from '../../Utilities/SnackBar';
import API from '../../services/api';
import Parameters from '../../services/parameters';
import CalibrationContainer from '../Calibration/CalibrationContainer';

const styles = theme => ({
  root: {
    width: '90%',

    margin: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 2,

    overflowX: 'auto',
  },
});

class MaintenanceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenances: [],

      maintenance: null,

      loadingList: true,

      filtro: '',

      equipmentForm: false,

      equipment: null,

      calibrationForm: false,

      calibration: null,

      equipments: [{ uuid: null, code: 'CARREGANDO' }],

      snackBarOpen: false,

      closedList: false,
    };

    // MAITENANCE BIND

    this.handleMaintenanceCreation = this.handleMaintenanceCreation.bind(this);

    this.handleMaintenanceUpdate = this.handleMaintenanceUpdate.bind(this);

    this.handleMaintenanceClose = this.handleMaintenanceClose.bind(this);

    this.handleMaintenanceEdit = this.handleMaintenanceEdit.bind(this);

    this.handleMaintenanceGet = this.handleMaintenanceGet.bind(this);

    this.handleMaintenanceDelete = this.handleMaintenanceDelete.bind(this);

    // EQUIPMENT BIND

    this.handleEquipmentsList = this.handleEquipmentsList.bind(this);

    this.handleEquipmentFormState = this.handleEquipmentFormState.bind(this);

    // CALIBRATION FORM

    this.handleCalibrationFormState = this.handleCalibrationFormState.bind(this);

    // MAINTENANCEPIECE BIND

    this.handleMaintenancePiecesList = this.handleMaintenancePiecesList.bind(
      this
    );

    this.handleMaintenancePieceFormState = this.handleMaintenancePieceFormState.bind(
      this
    );

    // MAINTENANCEEMPLOYYE BIND

    this.handleMaintenanceEmployeesList = this.handleMaintenanceEmployeesList.bind(
      this
    );

    this.handleMaintenanceEmployeeFormState = this.handleMaintenanceEmployeeFormState.bind(
      this
    );
  }

  refMaintenance = React.createRef();

  componentDidMount() {
    this.registerToSocket();

    this.getTodosList();

    this.getEquipmentsList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('maintenance', () => this.getTodosList());
  };

  handleMaintenanceCreation = async maintenance => {
    const data = {
      opening_description: maintenance.opening_description,

      opening_date: `${maintenance.opening_date}T${maintenance.time}`,

      type: maintenance.type,

      observation: maintenance.observation,

      equipmentUuid: maintenance.equipment,

      situation: maintenance.situation,

      km: maintenance.km ? maintenance.km : 0,

      oil_change: maintenance.oil_change ? maintenance.oil_change : false,

      // timer01: maintenance.timer01,

      // timer02: maintenance.timer02 ? maintenance.timer02 : null,

      status: 'ABERTA',
    };

    try {
      const response = await API.post(`maintenances`, { ...data });

      this.handleMaintenanceEdit(response.data);

      await this.getTodosList();

      this.getEquipmentsList();

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceUpdate = async object => {
    const { maintenance } = this.state;

    const data = {
      opening_description: object.opening_description.toUpperCase(),

      opening_date: `${object.opening_date}T${object.time}`,

      type: object.type,

      observation: object.observation.toUpperCase(),

      situation: object.situation,

      km: object.km ? object.km : 0,

      oil_change: object.oil_change ? object.oil_change : false,

      // timer01: maintenance.timer01,

      // timer02: maintenance.timer02 ? maintenance.timer02 : null,

      // equipmentUuid: maintenance.equipment.value,
    };

    try {
      const response = await API.put(`maintenances/${maintenance.uuid}`, {
        ...data,
      });

      this.handleMaintenanceEdit(response.data);

      await this.getTodosList();

      this.getEquipmentsList();

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleCalibrationCreation = async calibration => {
    const data = {
       equipmentUuid: calibration.equipment,
    };

    try {
      const response = await API.post(`calibrations`, { ...data });

      this.getEquipmentsList()

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  getEquipmentsList = async () => {
    try {
      const response = await API.get(`equipments`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        equipments: response.data,
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceDelete = async maintenance => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da manutenção?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`maintenances/${maintenance.uuid}`);

        this.setState({ snackBarOpen: true });

        this.handleMaintenanceNew();

        await this.getTodosList();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceClose = async object => {
    const { maintenance } = this.state;

    const data = {
      closing_description: object.closing_description,

      closing_date: `${object.closing_date}T${object.time}`,

      km: maintenance.km || 0,

      status: 'FECHADA',
    };

    try {
      await API.put(`maintenances/${maintenance.uuid}`, {
        ...data,
      });

      this.handleMaintenanceNew();

      await this.getTodosList();

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceEdit = maintenance => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenance,

      equipmentForm: false,

      maintenancePieceForm: false,

      maintenanceEmployeeForm: false,
    }));

    this.handleMaintenanceGet(maintenance.uuid);

    this.scrollToMyRef(this.refMaintenance);
  };

  handleClosedList = async () => {
    await this.setState(prevState => ({
      closedList: !prevState.closedList,

      loadingList: true,
    }));

    this.getTodosList();
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleMaintenanceNew = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenance: null,

      equipment: null,

      equipmentForm: false,

      maintenancePieceForm: false,

      maintenanceEmployeeForm: false,
    }));

    this.scrollToMyRef(this.refMaintenance);
  };

  getTodosList = async () => {
    const { closedList, filtro } = this.state;

    let auxiliar = closedList ? 'closed/' : 'open/';

    auxiliar += filtro || '';

    try {
      const response = await API.get(`maintenances/${auxiliar}`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        maintenances: response.data,
        loadingList: false,
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleMaintenanceGet = async uuid => {
    try {
      const response = await API.get(`maintenances/${uuid}`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        maintenance: response.data[0],
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleSnackBarClose = (event, reason) => {
    // if (reason === 'clickaway') {

    //  return;

    // }

    this.setState({ snackBarOpen: false });
  };

  // EQUIPMENTS HELPERS

  handleEquipmentsList = (equipments, equipment) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      equipments,

      equipment,
    }));
  };

  handleEquipmentFormState() {
    const { equipmentForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      equipmentForm: !equipmentForm,
    }));
  }

  handleCalibrationFormState() {
    const { calibrationForm } = this.state;

    this.setState(prevState => ({
      calibrationForm: !calibrationForm,
    }));
  }

  // MAINTENANCEPIECES HELPERS

  handleMaintenancePiecesList = (maintenancePieces, maintenancePiece) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenancePieces,

      maintenancePiece,
    }));
  };

  handleMaintenancePieceFormState() {
    const { maintenancePieceForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenancePieceForm: !maintenancePieceForm,
    }));
  }

  // MAINTENANCEEMPLOYEES HELPERS

  handleMaintenanceEmployeesList = (
    maintenanceEmployees,

    maintenanceEmployee
  ) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenanceEmployees,

      maintenanceEmployee,
    }));
  };

  handleMaintenanceEmployeeFormState(state) {
    const { maintenanceEmployeeForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      maintenanceEmployeeForm: state || !maintenanceEmployeeForm,
    }));
  }

  handleChangeFiltro = async event => {
    // eslint-disable-next-line no-unused-vars
    await this.setState(prevState => ({ filtro: event.target.value }));

    this.getTodosList();
  };

  render() {
    const { classes, useruuid } = this.props;

    const { equipments, filtro } = this.state;


    let oilChangeEquipments = null;
    let alertEquipments = null;
    let calibrationEquipments = null;

    if (equipments && equipments[0].uuid) {
      oilChangeEquipments = equipments.filter(
        equipment => equipment.maintenances[0] && equipment.type === 'CAVALO'
      );

      calibrationEquipments = equipments.filter(
        equipment => equipment.type === 'CAVALO'
      );

      alertEquipments = equipments.filter(
        equipment => equipment.km_diff && equipment.km_diff >= 19500
      );
    }

    console.log('calibrationEquipments', calibrationEquipments)

    return (
      <Fragment>
        <center>
          <Paper className={classes.root}>
            <div className="page-header">
              <h1>MANUTENÇÕES</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleMaintenanceNew()}
                >
                  NOVA
                </Button>
              </div>
            </div>

            <hr style={{ width: '90%', marginBottom: '10px' }} />

            <div className="page-header">
              <h1>{this.state.closedList ? 'FECHADAS' : 'EM ABERTO'}</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleClosedList()}
                >
                  {this.state.closedList ? 'ABERTAS' : 'FECHADAS'}
                </Button>
              </div>
            </div>

            {/* TYPE */}

            <div className="item">
              <FormControl style={{ width: '90%' }}>
                <InputLabel htmlFor="type">FILTRO</InputLabel>

                <SelectMUI
                  id="type"
                  name="type"
                  variant="outlined"
                  inputProps={{
                    name: 'type',

                    id: 'type',
                  }}
                  value={filtro}
                  onChange={this.handleChangeFiltro}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="">SEM FILTRO</MenuItem>

                  {equipments &&
                    equipments.length > 0 &&
                    equipments.map(object => (
                      <MenuItem value={object.uuid}>
                        {`${object.code} | ${object.type}`}
                      </MenuItem>
                    ))}
                </SelectMUI>
              </FormControl>
            </div>

            <div className="page-header">
              <h1>
                TOTAL:{' '}
                {this.state.maintenances ? this.state.maintenances.length : 0}
              </h1>

              {oilChangeEquipments && (
                <div className="last-wrap">
                  <PDFDownloadLink
                    document={
                      <OilChangePDF oilChangeEquipments={oilChangeEquipments} />
                    }
                    fileName={`TROCA_DE_ÓLEO-${moment(new Date()).format(
                      'DD_MM_YYYY'
                    )}.pdf`}
                    style={{
                      height: '35px',
                      borderRadius: '4px',
                      padding: '0 20px',
                      background: '#055aab',
                      fontSize: '16px',
                      color: '#fff',
                      marginRight: '32px',

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'CRREGANDO...' : 'PDF TROCA DE ÓLEO'
                    }
                  </PDFDownloadLink>
                </div>
              )}

              {alertEquipments && (
                <div className="last-wrap">
                  <PDFDownloadLink
                    document={
                      <OilChangePDF oilChangeEquipments={alertEquipments} />
                    }
                    fileName={`TROCA_DE_ÓLEO-${moment(new Date()).format(
                      'DD_MM_YYYY'
                    )}.pdf`}
                    style={{
                      height: '35px',
                      borderRadius: '4px',
                      padding: '0 20px',
                      background: '#BC0D35',
                      fontSize: '16px',
                      color: '#fff',
                      marginRight: '32px',

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'CRREGANDO...' : 'ALERTAS'
                    }
                  </PDFDownloadLink>
                </div>
              )}

              {this.state.maintenance && this.state.maintenance.equipment && (
                <div className="last-wrap">
                  <PDFDownloadLink
                    document={
                      <MaintenancePDF maintenance={this.state.maintenance} />
                    }
                    fileName={`OS-${this.state.maintenance.equipment.code}-Nº:${this.state.maintenance.number}-${this.state.maintenance.type}.pdf`}
                    style={{
                      height: '35px',
                      borderRadius: '4px',
                      padding: '0 20px',
                      background: '#055aab',
                      fontSize: '16px',
                      color: '#fff',
                      marginRight: '32px',

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'CRREGANDO...' : 'PDF OS'
                    }
                  </PDFDownloadLink>
                </div>
              )}
            </div>
            <MaintenanceList
              maintenances={this.state.maintenances}
              loadingList={this.state.loadingList}
              onMaintenanceEdit={this.handleMaintenanceEdit}
              onMaintenanceDelete={this.handleMaintenanceDelete}
            />

            <div ref={this.refMaintenance} />

            <div className="page-header">
              <h1>MANUTENÇÃO</h1>
              
              {calibrationEquipments && (
                  <PDFDownloadLink
                    document={
                      <CalibrationPDF calibrationEquipments={calibrationEquipments} />
                    }
                    fileName={`CALIBRAGENS-${moment(new Date()).format(
                      'DD_MM_YYYY'
                    )}.pdf`}
                    style={{
                      height: '35px',
                      borderRadius: '4px',
                      padding: '0 20px',
                      background: '#055aab',
                      fontSize: '16px',
                      color: '#fff',
                      marginRight: '32px',
                      marginLeft: '9%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'CARREGANDO...' : 'PDF CALIBRAGENS'
                    }
                  </PDFDownloadLink>
              )}

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleCalibrationFormState()}
                >
                  NOVA CALIBRAGEM
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleEquipmentFormState()}
                >
                  VEÍCULO
                </Button>
              </div>
            </div>

            <EquipmentContainer
              onEquipmentsList={this.handleEquipmentsList}
              onEquipmentCreation={() => this.handleEquipmentFormState()}
              equipmentForm={this.state.equipmentForm}
            />

            <CalibrationContainer
              onEquipmentsList={this.handleEquipmentsList}
              onCalibrationCreation={this.handleCalibrationCreation}
              calibrationForm={this.state.calibrationForm}
            />

            <MaintenanceForm
              onMaintenanceCreation={this.handleMaintenanceCreation}
              onMaintenanceUpdate={this.handleMaintenanceUpdate}
              onMaintenanceNew={this.handleMaintenanceNew}
              onMaintenanceDelete={this.handleMaintenanceDelete}
              maintenance={this.state.maintenance}
              equipments={this.state.equipments}
              equipment={this.state.equipment}
              useruuid={useruuid}
            />

            {this.state.maintenance ? (
              <Fragment>
                <Paper className={classes.root}>
                  <MaintenanceCloseForm
                    onMaintenanceClose={this.handleMaintenanceClose}
                    maintenance={this.state.maintenance}
                  />
                </Paper>

                <Paper className={classes.root}>
                  <div className="page-header">
                    <h1>PEÇAS DA MANUTENÇÃO</h1>

                    <div className="last-wrap">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: '30px', float: 'left' }}
                        onClick={() => this.handleMaintenancePieceFormState()}
                      >
                        {this.state.maintenancePieceForm ? 'FECHAR' : 'ABRIR'}
                      </Button>
                    </div>
                  </div>

                  {/*

                    {this.state.maintenancePieces ? (

                      <h1>

                        Total: R${' '}

                        {this.state.maintenancePieces

                          .reduce(

                            (prev, next) => prev + next.value * next.quantity,

                            0

                          )

                          .toLocaleString()}{' '}

                      </h1>

                          ) : null}

                      */}

                  <MaintenancePieceContainer
                    maintenance={this.state.maintenance}
                    onMaintenancePiecesList={this.handleMaintenancePiecesList}
                    onMaintenancePieceCreation={state => null}
                    onMaintenancePieceDelete={() =>
                      this.handleMaintenancePieceFormState()
                    }
                    maintenancePieceForm={this.state.maintenancePieceForm}
                  />
                </Paper>

                <Paper className={classes.root}>
                  <div className="page-header">
                    <h1>FUNCIONÁRIOS DA MANUTENÇÃO</h1>

                    <div className="last-wrap">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: '30px', float: 'left' }}
                        onClick={() =>
                          this.handleMaintenanceEmployeeFormState()
                        }
                      >
                        {this.state.maintenanceEmployeeForm
                          ? 'FECHAR'
                          : 'ABRIR'}
                      </Button>
                    </div>
                  </div>

                  <MaintenanceEmployeeContainer
                    maintenance={this.state.maintenance}
                    onMaintenanceEmployeesList={
                      this.handleMaintenanceEmployeesList
                    }
                    onMaintenanceEmployeeCreation={state => null}
                    maintenanceEmployeeForm={this.state.maintenanceEmployeeForm}
                    useruuid={useruuid}
                  />
                </Paper>
              </Fragment>
            ) : null}
          </Paper>

          <SnackBar
            onSnackBarClose={this.handleSnackBarClose}
            snackBarOpen={this.state.snackBarOpen}
          />
        </center>
      </Fragment>
    );
  }
}

MaintenanceContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaintenanceContainer);
