import React, { Component, Fragment } from 'react';

import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import io from 'socket.io-client';

import swal from '@sweetalert/with-react';

import PieceIventoryForm from './PieceIventoryForm';

import PieceIventoryList from './PieceIventoryList';

import PieceInventoryFilterForm from './PieceInventoryFilterForm';

import MovementForm from './MovementForm';

import PieceContainer from '../Piece/PieceContainer';

import SnackBar from '../../Utilities/SnackBar';

import Parameters from '../../services/parameters';

import API from '../../services/api';

const styles = theme => ({
  root: {
    width: '90%',

    margin: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 2,

    overflowX: 'auto',
  },
});

class PieceInventoryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventories: [],

      loadingList: true,

      pieceForm: false,

      piece: null,

      pieces: [{ uuid: null, code: 'CARREGANDO' }],

      pieceUuid: null,

      document: null,

      movementList: [],

      snackBarOpen: false,
    };

    // piece invenotry BIND

    this.handleInventoryCreation = this.handleInventoryCreation.bind(this);

    this.handleInventoryDelete = this.handleInventoryDelete.bind(this);

    // piece BIND

    this.handlePiecesList = this.handlePiecesList.bind(this);

    this.handlePieceFormState = this.handlePieceFormState.bind(this);
  }

  refTicket = React.createRef();

  componentDidMount() {
    this.registerToSocket();

    this.getListOptions();

    this.getTodosList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('schedule', () => this.getTodosList());
  };

  getListOptions = async () => {
    try {
      const responsePieces = await API.get(`pieces`);
      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        pieces: responsePieces.data,
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleInventoryCreation = async object => {
    const { movementList } = this.state;
    const { document } = object;

    const data = {
      document,
      list: movementList,
    };

    try {
      await API.post(`pieceinventories`, { ...data });
      await this.getTodosList();
      await this.getListOptions();

      this.setState({
        snackBarOpen: true,
        piece: null,
        movementList: [],
      });
    } catch (err) {
      toast.error(err);
    }
  };

  handleInventoryDelete = async movementUuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da movimentação?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`pieceinventories/${movementUuid}`);

        this.setState({ snackBarOpen: true });

        await this.getTodosList();
        await this.getListOptions();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  handleAddMovement = movement => {
    if (movement.pieceUuid && movement.quantity !== 0) {
      const { movementList, pieces } = this.state;
      const list = movementList;
      const data = {
        ...movement,
        piece: pieces.find(piece => piece.uuid === movement.pieceUuid),
      };
      list.push(data);

      this.setState({ movementList: list });
    }
  };

  handleDeleteMovement = index => {
    const { movementList } = this.state;

    const list = movementList;
    list.splice(index, 1);

    this.setState({ movementList: list });
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getTodosList = async values => {
    this.setState({
      loadingList: true,
    });

    if (values) {
      await this.setState({
        pieceUuid: values.piece || null,
        document: values.document ? values.document : null,
      });
    }

    const { pieceUuid, document } = this.state;
    const response = await API.get(
      `pieceinventories/${pieceUuid || null}/${document || null}`
    );

    this.setState({
      inventories: response.data,

      loadingList: false,
    });
  };

  handleSnackBarClose = (event, reason) => {
    // if (reason === 'clickaway') {

    //  return;

    // }

    this.setState({ snackBarOpen: false });
  };

  // PIECES HELPERS

  handlePiecesList = (pieces, piece) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      pieces,
      piece,
    }));
  };

  handlePieceFormState() {
    const { pieceForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      pieceForm: !pieceForm,
    }));
  }

  render() {
    const { classes } = this.props;

    const {
      inventories,
      pieces,
      piece,
      pieceForm,
      movementList,
      loadingList,
    } = this.state;

    return (
      <Fragment>
        <center>
          <Paper className={classes.root}>
            <div className="page-header">
              <h1>ESTOQUE DE PEÇAS</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleTicketNew()}
                >
                  NOVA
                </Button>
              </div>
            </div>
            <hr style={{ width: '90%', marginBottom: '20px' }} />
            <PieceInventoryFilterForm
              onList={this.getTodosList}
              pieces={pieces}
            />
            <hr style={{ width: '90%', margin: '20px' }} />
            <div className="page-header">
              <h1>TOTAL: {inventories.length}</h1>
            </div>
            <PieceIventoryList
              inventories={inventories}
              loadingList={loadingList}
              onInventoryDelete={this.handleInventoryDelete}
            />
            <div ref={this.refTicket} />

            <div className="page-header">
              <h1>ESTOQUE</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => this.handlePieceFormState()}
                  style={{ marginRight: '30px', float: 'right' }}
                >
                  NOVA PEÇA
                </Button>
              </div>
            </div>

            <PieceContainer
              onPiecesList={this.handlePiecesList}
              onPieceCreation={() => this.handlePieceFormState()}
              pieceForm={pieceForm}
            />

            <MovementForm
              onAdd={this.handleAddMovement}
              onDelete={this.handleDeleteMovement}
              pieces={pieces}
              piece={piece}
              movements={movementList}
            />

            <PieceIventoryForm
              onInventoryCreation={this.handleInventoryCreation}
              pieces={pieces}
              piece={piece}
              movements={movementList}
            />
          </Paper>

          <SnackBar
            onSnackBarClose={this.handleSnackBarClose}
            snackBarOpen={this.state.snackBarOpen}
          />
        </center>
      </Fragment>
    );
  }
}

PieceInventoryContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PieceInventoryContainer);
