import React, { Component } from 'react';

import moment from 'moment';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

import API from '../../../services/api';

// Components

import EmployeeRoleList from './list';

import EmployeeRoleForm from './form';

// Styles

import { SubContainer, SubContent } from '../styles';

class EmployeeRoleContainer extends Component {
  state = {
    isLoading: true,

    employeeRoles: null,

    roles: null,
  };

  async componentDidMount() {
    this.mount();
  }

  mount() {
    this.getEmployeeRoles();

    this.getRoles();
  }

  async getEmployeeRoles() {
    this.setState({
      isLoading: true,
    });

    const { employee } = this.props;

    API.get(`employeeroles/list/${employee.uuid}`).then(res => {
      const result = res.data;

      this.setState({
        employeeRoles: result,

        isLoading: false,
      });
    });
  }

  handleEmployeeRoleCreation = async values => {
    const { employee } = this.props;

    try {
      await API.post(
        'employeeroles',

        {
          // eslint-disable-next-line new-cap
          start_date: new moment.utc(values.start_date),
          // eslint-disable-next-line new-cap
          end_date: values.end_date ? new moment.utc(values.end_date) : null,
          value: values.value,
          roleUuid: values.roleUuid,
          employeeUuid: employee.uuid,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getEmployeeRoles();
    } catch (err) {
      alert(err);
    }
  };

  async getRoles() {
    this.setState({
      isLoading: true,
    });

    API.get(`roles`).then(res => {
      const result = res.data;

      this.setState({
        roles: result,

        isLoading: false,
      });
    });
  }

  handleEmployeeRoleDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão da Função?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`employeeroles/${uuid}`).then(() => {
        this.getEmployeeRoles();
      });
    }
  };

  render() {
    const { isLoading, employeeRoles, roles } = this.state;

    return (
      <SubContainer>
        <header>
          <h1>FUNÇÕES</h1>
        </header>

        <SubContent>
          <EmployeeRoleList
            isLoading={isLoading}
            onEmployeeRoleDelete={this.handleEmployeeRoleDelete}
            employeeRoles={employeeRoles}
          />

          <EmployeeRoleForm
            onEmployeeRoleCreation={this.handleEmployeeRoleCreation}
            roles={roles}
          />
        </SubContent>
      </SubContainer>
    );
  }
}

EmployeeRoleContainer.propTypes = {
  employee: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default EmployeeRoleContainer;
