import React from 'react';

import Loading from '../../components/Loading';

import { MaintenanceEmployeeStyles } from './MaintenanceEmployeeStyles';

const maintenanceEmployeeListItem = props =>
  props.maintenanceEmployees.map(maintenanceEmployee => (
    <div
      className="item"
      key={maintenanceEmployee.uuid}
      onClick={e => {
        props.onMaintenanceEmployeeEdit(maintenanceEmployee);
      }}
    >
      {/* <p className="po">{usuario.nome}</p> */}

      <p className="code">{maintenanceEmployee.employee.name}</p>

      <p className="date">
        {maintenanceEmployee.start
          ? new Date(maintenanceEmployee.start).toLocaleString('pt-BR', {
              timeZone: 'UTC',
            })
          : ''}
      </p>

      <p className="date_end">
        {maintenanceEmployee.end
          ? new Date(maintenanceEmployee.end).toLocaleString('pt-BR', {
              timeZone: 'UTC',
            })
          : ''}
      </p>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onMaintenanceEmployeeDelete(maintenanceEmployee);
        }}
        className="btn excluir"
      >
        Excluir
      </button>
    </div>
  ));

function maintenanceEmployeeList(props) {
  return (
    <div>
      <hr style={{ width: '90%', marginBottom: '20px' }} />

      {!props.loadingList ? (
        props.maintenanceEmployees.length > 0 ? (
          <MaintenanceEmployeeStyles>
            {maintenanceEmployeeListItem(props)}
          </MaintenanceEmployeeStyles>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}

      <hr style={{ width: '90%', marginTop: '20px' }} />
    </div>
  );
}

export default maintenanceEmployeeList;
