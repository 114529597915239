import React, { Fragment } from 'react';

import { MaintenanceListStyles } from './MaintenanceListStyles';

import Loading from '../components/Loading';

const maintenanceListItem = props =>
  props.maintenances.map(maintenance => (
    <div
      className="item"
      key={maintenance.uuid}
      onClick={e => {
        props.onMaintenanceEdit(maintenance);
      }}
    >
      {/* <p className="po">{usuario.nome}</p> */}

      <p className="code">{maintenance.equipment.code}</p>

      <p className="type">{maintenance.type}</p>

      <p className="situation">{maintenance.situation}</p>

      <p className="date">
        {new Date(maintenance.opening_date).toLocaleString('pt-BR', {
          timeZone: 'UTC',
        })}
      </p>

      <p className="date_end">
        {maintenance.closing_date
          ? new Date(maintenance.closing_date).toLocaleString('pt-BR', {
              timeZone: 'UTC',
            })
          : ''}
      </p>
    </div>
  ));

function MaintenanceList(props) {
  const { loadingList, maintenances } = props;

  return (
    <Fragment>
      <hr style={{ width: '90%', marginTop: '20px', marginBottom: '20px' }} />

      {!loadingList ? (
        maintenances && maintenances.length > 0 ? (
          <Fragment>
            <MaintenanceListStyles>
              {maintenanceListItem(props)}
            </MaintenanceListStyles>
          </Fragment>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}

      <hr style={{ width: '90%', marginTop: '20px' }} />
    </Fragment>
  );
}

export default MaintenanceList;
