import React from 'react';

import { IoMdTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

import Loading from '../../components/Loading';

import { ListStyled, ListItem } from '../styles';

const asuExamListItem = props =>
  props.asuExams.map(asuExam => (
    <ListItem
      gridheader="90% 10%"
      gridlist="90% 10%"
      color="#011826"
      strongcolor="#fff"
      key={asuExam.uuid}
    >
      <strong>{asuExam.exam && asuExam.exam.description}</strong>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onAsuExamDelete(asuExam.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function AsuExamList(props) {
  const { isLoading, asuExams } = props;

  return (
    <>
      {!isLoading || asuExams != null ? (
        <>
          {asuExams && asuExams.length > 0 ? (
            <ListStyled gridheader="90% 10%" gridlist="90% 10%">
              <header>
                <div className="titles">
                  <strong>EXAME</strong>

                  <strong />
                </div>
              </header>

              {asuExamListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>SEM EXAMES</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

AsuExamList.propTypes = {
  isLoading: PropTypes.bool,

  asuExams: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      exam: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
};

AsuExamList.defaultProps = {
  isLoading: false,

  asuExams: [],
};

export default AsuExamList;
