import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';

import PieceForm from './PieceForm';

import API from '../../services/api';

class PieceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pieces: null,
    };

    this.handlePieceCreation = this.handlePieceCreation.bind(this);

    this.getPiecesList();
  }

  refPieces = React.createRef();

  getPiecesList = async piece => {
    const { onPiecesList } = this.props;

    try {
      const response = await API.get(`pieces`);

      onPiecesList(response.data, piece);
    } catch (err) {
      toast.error(err);
    }
  };

  handlePieceCreation = async piece => {
    const { onPieceCreation } = this.props;

    const data = {
      description: piece.description.toUpperCase(),
      total: piece.total || 0,
    };

    try {
      const response = await API.post(`pieces`, { ...data });

      this.getPiecesList(response.data);
      onPieceCreation();
    } catch (err) {
      toast.error(err);
    }
  };

  render() {
    return (
      <div>
        {this.props.pieceForm ? (
          <Fragment>
            <div ref={this.refPiece} />

            <PieceForm onPieceCreation={this.handlePieceCreation} />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

export default PieceContainer;
