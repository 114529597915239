import React, { Component, Fragment } from 'react';

import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import io from 'socket.io-client';

import swal from '@sweetalert/with-react';

import TicketForm from './TicketForm';

import TicketList from './TicketList';

import TicketFilterForm from './TicketFilterForm';

import EquipmentContainer from '../Equipment/EquipmentContainer';

import EmployeeContainer from '../Employee/EmployeeContainer';

import LocalContainer from '../Local/LocalContainer';

import SnackBar from '../../Utilities/SnackBar';

import Parameters from '../../services/parameters';

import API from '../../services/api';

const styles = theme => ({
  root: {
    width: '90%',

    margin: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 2,

    overflowX: 'auto',
  },
});

class TicketContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tickets: [],

      ticket: null,

      loadingList: true,

      indexForm: -1,

      equipmentForm: false,

      equipment: null,

      equipments: [{ uuid: null, code: 'CARREGANDO' }],

      employeeForm: false,

      employee: null,

      employees: [{ uuid: null, name: 'CARREGANDO' }],

      localForm: false,

      local: null,

      locals: [{ uuid: null, name: 'CARREGANDO' }],

      snackBarOpen: false,
    };

    // MAITENANCE BIND

    this.handleTicketCreation = this.handleTicketCreation.bind(this);

    this.handleTicketUpdate = this.handleTicketUpdate.bind(this);

    this.handleTicketEdit = this.handleTicketEdit.bind(this);

    this.handleTicketGet = this.handleTicketGet.bind(this);

    this.handleTicketDelete = this.handleTicketDelete.bind(this);

    // equipment BIND

    this.handleEquipmentsList = this.handleEquipmentsList.bind(this);

    this.handleEquipmentFormState = this.handleEquipmentFormState.bind(this);

    // employee BIND

    this.handleEmployeesList = this.handleEmployeesList.bind(this);

    this.handleEmployeeFormState = this.handleEmployeeFormState.bind(this);

    // local BIND

    this.handleLocalsList = this.handleLocalsList.bind(this);

    this.handleLocalFormState = this.handleLocalFormState.bind(this);
  }

  refTicket = React.createRef();

  componentDidMount() {
    this.registerToSocket();

    this.getListOptions();

    this.getTodosList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('schedule', () => this.getTodosList());
  };

  getListOptions = async () => {
    try {
      const responseEquipments = await API.get(`equipments`);
      const responseEmployees = await API.get(`employees`);
      const responseLocals = await API.get(`locals`);
      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        equipments: responseEquipments.data,
        employees: responseEmployees.data,
        locals: responseLocals.data,
      }));
    } catch (err) {
      toast.error(err);
    }
  };

  handleTicketCreation = async object => {
    const {
      date,

      dead_line,

      value,

      number,

      dt_discharge,

      value_discharge,

      equipmentUuid,

      employeeUuid,

      localUuid,
    } = object;

    const data = {
      date,

      dead_line,

      value,

      number,

      dt_discharge,

      value_discharge,

      equipmentUuid,

      employeeUuid,

      localUuid,
    };

    try {
      const response = await API.post(`tickets`, { ...data });

      this.handleTicketEdit(response.data);

      await this.getTodosList();

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleTicketUpdate = async object => {
    const { ticket } = this.state;

    const {
      date,

      dead_line,

      value,

      number,

      dt_discharge,

      value_discharge,

      equipmentUuid,

      employeeUuid,

      localUuid,
    } = object;

    const data = {
      date,

      dead_line,

      value,

      number,

      dt_discharge,

      value_discharge,

      equipmentUuid,

      employeeUuid,

      localUuid,
    };

    try {
      const response = await API.put(`tickets/${ticket.uuid}`, {
        ...data,
      });

      this.handleTicketEdit(response.data);

      await this.getTodosList();

      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleTicketDelete = async ticket => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da Multa?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`tickets/${ticket.uuid}`);

        this.setState({ snackBarOpen: true });

        this.handleTicketNew();

        await this.getTodosList();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  handleTicketEdit = ticket => {
    this.setState(prevState => ({
      ticket,
    }));

    this.handleTicketGet(ticket.uuid);

    this.scrollToMyRef(this.refTicket);
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleTicketNew = () => {
    this.setState(prevState => ({
      ticket: null,
    }));

    this.scrollToMyRef(this.refTicket);
  };

  getTodosList = async values => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      loadingList: true,
    }));

    if (values) {
      // eslint-disable-next-line no-unused-vars
      await this.setState(prevState => ({
        equipment: values.equipment ? values.equipment : null,
        employee: values.employee ? values.employee : null,
        local: values.local ? values.local : null,
      }));
    }

    const { equipment, employee, local } = this.state;

    // const type = values && values.type ? values.type : null;
    const response = await API.get(
      `tickets/${equipment || null}/${employee || null}/${local || null}`
    );

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      tickets: response.data,

      loadingList: false,
    }));
  };

  handleTicketGet = async uuid => {
    const response = await API.get(`tickets/${uuid}`);

    this.setState(prevState => ({
      ticket: response.data[0],
    }));
  };

  handleSnackBarClose = (event, reason) => {
    // if (reason === 'clickaway') {

    //  return;

    // }

    this.setState({ snackBarOpen: false });
  };

  // EQUIPMENTS HELPERS

  handleEquipmentsList = (equipments, equipment) => {
    this.setState(prevState => ({
      equipments,

      equipment,
    }));
  };

  handleEquipmentFormState() {
    const { equipmentForm } = this.state;
    this.setState(prevState => ({
      equipmentForm: !equipmentForm,
    }));
  }

  // EMPLOYEES HELPERS

  handleEmployeesList = (employees, employee) => {
    this.setState(prevState => ({
      employees,

      employee,
    }));
  };

  handleEmployeeFormState() {
    const { employeeForm } = this.state;
    this.setState(prevState => ({
      employeeForm: !employeeForm,
    }));
  }

  // locals helper

  handleLocalsList = (locals, local) => {
    this.setState(prevState => ({
      locals,

      local,
    }));
  };

  handleLocalFormState() {
    const { localForm } = this.state;
    this.setState(prevState => ({
      localForm: !localForm,
    }));
  }

  render() {
    const { classes, useruuid } = this.props;

    const {
      ticket,
      tickets,
      equipments,
      equipment,
      employees,
      employee,
      locals,
      local,
      indexForm,
    } = this.state;

    return (
      <Fragment>
        <center>
          <Paper className={classes.root}>
            <div className="page-header">
              <h1>MULTAS</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => this.handleTicketNew()}
                >
                  NOVA
                </Button>
              </div>
            </div>
            <hr style={{ width: '90%', marginBottom: '20px' }} />
            <TicketFilterForm
              onList={this.getTodosList}
              equipments={equipments}
              employees={employees}
              locals={locals}
            />
            <hr style={{ width: '90%', margin: '20px' }} />
            <div className="page-header">
              <h1>TOTAL: {tickets.length}</h1>
            </div>
            <TicketList
              tickets={tickets}
              loadingList={this.state.loadingList}
              onTicketEdit={this.handleTicketEdit}
              onTicketDeletonlie={this.handleTicketDelete}
            />
            <div ref={this.refTicket} />
            <div className="page-header">
              <h1>MULTA</h1>

              <div className="last-wrap">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => {
                    if (indexForm === 0) {
                      this.setState({ indexForm: -1 });
                    } else {
                      this.setState({ indexForm: 0 });
                    }
                  }}
                >
                  VEÍCULO
                </Button>

                {useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
                useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
                useruuid === '7eff000c-91d9-4c54-b597-79bd93938325' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '30px', float: 'left' }}
                    onClick={() => {
                      if (indexForm === 1) {
                        this.setState({ indexForm: -1 });
                      } else {
                        this.setState({ indexForm: 1 });
                      }
                    }}
                  >
                    FUNCIONÁRIO
                  </Button>
                ) : null}

                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '30px', float: 'left' }}
                  onClick={() => {
                    if (indexForm === 2) {
                      this.setState({ indexForm: -1 });
                    } else {
                      this.setState({ indexForm: 2 });
                    }
                  }}
                >
                  LOCAL
                </Button>
              </div>
            </div>

            <EquipmentContainer
              onEquipmentsList={this.handleEquipmentsList}
              onEquipmentCreation={() => this.setState({ indexForm: -1 })}
              equipmentForm={indexForm === 0}
            />

            <EmployeeContainer
              onEmployeesList={this.handleEmployeesList}
              onEmployeeCreation={() => this.setState({ indexForm: -1 })}
              visible={indexForm === 1}
            />

            <LocalContainer
              onLocalsList={this.handleLocalsList}
              onLocalCreation={() => this.setState({ indexForm: -1 })}
              visible={indexForm === 2}
            />

            <TicketForm
              onTicketCreation={this.handleTicketCreation}
              onTicketUpdate={this.handleTicketUpdate}
              onTicketNew={this.handleTicketNew}
              onTicketDelete={this.handleTicketDelete}
              ticket={ticket}
              equipments={equipments}
              equipment={equipment}
              employees={employees}
              employee={employee}
              locals={locals}
              local={local}
              useruuid={useruuid}
            />
          </Paper>

          <SnackBar
            onSnackBarClose={this.handleSnackBarClose}
            snackBarOpen={this.state.snackBarOpen}
          />
        </center>
      </Fragment>
    );
  }
}

TicketContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TicketContainer);
