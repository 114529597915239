import React from 'react';

import { Formik } from 'formik';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import imgloading from '../../../img/load.gif';

import { FormStyled } from '../styles';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({ start_date, value, roleUuid }) => {
  const errors = {};

  if (!roleUuid) errors.roleUuid = 'Obrigatório!';

  if (!start_date) errors.start_date = 'Obrigatório!';

  if (!value) errors.value = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onEmployeeRoleCreation({ ...values });

  setSubmitting(false);

  resetForm();
};

const EmployeeRoleForm = props => {
  const { employeefunction, roles } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        start_date: employeefunction ? employeefunction.start_date : '',

        end_date: employeefunction ? employeefunction.end_date : '',

        value: employeefunction ? employeefunction.value : '',

        roleUuid: employeefunction ? employeefunction.roleUuid : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleChange,

        handleBlur,

        errors,

        touched,

        isSubmitting,

        handleReset,

        dirty,
      }) => (
        <FormStyled>
          <h1>NOVA FUNÇÃO</h1>

          <div className="formData">
            <strong>FUNÇÃO</strong>

            <select
              id="roleUuid"
              name="roleUuid"
              value={values.roleUuid}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <>
                <option value="" />

                {roles &&
                  roles.length > 0 &&
                  roles.map(object => (
                    <option key={object.uuid} value={object.uuid}>
                      {object.description}
                    </option>
                  ))}
              </>
            </select>

            {errors.roleUuid && touched.roleUuid && (
              <span>{errors.roleUuid}</span>
            )}
          </div>

          <div className="line">
            <div className="formData">
              <strong>DATA INÍCIO</strong>

              <input
                id="start_date"
                name="start_date"
                type="date"
                value={values.start_date}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              {errors.start_date && touched.start_date && (
                <span>{errors.start_date}</span>
              )}
            </div>

            <div className="formData">
              <strong>DATA FINAL</strong>

              <input
                id="end_date"
                name="end_date"
                type="date"
                value={values.end_date}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              {errors.end_date && touched.end_date && (
                <span>{errors.end_date}</span>
              )}
            </div>
          </div>

          <div className="formData">
            <strong>SALÁRIO</strong>

            <input
              id="value"
              name="value"
              type="text"
              value={values.value}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.value && touched.value && <span>{errors.value}</span>}
          </div>

          <button variant="contained" type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>

          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            CANCELAR
          </button>
        </FormStyled>
      )}
    </Formik>
  );
};

EmployeeRoleForm.propTypes = {
  employeefunction: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    value: PropTypes.string,
    roleUuid: PropTypes.string,
  }),

  roles: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

EmployeeRoleForm.defaultProps = {
  employeefunction: null,

  roles: [],
};

export default EmployeeRoleForm;
