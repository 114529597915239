import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container, Content } from './styles';

export default function Home({ team }) {
  const useruuid = localStorage.getItem('USER_UUID');

  const authMaintanance = team === 'GERENCIA' || team === 'OFICINA';
  const authSchedule = team === 'GERENCIA' || team === 'EXPEDICAO';
  const authRefuel = team === 'GERENCIA' || team === 'OFICINA';

  return (
    <Container>
      <Content>
        <ul>
          {authMaintanance && (
            <li>
              <Link to="/maintenances">MANUTENÇÕES</Link>
            </li>
          )}
          {authRefuel && (
            <li>
              <Link to="/refuels">ABASTECIMENTOS</Link>
            </li>
          )}
          {authSchedule && (
            <li>
              <Link to="/schedules">PROGRAMAÇÕES</Link>
            </li>
          )}
          {authRefuel && (
            <li>
              <Link to="/tickets">MULTAS</Link>
            </li>
          )}
          {authMaintanance && (
            <li>
              <Link to="/inventories">ESTOQUE DE PEÇAS</Link>
            </li>
          )}
          {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
            useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
            useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
            useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
            <li>
              <Link to="/employees">FUNCIONÁRIOS</Link>
            </li>
          )}
          {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
            useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
            useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
            useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
            <li>
              <Link to="/roles">FUNÇÕES</Link>
            </li>
          )}
          {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
            useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
            useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
            useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
            <li>
              <Link to="/asus">ASUS</Link>
            </li>
          )}
        </ul>
      </Content>
    </Container>
  );
}

Home.propTypes = {
  team: PropTypes.string.isRequired,
};
