import React, { Component, Fragment } from 'react';

import { toast } from 'react-toastify';

import io from 'socket.io-client';

import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import TankForm from './TankForm';

import SnackBar from '../../Utilities/SnackBar';

import Parameters from '../../services/parameters';

import TankList from './TankList';

import API from '../../services/api';

const styles = theme => ({
  root: {
    width: '90%',

    marginTop: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 3,

    overflowX: 'auto',
  },
});

class TankContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingRefuels: true,

      tankRefuels: [],

      snackBarOpen: false,

      tank: null,
    };

    this.handleTankCreation = this.handleTankCreation.bind(this);
  }

  componentDidMount() {
    this.registerToSocket();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('tankRefuel', () => this.getTankRefuelsList());
  };

  refTank = React.createRef();

  getTankRefuelsList = async tank => {
    if (tank) {
      this.setState(prevState => ({
        loadingRefuels: true,
      }));

      try {
        const response = await API.get(`tankRefuels/${tank.value}`);
        this.setState(prevState => ({
          tankRefuels: response.data,
          loadingRefuels: false,
        }));
      } catch (err) {
        toast.error(err);
      }
    }
  };

  handleTankCreation = async object => {
    const { onTankRefuelCreation } = this.props;

    const data = {
      ...object,
    };

    try {
      await API.post(`tankRefuels`, { ...data });

      onTankRefuelCreation();
      this.getTankRefuelsList(this.state.tank);
      this.setState({ snackBarOpen: true });
    } catch (err) {
      toast.error(err);
    }
  };

  handleSnackBarClose = (event, reason) => {
    // if (reason === 'clickaway') {

    //  return;

    // }

    this.setState({ snackBarOpen: false });
  };

  render() {
    const { loadingRefuels, tankRefuels, snackBarOpen, tank } = this.state;

    const { classes, tankForm, tanks } = this.props;

    return (
      <div>
        {tankForm ? (
          <Fragment>
            <div ref={this.refTank} />

            <Paper className={classes.root}>
              <TankForm
                onCreation={this.handleTankCreation}
                tanks={tanks}
                onSelectTank={e => {
                  this.getTankRefuelsList(e);
                  this.setState({ tank: e });
                }}
              />

              {tank && (
                <>
                  <hr style={{ width: '90%', marginBottom: '20px' }} />

                  <TankList
                    tankRefuels={tankRefuels}
                    loadingRefuels={loadingRefuels}
                  />
                </>
              )}
            </Paper>
          </Fragment>
        ) : null}

        <SnackBar
          onSnackBarClose={this.handleSnackBarClose}
          snackBarOpen={snackBarOpen}
        />
      </div>
    );
  }
}

TankContainer.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  tankForm: PropTypes.bool.isRequired,
  onTankRefuelCreation: PropTypes.func.isRequired,
  onTankList: PropTypes.func.isRequired,
  tank: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(TankContainer);
