import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {
  Table,
  TableBody,
  TableHeader,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ddd',
  },
  section: {
    width: '100%',
    margin: 10,
    padding: 32,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',

    textAlign: 'center',
  },
  division: {
    width: '100%',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',

    textAlign: 'center',
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: '1 solid #000',
  },
  title: {
    color: '#000',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  subtitle: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 8,
    marginBottom: 8,
  },
  common: {
    color: '#333',
    fontSize: 16,
    fontWeight: 'normal',
  },
  tablecontainer: {
    marginTop: 16,
    marginBottom: 16,
  },
  tableheader: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#000',
    border: 0,
  },
  tablecell: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#333',
    border: 0,
  },
});

export function MaintenancePDF({ maintenance }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.division}>
            <Text style={styles.title}>NSA</Text>
          </View>

          <View style={styles.division}>
            <Text style={styles.subtitle}>
              ORDEM DE SERVIÇO {maintenance.type} Nº {maintenance.number}
            </Text>
            <Text style={styles.subtitle}>
              DATA DE ABERTURA {maintenance.opening_date.substring(0, 10)}{' '}
              {maintenance.opening_date.substring(11, 16)}
            </Text>
            <Text style={styles.subtitle}>
              EQUIPAMENTO {maintenance.equipment.code}
            </Text>
          </View>

          <View style={styles.division}>
            <Text style={styles.subtitle}>DESCRIÇÃO DE ABERTURA:</Text>
            <Text style={styles.common}>{maintenance.opening_description}</Text>
            <Text style={styles.subtitle}>OBSERVAÇÃO:</Text>
            <Text style={styles.common}>{maintenance.observation || '-'}</Text>
          </View>

          <View style={styles.division}>
            <Text style={styles.subtitle}>DATA DE FECHAMENTO:</Text>
            <Text style={styles.common}>
              {maintenance.closing_date
                ? maintenance.closing_date.substring(0, 10)
                : '-'}{' '}
              {maintenance.closing_date
                ? maintenance.closing_date.substring(11, 16)
                : '-'}
            </Text>
            <Text style={styles.subtitle}>DESCRIÇÃO DE FECHAMENTO:</Text>
            <Text style={styles.common}>
              {maintenance.closing_description || '-'}
            </Text>
          </View>
        </View>
      </Page>

      {maintenance.maintenancepieces && (
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>PEÇAS DA OS</Text>
            <Table data={maintenance.maintenancepieces}>
              <TableHeader textAlign="center">
                <TableCell style={styles.tableheader}>PEÇA</TableCell>
                <TableCell style={styles.tableheader}>QUANTIDADE</TableCell>
                <TableCell style={styles.tableheader}>VALOR</TableCell>
                <TableCell style={styles.tableheader}>TOTAL</TableCell>
              </TableHeader>
              <TableBody textAlign="center">
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => (r.piece ? r.piece.description : '-')}
                />
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.quantity}
                />
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.value}
                />
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.value * r.quantity}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
      )}

      {maintenance.maintenanceemployees && (
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>FUNCIONÁRIOS DA OS</Text>
            <Table data={maintenance.maintenanceemployees}>
              <TableHeader textAlign="center">
                <TableCell style={styles.tableheader}>FUNCIONARIO</TableCell>
                <TableCell style={styles.tableheader}>INICIO</TableCell>
                <TableCell style={styles.tableheader}>FIM</TableCell>
              </TableHeader>
              <TableBody textAlign="center">
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.employee.name}
                />
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.start || '-'}
                />
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.end || '-'}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
      )}
    </Document>
  );
}

export function OilChangePDF({ oilChangeEquipments }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.division}>
            <Text style={styles.title}>NSA - TROCA DE ÓLEO</Text>
          </View>

          <Table data={oilChangeEquipments}>
            <TableHeader textAlign="center">
              <TableCell style={styles.tableheader}>EQUIPAMENTO</TableCell>
              <TableCell style={styles.tableheader}>KM ANTERIOR</TableCell>
              <TableCell style={styles.tableheader}>KM ATUAL</TableCell>
              <TableCell style={styles.tableheader}>TROCA DE ÓLEO</TableCell>
            </TableHeader>
            <TableBody textAlign="center">
              <DataTableCell
                style={styles.tablecell}
                getContent={r => r.code}
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r => r.maintenances[0].km}
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r => r.odometers[0].km}
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r => r.odometers[0].km - r.maintenances[0].km}
              />
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  );
}

export function CalibrationPDF({ calibrationEquipments }) {
  return (
    <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.division}>
            <Text style={styles.title}>NSA - CALIBRAGENS</Text>
            </View>
            <Table data={calibrationEquipments}>
              <TableHeader textAlign="center">
                <TableCell style={styles.tableheader}>EQUIPAMENTO</TableCell>
                <TableCell style={styles.tableheader}>ULTIMA CALIBRAGEM</TableCell>
              </TableHeader>
              <TableBody textAlign="center">
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.code}
                />
                <DataTableCell
                  style={styles.tablecell}
                  getContent={r => r.calibrations[0] ? moment(new Date(r.calibrations[0].createdAt)).format('DD/MM/YYYY') : '-'}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
    </Document>
  );
}

MaintenancePDF.propTypes = {
  maintenance: PropTypes.shape({
    type: PropTypes.string,
    uuid: PropTypes.string,
    number: PropTypes.number,
    opening_date: PropTypes.string,
    opening_description: PropTypes.string,
    closing_date: PropTypes.string,
    closing_description: PropTypes.string,
    observation: PropTypes.string,
    status: PropTypes.string,
    equipment: PropTypes.shape({
      code: PropTypes.string,
    }),
    maintenancepieces: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    maintenanceemployees: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }),
};

MaintenancePDF.defaultProps = {
  maintenance: null,
};

OilChangePDF.propTypes = {
  oilChangeEquipments: PropTypes.arrayOf(
    PropTypes.shape({
      maintenances: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          uuid: PropTypes.string,
          number: PropTypes.number,
          opening_date: PropTypes.string,
          opening_description: PropTypes.string,
          closing_date: PropTypes.string,
          closing_description: PropTypes.string,
          observation: PropTypes.string,
          status: PropTypes.string,
          equipment: PropTypes.shape({
            code: PropTypes.string,
          }),
          maintenancepieces: PropTypes.shape({
            uuid: PropTypes.string,
          }),
          maintenanceemployees: PropTypes.shape({
            uuid: PropTypes.string,
          }),
        })
      ),
    })
  ),
};

OilChangePDF.defaultProps = {
  oilChangeEquipments: [],
};

CalibrationPDF.propTypes = {
  calibrationEquipments: PropTypes.arrayOf(
    PropTypes.shape({
      calibrations: PropTypes.arrayOf(
        PropTypes.shape({
          createdAt: PropTypes.string,
          equipment: PropTypes.shape({
            code: PropTypes.string,
          }),
        })
      ),
    })
  ),
};

CalibrationPDF.defaultProps = {
  calibrationEquipments: [],
};
