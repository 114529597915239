/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import { RefuelListStyles } from './ScheduleListStyles';
import Loading from '../components/Loading';

const listItem = props =>
  props.maintenances.map(object => (
    <div
      className="item"
      key={object.uuid}
      style={object.employee && { backgroundColor: '#7FFF00' }}
      onClick={e => {
        props.onMaintenanceEdit(object);
      }}
    >
      {/* <p className="po">{usuario.nome}</p> */}
      <p className="code">
        {new Date(object.date).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })}
      </p>
      <p className="date">{object.partner.name}</p>
      <p className="type">{object.material.name}</p>
      <p className="type">
        {object.value.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="type">
        {object.volume.toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
        })}
      </p>
      <p className="type">{object.origin && object.origin.name}</p>
      <p className="type">{object.destiny && object.destiny.name}</p>
      <p className="type">{object.employee && object.employee.name}</p>
    </div>
  ));

const listHeader = () => (
  <div className="header" key={1} style={{ background: 'white' }}>
    <p className="code">DATA</p>
    <p className="date">CLIENTE</p>
    <p className="type">MATERIAL</p>
    <p className="type">PESO</p>
    <p className="type">VOLUME</p>
    <p className="type">ORIGEM</p>
    <p className="type">DESTINO</p>
    <p className="type">MOTORISTA</p>
  </div>
);

function MaintenanceList(props) {
  return (
    <Fragment>
      {!props.loadingList ? (
        props.maintenances.length > 0 ? (
          <Fragment>
            <RefuelListStyles>
              {listHeader()}
              {listItem(props)}
            </RefuelListStyles>
          </Fragment>
        ) : (
          'LISTA VAZIA'
        )
      ) : (
        <Loading />
      )}
      <hr style={{ width: '90%', marginTop: '20px' }} />
    </Fragment>
  );
}

export default MaintenanceList;
