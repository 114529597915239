import React from 'react';

import { IoMdTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

import Loading from '../../components/Loading';

import { formatShortDate } from '../../../Utilities/Datetime';

import { ListStyled, ListItem } from '../styles';

const employeeRoleListItem = props =>
  props.employeeRoles.map(employeeRole => (
    <ListItem
      gridheader="1fr 1fr 1fr 1fr 1fr"
      gridlist="1fr 1fr 1fr 1fr 1fr"
      color="#011826"
      strongcolor="#fff"
      key={employeeRole.uuid}
    >
      <strong>{formatShortDate(employeeRole.start_date)}</strong>
      <strong>{formatShortDate(employeeRole.end_date)}</strong>
      <strong>{employeeRole.value}</strong>
      <strong>{employeeRole.role && employeeRole.role.description}</strong>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onEmployeeRoleDelete(employeeRole.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function EmployeeRoleList(props) {
  const { isLoading, employeeRoles } = props;

  return (
    <>
      {!isLoading || employeeRoles != null ? (
        <>
          {employeeRoles && employeeRoles.length > 0 ? (
            <ListStyled
              gridheader="1fr 1fr 1fr 1fr 1fr"
              gridlist="1fr 1fr 1fr 1fr 1fr"
            >
              <header>
                <div className="titles">
                  <strong>INÍCIO</strong>

                  <strong>FINAL</strong>

                  <strong>SALÁRIO</strong>

                  <strong>FUNÇÃO</strong>

                  <strong />
                </div>
              </header>

              {employeeRoleListItem(props)}
            </ListStyled>
          ) : (
            <ListStyled>
              <strong>SEM FUNÇÕES</strong>
            </ListStyled>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

EmployeeRoleList.propTypes = {
  isLoading: PropTypes.bool,

  employeeRoles: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      start_date: PropTypes.string,

      end_date: PropTypes.string,

      value: PropTypes.string,

      role: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
};

EmployeeRoleList.defaultProps = {
  isLoading: false,

  employeeRoles: [],
};

export default EmployeeRoleList;
