import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

import API from '../../services/api';

import Loading from '../components/Loading';

// Components

import EmployeeForm from './form';

import EmployeeFilter from './filter';

import EmployeeList from './list';

import EmployeeRoleContainer from './EmployeeRole';

// Styles

import { Wrapper, Container, Content } from './styles';

class EmployeeContainer extends Component {
  state = {
    modalAddEmployee: false,

    isLoading: true,

    employees: [],

    employee: null,

    name: null,

    cpf: null,

    cnh: null,

    type: null,

    isLoadingEmployee: false,
  };

  async componentDidMount() {
    const { match } = this.props;
    this.mount(match.params.uuid);
  }

  mount(uuid) {
    if (uuid) {
      this.getEmployee(uuid);
    }

    this.getEmployees();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  getEmployees = async values => {
    this.setState(prevState => ({
      isLoading: true,
      name: values ? values.name : prevState.name,
      cpf: values ? values.cpf : prevState.cpf,
      cnh: values ? values.cnh : prevState.cnh,
      type: values ? values.type : prevState.type,
    }));

    const { name, cpf, cnh, type } = this.state;

    const res = await API.get(
      `employees/list/${name || null}/${cpf || null}/${cnh || null}/${type ||
        null}`
    );

    this.setState({
      employees: res.data,

      isLoading: false,
    });
  };

  async getEmployee(uuid) {
    this.setState({
      isLoadingEmployee: true,

      modalAddEmployee: true,
    });

    await API.get(`employees/${uuid}`).then(res => {
      const employee = res.data[0];

      this.setState({
        employee,

        isLoadingEmployee: false,
      });
    });
  }

  handleEmployeeDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Funcionário?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      await API.delete(`employees/${uuid}`).then(() => {
        this.getEmployees();

        this.setState({
          employee: null,

          modalAddEmployee: false,
        });
      });
    }
  };

  handleEmployeeCreation = async values => {
    try {
      const name = values.name.toUpperCase();

      await API.post(
        'employees',
        {
          name,
          cpf: values.cpf,
          rg: values.rg,
          rg_expiration: values.rg_expiration,
          rg_emission: values.rg_emission,
          cnh: values.cnh,
          cnh_expiration: values.cnh_expiration,
          title: values.title,
          section: values.section,
          zone: values.zone,
          birth_date: values.birth_date,
          phone: values.phone,
          address: values.address,
          type: values.type,
          bond: values.bond,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getEmployees();

      this.setState({ modalAddEmployee: false });
    } catch (err) {
      alert(err);
    }
  };

  handleEmployeeUpdate = async values => {
    const { employee } = this.state;

    try {
      const name = values.name.toUpperCase();

      await API.put(
        `employees/${employee.uuid}`,
        {
          name,
          cpf: values.cpf,
          rg: values.rg,
          rg_expiration: values.rg_expiration,
          rg_emission: values.rg_emission,
          cnh: values.cnh,
          cnh_expiration: values.cnh_expiration,
          title: values.title,
          section: values.section,
          zone: values.zone,
          birth_date: values.birth_date,
          phone: values.phone,
          address: values.address,
          type: values.type,
          bond: values.bond,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getEmployees();

      this.setState({ modalAddEmployee: false, employee: null });
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const {
      modalAddEmployee,

      isLoadingEmployee,

      employees,

      isLoading,

      employee,

      name,

      cpf,

      cnh,

      type,
    } = this.state;

    const useruuid = localStorage.getItem('USER_UUID');

    return (
      <Wrapper>
        {(useruuid === 'bfa5aa85-a3a0-4e1c-a3c2-769bfe92aace' ||
          useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
          useruuid === 'bdbafd39-5bc4-4ef1-b637-1df80d16bb0a' ||
          useruuid === 'b9cf4ca4-8935-44bb-a373-bd118776a325') && (
          <Container>
            <header>
              <h1>FUNCIONÁRIOS</h1>

              <Link
                to="/employees"
                onClick={() =>
                  this.setState({
                    modalAddEmployee: !modalAddEmployee,

                    employee: null,
                  })
                }
              >
                {!modalAddEmployee ? 'ADICIONAR' : 'LISTA'}
              </Link>
            </header>

            <Content>
              {modalAddEmployee ? (
                <>
                  {!isLoadingEmployee ? (
                    <>
                      <EmployeeForm
                        onEmployeeCreation={this.handleEmployeeCreation}
                        onEmployeeUpdate={this.handleEmployeeUpdate}
                        isLoadingEmployee={isLoadingEmployee}
                        onEmployeeDelete={this.handleEmployeeDelete}
                        employee={employee}
                      />

                      {employee && (
                        <EmployeeRoleContainer employee={employee} />
                      )}
                    </>
                  ) : (
                    <Loading />
                  )}
                </>
              ) : (
                <>
                  <EmployeeFilter
                    onList={this.getEmployees}
                    name={name}
                    cpf={cpf}
                    cnh={cnh}
                    type={type}
                  />

                  <EmployeeList
                    isLoading={isLoading}
                    onEmployeeGet={this.getEmployee}
                    employees={employees}
                  />
                </>
              )}
            </Content>
          </Container>
        )}
      </Wrapper>
    );
  }
}

EmployeeContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default EmployeeContainer;
